<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <h2 class="monoTitle title">Available Templates</h2>
      <b-button variant="outline-success" @click="startNewTemplate()"><font-awesome-icon :icon="['fas', 'upload']" />
        New Template</b-button>
    </div>


    <b-list-group>
      <b-list-group-item v-for="template in templates" :key="template._id" class="flex-column align-items-start">
        <div class="d-flex w-100">
          <h5 class="flex-grow-1"><small>Template: </small><b>{{ template.name }}</b></h5>
          <b-button variant="success" size="sm" @click="editTemplate(template)">
            <font-awesome-icon :icon="['fas', 'pencil']" /> Edit
          </b-button>
          <b-button variant="outline-danger" size="sm" class="ml-1" @click="delTemplate()">
            <font-awesome-icon :icon="['fas', 'trash']" />
            Delete
          </b-button>
        </div>
        <p>{{ template.stripped }}</p>
      </b-list-group-item>
    </b-list-group>


    <!-- Start templateEditor Modal-->
    <b-modal id="editorModal" :title="editorTitle" size="lg" @ok.prevent="saveTemplate" ok-title="Save Template"
      ok-variant="primary" header-bg-variant="primary" header-text-variant="white" @cancel="destroyEditor()">

      <b-form-group label="Template Name:" v-if="newTemplate">
        <b-form-input id="templateNameTxt" ref="templateNameTxt" v-model="newTemplateName" trim
          :state="newTemplateNameErrState" autofocus></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">{{ newTemplateNameErrTxt }}</b-form-invalid-feedback>
      </b-form-group>

      <b-button-toolbar v-if="editor">

        <b-button-group size="sm" class="mr-1 mb-1">
          <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('16px').run()"
            :class="{ 'active': editor.isActive('textStyle', { fontSize: '16px' }) }">
            <font-awesome-icon icon="paragraph" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('12px').run()"
            :class="{ 'active': editor.isActive('textStyle', { fontSize: '12px' }) }">
            <font-awesome-icon icon="minus" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().setFontSize('20px').run()"
            :class="{ 'active': editor.isActive('textStyle', { fontSize: '20px' }) }">
            <font-awesome-icon icon="plus" />
          </b-button>

        </b-button-group>

        <b-button-group size="sm" class="mb-1 mr-1">
          <b-button variant="outline-secondary" @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'active': editor.isActive('bold') }">
            <font-awesome-icon icon="bold" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'active': editor.isActive('italic') }">
            <font-awesome-icon icon="italic" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'active': editor.isActive('strike') }">
            <font-awesome-icon icon="strikethrough" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'active': editor.isActive('underline') }">
            <font-awesome-icon icon="underline" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().toggleHighlight().run()"
            :class="{ 'active': editor.isActive('highlight') }">
            <font-awesome-icon icon="highlighter" /> <font-awesome-icon style="color:#fcf8e3"
              :icon="['fas', 'square']" />
          </b-button>
        </b-button-group>

        <b-button-group size="sm" class="mb-1">
          <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#f44336').run()"
            :class="{ 'is-active': editor.isActive('textStyle', { color: '#f44336' }) }">
            Red <font-awesome-icon style="color:#f44336" :icon="['fas', 'square']" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#3688f4').run()"
            :class="{ 'is-active': editor.isActive('textStyle', { color: '#3688f4' }) }">
            Blue <font-awesome-icon style="color:#3688f4" :icon="['fas', 'square']" />
          </b-button>
          <b-button variant="outline-secondary" @click="editor.chain().focus().setColor('#36f4a2').run()"
            :class="{ 'is-active': editor.isActive('textStyle', { color: '#36f4a2' }) }">
            Green <font-awesome-icon style="color:#36f4a2" :icon="['fas', 'square']" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      <div class="editor">
        <editor-content :editor="editor" ref="scopeeditor" />
      </div>

    </b-modal>


  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from '@luciusa/extension-font-size'
import TextColor from '@tiptap/extension-color'

export default {
  name: 'contractTemplates',
  components: {
    EditorContent
  },
  computed: {
    userInfo() {
      return this.$state.userInfo
    },
    isMobile() {
      return this.$state.isMobile
    },
  },
  data() {
    return {
      templates: [],
      editor: null,
      editorTitle: "",
      editorValue: "",
      templateId: null,
      newTemplate: false,
      newTemplateName: "",
      newTemplateNameErrState: null,
      newTemplateNameErrTxt: "'Template Name' can not be blank."
    }
  },
  methods: {
    async getTemplates() {
      console.log("calling getTemplates")
      try {
        let res = await this.$axios.get(`/templates`, {
          headers: {
            Authorization: `Bearer ${this.$state.token}`
          }
        })

        console.log(res.data)
        res.data.forEach((template, i) => {
          let stripped = template.body.replace(/(<([^>]+)>)/ig, '').substring(0, 170) + "..."
          res.data[i].stripped = stripped
        })

        this.templates = res.data

      } catch (err) {
        console.error(err)
      }

    },
    delTemplate() {
      console.log("Delete Clicked.")
    },
    editTemplate(template) {
      console.log("Opening editor...")
      this.editor = new Editor({
        content: template.body,
        onCreate: () => { this.editorValue = this.editor.getHTML() },
        onUpdate: () => { this.editorValue = this.editor.getHTML() },
        autoFocus: true,
        extensions: [
          StarterKit,
          Highlight,
          Underline,
          TextStyle,
          FontSize,
          TextColor
        ]
      })
      this.editorTitle = "Edit Template"
      this.templateId = template._id

      this.$bvModal.show('editorModal')
    },
    async startNewTemplate() {
      this.editor = new Editor({
        content: "Enter your templete text here...",
        onUpdate: () => { this.editorValue = this.editor.getHTML() },
        autoFocus: true,
        extensions: [
          StarterKit,
          Highlight,
          Underline,
          TextStyle,
          FontSize,
          TextColor
        ]
      })
      this.editorTitle = "Create New Template"
      this.newTemplate = true
      this.$bvModal.show('editorModal')
    },
    async saveTemplate() {
      console.log("Save templete called.")
      //is a new template being saved or an exisitng template updated? 
      if (this.newTemplate) { //its a new template
        let payload = {
          name: this.newTemplateName,
          body: this.editorValue
        }
        console.log(payload)

        try {
          let res = await this.$axios.post(`/templates`, payload, {
            headers: {
              Authorization: `Bearer ${this.$state.token}`
            }
          })

          console.log(res.data)
          console.log("success, new template added.")

          this.getTemplates()
          this.$bvModal.hide('editorModal')
          this.destroyEditor()

        } catch (err) {
          console.error(err)
        }

      } else { //its exisitng, update it.
        let payload = {
          body: this.editorValue
        }
        console.log(payload)

        try {
          let res = await this.$axios.put(`/templates/${this.templateId}`, payload, {
            headers: {
              Authorization: `Bearer ${this.$state.token}`
            }
          })

          console.log(res.data)
          console.log("success, template updated.")

          this.getTemplates()
          this.$bvModal.hide('editorModal')
          this.destroyEditor()

        } catch (err) {
          console.error(err)
        }
      }



    },
    destroyEditor() {
      this.editor.destroy()
      this.editorValue = ""
      this.editorTitle = ""
      this.newTemplate = false
      this.newTemplateName = ""
      this.templateId = null
    }
  },
  created() {
    this.getTemplates()
  }
}
</script>

<style>
.template {
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProseMirror {
  border: 1px solid black;
  border-radius: .2em;
  padding: .5em;
  margin-bottom: 1em;
  max-height: 450px;
  min-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;

}

.ProseMirror:focus {
  outline: none;
}
</style>