
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-4' : 'mt-3' ]">
    <h1 class="monoTitle">Settings</h1>
    <b-row>
      <b-col cols="3">
        <b-list-group>
          <b-list-group-item class="monoTitle" @click="currentView = 'brochures'" :class="{ selected: currentView === 'brochures'}"><font-awesome-icon :icon="['far', 'file-image']" /> Brochures</b-list-group-item>
          <b-list-group-item class="monoTitle" @click="currentView = 'contractTemplates'" :class="{ selected: currentView === 'contractTemplates'}"><font-awesome-icon :icon="['fas', 'file-signature']" /> Contract Templates</b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="9">
        <brochures-admin v-show="currentView === 'brochures'"/>
        <contract-templates v-show="currentView === 'contractTemplates'"/>
      </b-col>
    </b-row>
       
  </b-container>
</template>

<script>
import brochuresAdmin from '../components/brochuresAdmin.vue'
import contractTemplates from '../components/contractTemplates.vue'

export default {
  name: "settings",
  components: {
    brochuresAdmin,
    contractTemplates
  },
  data() {
    return {
      currentView: "brochures"
    }
  },
  computed: {
        userInfo () {
          return this.$state.userInfo
        },
        store () {
          return this.$state
        }, 
        isMobile () {
          return this.$state.isMobile
        }
  },
  methods: {
  
  },
  created() {
    
  }
}
</script>


<style lang="scss" scoped>
.selected {
  background-color: #89cfef;
}

</style>
