
<template>
    <b-container :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
        <h3>Stripe Available: ${{ (balance.available/100).toFixed(2) }} </h3>
        <h3>Stripe Pending: ${{ (balance.pending/100).toFixed(2) }}</h3>  
        <b-card class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="light">
            <template #header>
                <span class="monoTitle"><b>Last 12 Charges</b></span>
            </template>
            <b-list-group flush>
                <b-list-group-item>
                    <b-row no-gutters>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Date</strong>
                        </b-col>
                        <b-col cols="8" md="4" class="text-truncate d-inline-block monoTitle" >
                            <strong>Name</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Amount</strong>
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Status</strong>
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Reciept</strong>
                        </b-col>
                        <b-col cols="4" md="3" class="text-truncate text-right d-inline-block monoTitle" >
                            <strong>More Info</strong>
                        </b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item v-for="charge in charges" :key="charge.id">
                    <b-row no-gutters>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block smTxt" >
                            {{ format(fromUnixTime(charge.created), 'MM/dd/yy') }}
                        </b-col>
                        <b-col cols="8" md="4" class="text-truncate d-inline-block monoTitle" >
                            {{ charge.billing_details.name }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            ${{ (charge.amount/100).toFixed(2) }}
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block" >
                            <b-badge v-if="charge.status === 'succeeded'" variant="success">Success</b-badge>
                            <b-badge v-if="charge.status === 'failed'" variant="danger">Failed</b-badge>
                            <b-badge v-if="charge.status === 'pending'" variant="warning">Pending</b-badge>
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block text-center" >
                            <span v-if="charge.status === 'failed'">N/A</span>
                            <span v-else>
                                <b-link :href="charge.receipt_url" target="_blank" v-b-tooltip.hover title="Go to hosted receipt page">
                                    <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="fa-lg" fixed-width/>
                                </b-link>
                            </span>
                        </b-col>
                        <b-col cols="4" md="3" class="text-truncate d-inline-block smTxt text-right" >
                            <span v-if="charge.status === 'failed'">Not Available</span>
                            <span v-else>Net: ${{ (charge.balanceInfo.net/100).toFixed(2) }} Fee: ${{ (charge.balanceInfo.fee/100).toFixed(2) }}</span>
                        </b-col>
                    </b-row>

                </b-list-group-item>
            </b-list-group>
        </b-card>

        <b-card class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="light">
            <template #header>
                <span class="monoTitle"><b>Last 10 Balance Transactions</b></span>
            </template>
            <b-list-group flush>
                <b-list-group-item>
                    <b-row no-gutters>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Date</strong>
                        </b-col>
                        <b-col cols="8" md="3" class="text-truncate d-inline-block monoTitle" >
                            <strong>Available</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Amount</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Description</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Status</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Category</strong>
                        </b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item v-for="txn in balanceTrans" :key="txn.id">
                    <b-row no-gutters>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            {{ format(fromUnixTime(txn.created), 'MM/dd/yy') }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            {{ format(fromUnixTime(txn.available_on), 'MM/dd/yy') }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            ${{ (txn.amount/100).toFixed(2) }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            {{ txn.description }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            {{ txn.status }}
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                            {{ txn.reporting_category }}
                
                        </b-col>
                    </b-row>

                </b-list-group-item>
            </b-list-group>
        </b-card>

        <b-modal id="moreInfoModal" size="lg" title="Transaction Details">
            <b>Time:</b> {{ intentData.created ? fromUnixTime(intentData.created) : "n/a" }} <br>
            <b>Amount:</b> ${{ (intentData.amount/100).toFixed(2) }} <br>
            <b>Fee:</b> ${{ (intentData.balanceDetails.fee/100).toFixed(2) }} <br>
            <b>Net:</b> ${{ (intentData.balanceDetails.net/100).toFixed(2) }} <br>
            <b>Amount Recieved:</b> ${{ intentData.amount_received/100 }} <br>
            <b>Description:</b> {{ intentData.description }} <br>
            <b>Status:</b> {{ intentData.status }} <br>
            <div v-if="intentData.latest_charge">
                <b>Latest Charge Time:</b> {{ intentData.latest_charge ? fromUnixTime(intentData.latest_charge.created) : "n/a" }} <br>
                <b>Failure Code:</b> {{ intentData.latest_charge ? intentData.latest_charge.failure_code : "n/a" }} <br>
                <b>Failure Message:</b> {{ intentData.latest_charge ? intentData.latest_charge.failure_message: "n/a" }} <br>
                <b>Payment Method Details:</b> {{ intentData.latest_charge ? intentData.latest_charge.payment_method_details.card.brand: "n/a" }} XXXX{{ intentData.latest_charge ? intentData.latest_charge.payment_method_details.card.last4: "n/a "}} {{ intentData.latest_charge ? intentData.latest_charge.payment_method_details.card.exp_month: "" }}/{{ intentData.latest_charge ? intentData.latest_charge.payment_method_details.card.exp_year: ""}} <br>
                <b>Reciept URL:</b> <a :href="intentData.latest_charge.receipt_url" target="_blank"> View <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="fa-lg" fixed-width/> </a> <br>
                <b>Refunded:</b> {{ intentData.latest_charge ? intentData.latest_charge.refunded: "n/a" }}
            </div>
        </b-modal>

    </b-container>

    

  </template>
  
  <script>
  import { format, fromUnixTime } from 'date-fns'
  
  export default {
    name: "creditCardActivity",
    components: {
      
    },
    data() {
      return {
        invoices: [],
        balanceTrans: [],
        paymentIntents: [],
        charges: [],
        balance: {
            available: 0,
            pending: 0,
            rawData: {}
        },
        intentData: {
            created: 0,
            amount: 0,
            amount_received: 0,
            description: "",
            status: null,
            balanceDetails: {
                fee: 0,
                net: 0
            },
            latest_charge: {
                created: 0,
                failure_code: null,
                failure_message: "",
                payment_method_details: {
                    card: {
                        brand: "",
                        last4: "",
                        exp_month: "",
                        exp_year: ""
                    }
                },
                receipt_url: null,
                refunded:null
            }
        },
        fromUnixTime,
        format

      }
    },
    computed: {
          userInfo () {
            return this.$state.userInfo
          },
          isMobile () {
            return this.$state.isMobile
          }
    },
    methods: {
        async getInvoices() {
            try {
                const res = await this.$axios.get('/payment/invoices', {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                console.log(res)
                this.invoices = res.data.invoices.data
                this.balanceTrans = res.data.balanceTrans.data
                console.log('getInvoices returned')
            } catch (err) {
                console.error(err)
            }
            
        },
        async getCharges() {
            try {
                const res = await this.$axios.get('/payment/charges', {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                console.log(res)
                this.charges = res.data.charges.data
                this.balanceTrans = res.data.balanceTrans.data
                console.log('charges returned')
            } catch (err) {
                console.error(err)
            }
            
        },
        async moreInfo(pi){
            this.resetIntentData()
            const intentData = await this.$axios.get(`/payment/invoice/intent/${pi}`, {
                headers: { Authorization: `Bearer ${this.$state.token}` }
            })
            this.intentData = intentData.data
            this.$bvModal.show("moreInfoModal")
            //console.log(this.intentData)
        },
        async getBalance() {
            const bal = await this.$axios.get(`/payment/balance`, {
                headers: { Authorization: `Bearer ${this.$state.token}` }
            })
            this.balance.rawData = bal.data

            let totalAvailable = bal.data.available.reduce((prev, curr) => {
                ({amount: prev.amount + curr.amount})
            })

            console.log(`Total is: ${totalAvailable.amount}`)
            this.balance.available = totalAvailable.amount

            let totalPending = bal.data.pending.reduce((prev, curr) => {
                ({amount: prev.amount + curr.amount})
            })

            console.log(`Pending is: ${totalAvailable.amount}`)
            this.balance.pending = totalPending.amount

        },
        resetIntentData() {
            console.log(`reseting intentData`)
            this.intentData =  {
                created: 0,
                amount: 0,
                amount_received: 0,
                description: "",
                status: null,
                last_payment_error: {},
                balanceDetails: {
                    fee: 0,
                    net: 0
                },
                latest_charge: {
                    created: 0,
                    failure_code: null,
                    failure_message: "",
                    payment_method_details: {
                        card: {
                            brand: "",
                            last4: "",
                            exp_month: "",
                            exp_year: ""
                        }
                    },
                    receipt_url: null,
                    refunded:null
                }
            }
        }
        
    },
    created() {
        this.resetIntentData()
        this.getBalance()
        this.getCharges()
    
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  .smTxt {
    font-size: .8em;
  }
  .moreInfo {
    color: #0074D4;
    cursor: grab;
  }
  </style>
  