<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 pb-5' : 'mt-3' ]" v-if="ready">
    <b-row>
        <b-col>
            <b-button v-if="fromId" variant="primary" @click="goBackToJob()">
                <font-awesome-icon icon="fa-arrow-left" ></font-awesome-icon> Go Back
            </b-button> 
            <b-button v-else variant="primary" @click="goToJob()">
                Go to Job Profile <font-awesome-icon icon="fa-arrow-right" ></font-awesome-icon>
            </b-button> 
            <br />
            <h1>Twin Service Ticket</h1>
            <div class="d-flex">
                <h3 class="d-inline-block flex-grow-1">{{tix.customer.fname}} {{tix.customer.lname}}</h3>
                <h3 class="d-inline-block">Date: {{tix.dates.addedDate}}</h3>
            </div>
            <div class="d-flex flex-row-reverse" v-if="tix.status == 'Finished'">
                <h5 class="d-inline-block">Finished Date: {{tix.dates.finishedDate}}</h5>
            </div>
            <address>
                <b>Jobsite Address</b> <br />
                {{tix.job.siteAddress.street}}<br />
                {{tix.job.siteAddress.city}}, {{tix.job.siteAddress.state}} {{tix.job.siteAddress.zip}}
            </address>
            <b-card title="Contact Info">
                <b-row class="mt-2">
                    <b-col cols="12" sm="6">
                        <b>Phone:</b><br />
                        Mobile: {{tix.customer.phones.mobile}}<br />
                        Home: {{tix.customer.phones.home}}<br />
                        Work: {{tix.customer.phones.work}}
                    </b-col >
                    <b-col cols="12" sm="6">
                    
                        <b>Email:</b><br />
                        Primary: {{tix.customer.emails.primary}}<br />
                        Secondary: {{tix.customer.emails.secondary}}<br />
                    </b-col>
                </b-row>            
            </b-card>
            <b-card header="Ticket Info" class="mb-5 mt-3" border-variant="primary" header-bg-variant="primary" header-text-variant="white">
                <b-row>
                    <b-col class="mb-3">
                        <div class="alert" :class="statusVariant" role="alert">
                            {{tix.serviceCat}} Ticket | Status: <b>{{cap(tix.status)}}</b> <span v-show="tix.status != 'Finished'">| Ticket Age: {{tixAge}} Days</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="5">
                        <b-form-group description="Service Assigned To">
                            <b-form-input id="serviceMan" v-model="tix.serviceMan" trim autocomplete="off" :state="serviceManValid"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="3">
                        <b-form-group description="Service Catagory" class="radioBtn">
                            <b-form-radio-group
                                button-variant="outline-primary"
                                id="serviceCat"
                                v-model="tix.serviceCat"
                                :options="[{text: 'Warranty', value: 'Warranty'}, {text: 'Punchlist', value: 'Punchlist'}]"
                                buttons
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="4">
                        <b-form-group description="Service Status" class="radioBtn">
                            <b-form-radio-group
                                button-variant="outline-primary"
                                id="status"
                                v-model="tix.status"
                                :options="[{text: 'Pending', value: 'pending'}, {text: 'Started', value: 'started'}, {text: 'Finished', value: 'Finished'}]"
                                buttons
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-show="tix.status == 'started' || tix.status == 'Finished'">
                    <b-col cols="12" sm="6">
                        <b-form-group description="Start Date">
                            <b-form-datepicker id="start-datepicker" v-model="startDate" class="mb-2"></b-form-datepicker>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <b-form-group description="Finished Date">
                            <b-form-datepicker id="finished-datepicker" v-model="finishedDate" class="mb-2"></b-form-datepicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-form-group description="Service Description" >
                    <b-form-textarea id="serviceDesc" v-model="tix.serviceDesc" trim autocomplete="off" ></b-form-textarea>
                </b-form-group>

                <b-button variant="success" @click="saveTix()"><font-awesome-icon icon="fa-regular fa-save"></font-awesome-icon> Save Ticket</b-button> 
                <b-button variant="outline-primary" @click="print()" class="ml-1"><font-awesome-icon icon="fa-print"></font-awesome-icon> Print</b-button>
                <b-button variant="danger" class="ml-1" @click="deleteTix()"><font-awesome-icon icon="fa-regular fa-trash-alt"></font-awesome-icon> Delete</b-button>
            </b-card>

        </b-col>
    </b-row>
  </b-container>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'
import { getUnixTime } from 'date-fns'
import { differenceInDays } from 'date-fns'
import { parse } from 'date-fns'


export default {
  name: "serviceTix",
  components: {
  },
  data() {
    return {
        ready: false,
        tix: null,
        cap: this.capitalize,
        statusVariant: 'alert-info',
        tixAge: null,
        showDateModal: false,
        startDate: null,
        finishedDate: null
    }
  },
  computed: {
        fromId () {
            if(this.$route.query.fromJob){
                return this.$route.query.fromJob
            } else {
                return null
            }
        },
        serviceManValid() {
            if(this.tix.status == 'pending'){
                if(this.tix.serviceMan.length < 3){
                    return null
                } else {
                    return true
                }   
            } else {
                return this.tix.serviceMan.length > 2
            }     
        },
        userInfo () {
          return this.$state.userInfo
        },
        id () {
            return this.$route.params.id
        },
        isMobile() {
            return this.$state.isMobile
        }
  },
  methods: {
    async getTixInfo () {
        console.log(`getTixInfo Called...id is: ${this.id}`)

        try {
            const res = await this.$axios.get(`/service/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })


            this.tixAge = differenceInDays(Date.now(), fromUnixTime(res.data.dates.addedDate))
            if(this.tixAge > 20) {
                this.statusVariant = "alert-warning"
            } 
            if (this.tixAge > 45) {
                this.statusVariant = "alert-danger"
            }

            if (res.data.status == "Finished") {
                this.statusVariant = "alert-success"
            }
            //formatDates

            res.data.dates.addedDate = format(fromUnixTime(res.data.dates.addedDate), "MM/dd/yyyy")
            
            if(!res.data.dates.finishedDate) {
                this.finishedDate = null
            } else {
                this.finishedDate = format(fromUnixTime(res.data.dates.finishedDate), "yyyy-MM-dd")
            }
            if(!res.data.dates.startDate) {
                this.startDate = null
            } else {
                this.startDate = format(fromUnixTime(res.data.dates.startDate), "yyyy-MM-dd")
            }
            

            this.tix = res.data
            console.log(`Ticket data loaded.`)

        } catch (err) {
            console.error(err)
        } finally {
            console.log(`Setting ready state to true.`)
            this.ready = true
        }

    },
    async deleteTix() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this ticket?', {
            headerBgVariant: 'danger',
            headerTextVariant: 'white',
            title: 'Please Confirm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
        .then(async value => {
            if(value){
                console.log(`DELETE!`)
                let oldData = this.tix
                
                try {
                    let res = await this.$axios.delete(`/service/${this.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.$state.token}`
                        }
                    })

                    //console.log("Responce:")
                    console.log(res)

                    //log it
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Service Ticket for Job: ${this.tix.job._id} Deleted`,
                        addInfo: oldData,
                        job: this.tix.job._id,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)

                    this.$root.$bvToast.toast('Service ticket deleted successfully', {
                        title: `DELETE`,
                        variant: "info",
                        solid: true
                    })    

                    if(this.fromId) {
                        this.$router.push({ path: `/jobProfile/${this.fromId}` })
                    } else {
                        this.$router.push({ name: 'serviceBoard'})
                    }
                    

                    

                } catch (err) {
                    console.error(err)
                }


            } else {
                console.log("Abort!")
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    async saveTix() {

        if(!this.serviceManValid && this.tix.status == 'Finished') {
            this.$bvModal.msgBoxOk("The 'Serive Assigned To' must be filled in before you can mark this ticket finished. (Min name lenght is 3 characters.)", { 
                headerBgVariant: 'warning',
                headerTextVariant: 'dark',
                title: 'Stop',
                centered: true
            })
            return
        }

        if(!this.serviceManValid && this.tix.status == 'started') {
            this.$bvModal.msgBoxOk("The 'Serive Assigned To' must be filled in before you can mark this ticket Started. (Min name lenght is 3 characters.)", { 
                headerBgVariant: 'warning',
                headerTextVariant: 'dark',
                title: 'Stop',
                centered: true
            })
            return
        }

        if(this.tix.status == 'started' || this.tix.status == 'finished'){
            if(!this.startDate || !this.finishedDate){
                this.$bvModal.msgBoxOk("Start and Finished dates must both be filled in.", { 
                    headerBgVariant: 'warning',
                    headerTextVariant: 'dark',
                    title: 'Stop',
                    centered: true
                })
                return
            }
        }

        let payload = {
            customer: this.tix.customer._id,
            job: this.tix.job._id,
            dates: {
                addedDate: getUnixTime(parse(this.tix.dates.addedDate, 'MM/dd/yyyy', new Date())),
            },
            serviceCat: this.tix.serviceCat,
            serviceMan: this.tix.serviceMan,
            serviceDesc: this.tix.serviceDesc,
            status: this.tix.status,
            linkedFiles: this.tix.linkedFiles
        }

        if(this.tix.status == "Finished") {
            this.statusVariant = "alert-success"
            payload.dates.startDate = getUnixTime(parse(this.startDate, 'yyyy-MM-dd', new Date()))
            payload.dates.finishedDate = getUnixTime(parse(this.finishedDate, 'yyyy-MM-dd', new Date()))
        }
        if(this.tix.status == "started") {
            this.statusVariant = "alert-success"
            payload.dates.finishedDate = getUnixTime(parse(this.finishedDate, 'yyyy-MM-dd', new Date()))
            payload.dates.startDate = getUnixTime(parse(this.startDate, 'yyyy-MM-dd', new Date())) 
        }
        if(this.tix.status == "pending") {
            payload.dates.finishedDate = null
            payload.dates.startDate = null
            this.statusVariant = "alert-warning"
        }
        
        console.log('Payload:')
        console.log(payload)
        
        try {
            let res = await this.$axios.put(`/service/${this.id}`, payload, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.log(res)

            //log it
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `Service Ticket for Job: ${this.tix.job._id} Updated.`,
                addInfo: res.config.data,
                job: this.tix.job._id,
                logUser: this.userInfo.name
            }

            this.$actions.log(newLog)

            this.$bvToast.toast('Service Ticket Updated', {
                title: `Success`,
                variant: "success",
                solid: true
            })

        } catch (err) {
            console.error(err)
        }
    

    },
    closeDateModal(){
            this.showDateModal = false
    },
    goBackToJob() {
        this.$router.push({ path: `/jobProfile/${this.fromId}` })
    },
    goToJob() {
        this.$router.push({ name: "jobProfile", params: { id : this.tix.job._id } })
    },
    capitalize (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },
    print() {
        window.print()
    }
  },
  created () {
        this.getTixInfo()
        
  }
}
</script>


<style lang="scss" scoped>
   #serviceDesc {
       min-height: 250px;
   }

   @media print {
       @page {
            size: auto;
            margin: 15mm 15mm 10mm 15mm;
        }
       
       .btn {
           display: none;
       } 
       .radioBtn {
           display: none;
       }
   }

</style>
