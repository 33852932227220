<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-4' : 'mt-3' ]" v-if="ready">
    <b-row>
        <b-col>
            <h3 class="d-block d-md-inline monoTitle" :class="{'pl-2':isMobile}">
                {{contract.customer.fname}} {{contract.customer.lname}} 
            </h3>

            <small class="text-muted font-weight-light d-block mb-2" :class="{'pl-2':isMobile}" >
                Contract #:{{contract.contractId}} | {{contract.jobType}} | {{jobStatus}}
            </small>
            
            

            <customer-info :contract="contract" :contracts="contracts"></customer-info>
            
            <contract-details :contract="contract"></contract-details>

            <jobsetups :contract="contract"></jobsetups>

            <files :contractId="contract.contractId"></files>

            <money :jobId="id" :contract="contract" @unsold="unsold" @sold="sold"></money>

            <logtabs :jobId="id" class="mt-3"/>
        </b-col>
    </b-row>
  </b-container>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'
import CustomerInfo from '../components/jobProfile-components/customerInfo.vue'
import ContractDetails from '../components/jobProfile-components/contractDetails.vue'
import Money from '../components/jobProfile-components/money.vue'
import Files from '../components/jobProfile-components/files.vue'
import logtabs from '../components/jobProfile-components/log-tabs.vue'
import jobsetups from '../components/jobProfile-components/jobsetups.vue'


export default {
  name: "jobProfile",
  components: {
    CustomerInfo,
    ContractDetails,
    Money,
    Files,
    logtabs,
    jobsetups
  },
  data() {
    return {
        ready: false,
        contract: {
            contractId: 0,
            customer: {
                fname: "",
                lname: "",
                address: {
                    street: ""
                }
            },
            siteAddress: {
                street: ""
            },
            mechanic: null,
            jobType: "",
            dates: {
                contractDate: null,
                depositDate: null,
                startDate: null,
                finishedDate: null
            },
            sold: false,
            salesman: {
                name: "n/a"
            },
            scopeText: ""
        },
        contracts: [],
        serviceTix: [] 
    }
  },
  computed: {
        id () {
            return this.$route.params.id
        },
        jobStatus () {
            if (this.contract.sold) {
                return `Sold / ${this.contract.status}`
            } else {
                return `Un-Sold`
            }
        },
        isMobile() {
            return this.$state.isMobile
        }
  },
  methods: {

    unsold() {
        console.log("child emitted 'unsold'")
        this.contract.sold = false
        this.contract.status = null
    },
    sold(){
        console.log("child emitted 'sold'")
        this.contract.sold = true
        this.contract.status = "pending"
    },
    async getContractInfo (id) {
        console.log(`getContractInfo Called...id is: ${id}`)

        try {
            const res = await this.$axios.get(`/job/${id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

            //formatDates

            res.data.dates.contractDate = format(fromUnixTime(res.data.dates.contractDate), "MM/dd/yyyy")
            
            if(!res.data.dates.depositDate) {
                res.data.dates.depositDate = "n/a"
            } else {
                res.data.dates.depositDate = format(fromUnixTime(res.data.dates.depositDate), "MM/dd/yyyy")
            }
            
            if(!res.data.dates.startDate) {
                res.data.dates.startDate = "n/a"
            } else {
                res.data.dates.startDate = format(fromUnixTime(res.data.dates.startDate), "MM/dd/yyyy")
            }

            if(!res.data.dates.finishedDate) {
                res.data.dates.finishedDate = "n/a"
            } else {
                res.data.dates.finishedDate = format(fromUnixTime(res.data.dates.finishedDate), "MM/dd/yyyy")
            }

            this.contract = res.data
            console.log(`Contract data loaded.`)

        } catch (err) {
            console.error(err)
        } finally {
            console.log(`Setting ready state to true.`)
            this.ready = true
            this.getOtherContracts(this.contract.customer._id)
        }

    },
    async getOtherContracts(id) {
        try {
            const res = await this.$axios.get(`/job/getAllJobs/${id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
            
            let filteredRes = res.data.filter((job) => {
                return job._id != this.id
            })

            this.contracts = filteredRes
            
        } catch (err) {
            console.error(err)

        }
    },

    firstLetterCap (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase()
    },
    capitalize (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },
    gotoMap() {
        this.$router.push({ name: 'Map', params: { location: `${this.contract.address.street}, ${this.contract.address.city} ${this.contract.address.state}` } })
    },
    getIcon (jobType) {
        if(jobType.trim() === 'ripReroof') {
            return {icon: 'caret-up', displayName: 'Rip Re-Roof'}
        } else if(jobType.trim().toLowerCase() === 'decking'){
            return {icon: 'grip-lines', DisplayName: 'Decking'}
        } else if(jobType.trim().toLowerCase() === 'roofRepair') {
            return {icon: 'caret-up', displayName: 'Roof Repair'}
        } else if(jobType.trim().toLowerCase() === 'other') {
            return {icon: 'network-wired', displayName: 'Other'}
        } else if(jobType.trim().toLowerCase() === 'railing') {
            return {icon: 'ruler-horizontal', displayName: 'Railing'}
        } else if(jobType.trim().toLowerCase() === 'gutters') {
            return {icon: 'tint', displayName: 'Gutters'}
        } else if(jobType.trim().toLowerCase() === 'gutterRepair') {
            return {icon: 'tint', displayName: 'Gutter Repair'}
        } else if(jobType.trim() === 'gutterCleaning') {
            return {icon: 'leaf', displayName: 'Gutter Cleaning'}
        } else if(jobType.trim().toLowerCase() === 'windows') {
            return {icon: 'border-all', displayName: 'Windows'}
        } else if(jobType.trim().toLowerCase() === 'windowRepair') {
            return {icon: 'border-all', displayName: 'Window Repair'}
        } else if(jobType.trim().toLowerCase() === 'coverRoof') {
            return {icon: 'caret-up', displayName: 'Cover Re-Roof'}
        } else if(jobType.trim().toLowerCase() === 'siding') {
            return {icon: 'align-left', displayName: 'Siding'}
        } else if(jobType.trim().toLowerCase() === 'sidingRepair') {
            return {icon: 'align-left', displayName: 'Siding Repair'}
        } else if(jobType.trim().toLowerCase() === 'trimjob') {
            return {icon: 'align-left', displayName: 'Trim Job'}
        } else if(jobType.trim().toLowerCase() === 'newConstructRoof') {
            return {icon: 'caret-up', displayName: 'New Construction Roof'}
        } else {
            return {icon: 'network-wired', displayName: 'Other'}
        }
    }
  },
  created () {
        this.getContractInfo(this.id)
        /*
        let logObj = {
            user: this.username, 
            msg: `Contract ${this.contractId} viewed by ${this.username}`, 
            contractId: this.contractId
        }
        this.$root.log(logObj)
        */
  }
}
</script>


<style lang="scss" scoped>
   
</style>
