
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 pb-5 mb-5' : 'mt-3' ]">
    <b-card header="In Progress" class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
    <b-list-group flush>
        <b-list-group-item>
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >
                    Start Date
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >  
                    <div class="customerNameCol">
                        <div>Job Name</div>
                        <div v-if="!isMobile"><font-awesome-icon icon="fa-solid fa-print" /> Count</div>
                    </div>
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Contract Total
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Payments
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Balance
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-for="job in inProgress" :to="'/jobProfile/' + job._id" :key="job.contractId">
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >  
                    {{format(fromUnixTime(job.dates.startDate), 'MM/dd/yyyy')}}
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >
                    <div class="customerNameCol">
                        <div><b>{{job.customer.lname}}, {{job.customer.fname}}</b></div>
                        <div v-if="!isMobile">
                            <b-button variant="link" size="sm" @click.prevent="viewInvoice(job._id)">
                                <font-awesome-icon icon="fa-solid fa-print" /> ({{job.printCount || 0}})
                            </b-button>
                        </div>
                    </div>
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractTotal).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractPayments).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractBalance).format()}}
                </b-col>
                
            </b-row>
        </b-list-group-item>
        <b-list-group-item variant="secondary">
            <b-row no-gutters>
                <b-col cols="12" class="text-right">
                    <div class="mr-2 d-inline"><b>Total In Progress:</b></div>
                    <span class="h5">${{numbro(inProgressTotal).format()}}</span>  
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>

<b-card header="Complete - Balance Due" class="mb-3" no-body border-variant="secondary" header-bg-variant="secondary" header-text-variant="white">
    <b-list-group flush>
        <b-list-group-item>
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >
                    Finished Date
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >
                    <div class="customerNameCol">
                        <div>Age - Job Name</div>
                        <div v-if="!isMobile"><font-awesome-icon icon="fa-solid fa-print" /> Count</div>
                    </div>
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Contract Total
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Payments
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Balance
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-for="job in balanceDue" :to="'/jobProfile/' + job._id" :key="job.contractId">
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >
                    {{format(fromUnixTime(job.dates.finishedDate), 'MM/dd/yyyy')}}
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >
                    <div class="customerNameCol">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-circle" :class="job.age < 8 ? 'text-success': job.age < 15 ? 'text-warning': 'text-danger'" class="mr-1"/> 
                            <b>{{job.customer.lname}}, {{job.customer.fname}}</b>
                        </div>
                        <div v-if="!isMobile">
                            <b-button variant="link" size="sm" @click.prevent="viewInvoice(job._id)">
                                <font-awesome-icon icon="fa-solid fa-print" /> ({{job.printCount || 0}})
                            </b-button>
                        </div>
                    </div>
                    
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractTotal).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractPayments).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractBalance).format()}}
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item variant="secondary">
            <b-row no-gutters>
                <b-col cols="12" sm="5" order="3" order-sm="0" >
                    <div class="smallTxt d-inline mr-1"><font-awesome-icon icon="fa-solid fa-circle" class="text-success"/> Less then 1 week</div>
                    <div class="smallTxt d-inline mr-1"><font-awesome-icon icon="fa-solid fa-circle" class="text-warning"/> Over 1 week</div>
                    <div class="smallTxt d-inline"> <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="text-danger"/> Over 2 weeks</div>
                </b-col>
                <b-col cols="12" sm="7" class="text-right">
                    <div class="mr-2 d-inline"><b>Total Balance Due:</b></div>
                    <span class="h5">${{numbro(balanceDueTotal).format()}}</span>
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>

<b-card class="mb-3 pt-0 pb-0">
    <b-row no-gutters>
        <b-col cols="12" class="text-right">
            <div class="mr-2 d-inline h4"><b>Grand Total of Due and In Progress:</b></div>
            <span class="h4">${{numbro(inProgressTotal).add(balanceDueTotal).format()}}</span>  
        </b-col>
    </b-row>       
</b-card>

<b-card header="Recently Completed" class="mb-3" no-body header-bg-variant="light">
    <b-list-group flush>
        <b-list-group-item>
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >
                    Paid Date
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >
                    <div class="customerNameCol">
                        <div v-if="!isMobile">Job Name</div>
                        <div><font-awesome-icon icon="fa-solid fa-print" /> Count</div>
                    </div>
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Contract Total
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Payments
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    Balance
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-for="job in recentlyPaid" :to="'/jobProfile/' + job._id" :key="job.contractId">
            <b-row no-gutters>
                <b-col cols="4" sm="2" class="text-truncate d-inline-block" >
                    {{job.paidDate}}
                    <!--{{format(fromUnixTime(job.dates.startDate), 'MM/dd/yyyy')}}-->
                </b-col>
                <b-col cols="8" sm="4" class="text-truncate d-inline-block" >
                    <div class="customerNameCol">
                        <div><b>{{job.customer.lname}}, {{job.customer.fname}}</b></div>
                        <div v-if="!isMobile">
                            <b-button variant="link" size="sm" @click.prevent="viewInvoice(job._id)">
                                <font-awesome-icon icon="fa-solid fa-print" /> ({{job.printCount || 0}})
                            </b-button>
                        </div>
                    </div>
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractTotal).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractPayments).format()}}
                </b-col>
                <b-col cols="4" sm="2" class="text-right">
                    ${{numbro(job.contractBalance).format()}}
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item variant="secondary">
            <b-row no-gutters>
                <b-col cols="12" class="text-right">
                    <div class="mr-2 d-inline"><b>Total Recently Paid:</b></div>
                    ${{numbro(recentlyPaidTotal).format()}}
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>
</b-container>
</template>

<script>
import numbro from "numbro"
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'
import { differenceInDays } from 'date-fns'

export default {
  name: "accountsRecievable",
  components: {
    
  },
  data() {
    return {
      inProgress: [],
      inProgressTotal: 0,
      balanceDue: [],
      balanceDueTotal: 0,
      recentlyPaid: [],
      recentlyPaidTotal: 0,
      complete: [],
      numbro: numbro,
      format: format,
      fromUnixTime: fromUnixTime
    }
  },
  computed: {
    isMobile() {
        return this.$state.isMobile
    }, /*
    recentlyPaidSorted () {
        return this.recentlyPaid.sort((a,b) => (a.paidDate > b.paidDate) ? 1 : -1)
    }*/
  },
  methods: {
    async getInProgress() {
      
      try {
        const jobs = await this.$axios.get(`/job/inprogress`, {
            headers: { Authorization: `Bearer ${this.$state.token}` }
        })
        
        let getMoneyResults = await this.getMoney(jobs.data, "inProgress")
        console.log(getMoneyResults )
        this.inProgress = getMoneyResults

      } catch(err){
        console.log(err)
      }
    },
    async getBalanceDue() {
      
      try {
        const jobs = await this.$axios.get(`/job/balanceDue`, {
            headers: { Authorization: `Bearer ${this.$state.token}` }
        })
        
        //console.log(jobs)
        let balanceDueResults = await this.getMoney(jobs.data, "balanceDue")
        console.log(balanceDueResults )
        balanceDueResults.forEach(job => {
            let age = differenceInDays(new Date(), fromUnixTime(job.dates.finishedDate))
            this.$set(job, "age", age)
        })
        this.balanceDue = balanceDueResults

      } catch(err){
        console.log(err)
      }
    },
    async getRecentlyPaid() {
      
      try {
        const jobs = await this.$axios.get(`/job/recentlyPaid`, {
            headers: { Authorization: `Bearer ${this.$state.token}` }
        })
        
        //console.log(jobs)
        let recentlyPaidResults = await this.getMoney(jobs.data, "recentlyPaid")
        
        this.recentlyPaid = recentlyPaidResults
      } catch(err){
        console.log(err)
      }
    },
    async getMoney(jobs, catagory) {

        return new Promise((resolve) => {

            let balanceTotal = numbro(0)

            jobs.forEach(async (job) => {
                //console.log(job._id)
                const moneyRes = await this.$axios.get(`/money/job/${job._id}`, {headers: {Authorization: `Bearer ${this.$state.token}`}})
                //job.money = moneyRes.data
                let contractTotal = numbro(0)
                let contractPayments = numbro(0)
                let paidDate = 0
                
                for(let i = 0; i < moneyRes.data.length; i++){
                    //console.log(moneyRes.data[i])
                    if(moneyRes.data[i].debit.toLowerCase() === 'debit'){
                        contractTotal.add(numbro(moneyRes.data[i].amount))
                    } else {
                        contractPayments.add(numbro(moneyRes.data[i].amount))
                    }
                    if(moneyRes.data[i].transTime > paidDate){
                        paidDate = moneyRes.data[i].transTime
                    }
                }

                this.$set(job, "contractTotal", contractTotal.value())
                this.$set(job, "contractPayments", contractPayments.value())
                let contractBalance = contractTotal.subtract(contractPayments)
                balanceTotal = balanceTotal.add(contractBalance)
                this.$set(job, "contractBalance", contractBalance.value())    
                
                //set the 'paid' date
                this.$set(job, 'paidDate', paidDate)
                

            })
            console.log(`${catagory}: getMoney complete, returning...`)
            this[`${catagory}Total`] = balanceTotal

            resolve(jobs)
        })
             
    },
    additup(){

    },
    viewInvoice(id) {
        this.$router.push({ name: "viewInvoice", params: { id: id } })
    }

  },
  created() {
    numbro.setDefaults({
        thousandSeparated: true,
        mantissa: 2
    })

    this.getInProgress()
    this.getBalanceDue()
    this.getRecentlyPaid()
  }
}

</script>


<style lang="scss" scoped>
.smallTxt {
    font-size: .7em;
}
.btn-sm {
    padding: 0rem 0.25rem;
}
.list-group-item {
    padding: .5rem 1rem;
}
.customerNameCol {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.customerNameCol :first-child {
    flex-shrink: 4; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.customerNameCol > div > .btn {
    border: 1px solid #017BFE;
    text-decoration: none;
}

.customerNameCol > div > .btn:hover {
    background-color: #017BFE;
    color: #fff
}
</style>
