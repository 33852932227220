<template>
    <div>
    <b-button id="show-btn" variant="outline-light" size="sm" @click="showEditCustomerInfoModal"><font-awesome-icon icon="fa-regular fa-edit"></font-awesome-icon> Edit</b-button>

    <b-modal id="editCustomerInfoModal" title="Edit Customer Info" size="xl" @ok="submitUpdate">
        <b-container fluid>
            <b-row>
                <b-col sm>
                    <h5>Contact Information</h5>
                    <b-form-group id="grpLname" description="Last Name">
                        <b-form-input id="lname" ref="lname" v-model="contract.customer.lname" trim autocomplete="off" :state="nameErrorState"></b-form-input>
                    </b-form-group>
                    <b-form-group id="grpFname" description="First Name" >
                        <b-form-input id="fname" v-model="contract.customer.fname" trim autocomplete="off" :state="nameErrorState"></b-form-input>
                    </b-form-group>
                    <h5>Phones</h5>
                    <b-form-group id="grpMphone" description="Mobile Phone" >
                        <b-form-input id="mphone" v-model="contract.customer.phones.mobile" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                    <b-form-group id="grpHphone" description="Home Phone" >
                        <b-form-input id="hphone" v-model="contract.customer.phones.home" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                    <b-form-group id="grpWphone" description="Work Phone" >
                        <b-form-input id="wphone" v-model="contract.customer.phones.work" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                    
            
                </b-col>
                <b-col sm>
                    <h5>Email</h5>
                   <b-form-group id="grpEmail1" description="Email" >
                        <b-form-input id="email1" v-model="contract.customer.emails.primary" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                    <b-form-group id="grpEmail2" description="Email 2" >
                        <b-form-input id="email2" v-model="contract.customer.emails.secondary" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                    <h5>Billing Address</h5>
                    <b-form-group id="grpBstreet" description="Street" >
                        <b-form-input id="bstreet" v-model="contract.customer.address.street" trim autocomplete="off" :state="billingAddressState"></b-form-input>
                    </b-form-group>
                    <b-form-group id="grpBcity" description="City" >
                        <b-form-input ref="bcity" v-model="contract.customer.address.city" @blur="lookupZip('billing')" trim :state="billingAddressState">></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group id="grpBstate" description="State" >
                                <b-form-input ref="bstate" v-model="contract.customer.address.state" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group id="grpBzip" description="Zip Code" >
                                <b-form-input ref="bzip" v-model="contract.customer.address.zip" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                     <!--
                    <br />
                    <h5>Jobsite Address</h5>
                    <b-form-group id="grpSstreet" description="Street" >
                        <b-input-group>
                            <b-form-input id="sstreet" ref="siteaddress" v-model="contract.siteAddress.street" trim autocomplete="off" :state="siteAddressState"></b-form-input>
                            <b-input-group-append>
                                <b-button ref="copyAddressBtn" variant="outline-primary" @click="copyAddress()"> Copy <v-icon name="copy" /></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group id="grpScity" description="City" >
                        <b-form-input ref="scity" v-model="contract.siteAddress.city" @blur="lookupZip('site')" trim autocomplete="off" :state="siteAddressState"></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group id="grpSstate" description="State" >
                                <b-form-input ref="sstate" v-model="contract.siteAddress.state" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group id="grpSzip" description="Zip Code" >
                                <b-form-input ref="szip" v-model="contract.siteAddress.zip" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    -->
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
    
    </div>
</template>

<script>
import "animate.css"
import { getUnixTime } from 'date-fns'

export default {
  name: "editCustomerInfo",
  props: {
      contract: Object,
  },
  data() {
    return {
        nameErrorState: null,
        billingAddressState: null,
        siteAddressState: null
    }
  },
  created() {
      
  },
  methods: {
    async submitUpdate() {
        try {
            let res = await this.$axios.put(`/customer/${this.contract.customer._id}`, this.contract.customer, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.log(res)

            //log it
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `Customer Info Updated.`,
                addInfo: res.config.data,
                job: this.contract._id,
                logUser: this.$state.userInfo.name
            }
            this.$actions.log(newLog)

            this.$bvModal.hide('editCustomerInfoModal')

        } catch (err) {
            console.error(err)
        }
    },
    showEditCustomerInfoModal() {
        this.$bvModal.show('editCustomerInfoModal')
    },
    async lookupZip(loc) {
        //geoc
          let apiKey = "101d562b2155dee5b664021b1042d15e5b4bb01"
          let city = ""
          let street = ""
          let state = ""

          if(loc == 'site') {
              city = this.contract.address.city
              street = this.contract.address.street
              state = this.contract.address.state
          } else {
              city = this.customer.address.city
              street = this.customer.address.street
              state = this.customer.address.state
          }
          
          try {
                
                let res = await this.$axios.get(`https://api.geocod.io/v1.6/geocode?q=${encodeURI(`${street}, ${city}, ${state}`)}&country=us&api_key=${apiKey}`, {
                    headers: {}
                })

                //console.log(res.data)

                 
                let zipCode = res.data.results[0].address_components.zip

                //console.log(`The Zip code is: ${zipCode}`)
                
                
                
                if(loc == 'site') {
                    this.contract.address.zip = zipCode
                    this.$refs.szip.$el.classList.add('animate__animated', 'animate__tada')
                    this.contract.location.lat = res.data.results[0].location.lat
                    this.contract.location.lon = res.data.results[0].location.lng

                } else {
                    this.customer.address.zip = zipCode
                    this.$refs.bzip.$el.classList.add('animate__animated', 'animate__tada')
                }
                
                
            } catch (error) {
                console.error(error)
            }
          
    },
    copyAddress(){
        this.contract.address.street = this.customer.address.street
        this.contract.address.city = this.customer.address.city
        this.contract.address.state = this.customer.address.state
        this.contract.address.zip  = this.customer.address.zip 
        this.lookupZip('site')
    }
  }
}

</script>


<style lang="scss" scoped>

</style>