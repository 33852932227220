<template>
    <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-tag="header"
        header-class="custInfo">
        <template #header>
            <div class="d-flex flex-row justify-content-between">
                <h5 class="mb-0 mt-1 d-inline monoTitle">Customer Info</h5>
                <edit-customer-info class="d-inline" :contract="contract" />
            </div>
        </template>
        <b-card-text>
            <b-container fluid>
                <b-row>
                    <b-col sm="6">
                        <h3 class="monoTitle">{{ contract.customer.lname }}, {{ contract.customer.fname }}</h3>
                        <address>
                            <b>Billing Address</b><br />
                            {{ contract.customer.address.street }}<br />
                            {{ contract.customer.address.city }}, {{ contract.customer.address.state }}
                            {{ contract.customer.address.zip }}
                        </address>
                        <b>Phone Numbers:</b><br />
                        Mobile: {{ contract.customer.phones.mobile }}
                        <b-button variant="link" class="text-success hover-hand d-inline ml-1" v-if="contract.customer.phones.mobile" @click="openTxtMsgModal()">
                            <font-awesome-icon :icon="['fas', 'comment']" scale="1"></font-awesome-icon> <small>Send Text</small>
                        </b-button>
                        <br />
                        Home: {{ contract.customer.phones.home }}<br />
                        Work: {{ contract.customer.phones.work }}<br />
                        <div class="mt-2 mb-2">
                            <b>Email:</b><br />
                            Primary: {{ contract.customer.emails.primary }} <font-awesome-icon class="hover-hand"
                                v-if="contract.customer.emails.primary" @click="email()"
                                icon="fa-regular fa-paper-plane"></font-awesome-icon><br />
                            Secondary: {{ contract.customer.emails.secondary }}<font-awesome-icon class="hover-hand"
                                v-if="contract.customer.emails.secondary" @click="email()"
                                icon="fa-regular fa-paper-plane"></font-awesome-icon><br />
                        </div>
                    </b-col>

                    <b-col sm="6">
                        <b-list-group v-if="contracts.length > 0">
                            <b-list-group-item class="otherJobs" variant="primary">
                                Additional Jobs: {{ contracts.length }}
                                <span class="float-right">
                                    <all-customer-jobs :fname="contract.customer.fname" :lname="contract.customer.lname"
                                        :contracts="contracts" @jobSelected="goToJob" />
                                </span>
                            </b-list-group-item>
                            <b-list-group-item v-for="job in fiveContracts" :key="job._id" class="otherJobs"
                                :to="{ name: 'jobProfile', params: { id: job._id } }">
                                {{ format(fromUnixTime(job.dates.contractDate), "MM/dd/yyyy") }} {{ job.jobType }}
                                <span v-if="job.sold"><b-badge variant="success">Sold</b-badge> {{ job.status }}</span>
                                <span v-else><b-badge variant="secondary">Un-sold</b-badge></span>
                                - {{ job.siteAddress.city }}
                            </b-list-group-item>

                        </b-list-group>
                        <b-list-group v-else>
                            <b-list-group-item class="otherJobs" variant="primary">Other Contracts:
                                0</b-list-group-item>
                            <b-list-group-item class="otherJobs">
                                <b-button @click="gotoCopyContract" variant="outline-secondary">+ New contract for this
                                    customer</b-button>
                            </b-list-group-item>

                        </b-list-group>

                    </b-col>
                </b-row>
            </b-container>

        </b-card-text>

        <!-- Start Text Message Modal-->
        <b-modal id="txtModal" :title="txtMsg.txtTitle" size="md" @ok.prevent="sendTxtMsg"
            ok-title="Send Text" ok-variant="primary" header-bg-variant="primary" header-text-variant="white">
            
            <b-form-radio-group v-model="txtMsg.reviewTxt" :options="txtMsg.reviewTxtOptions" name="reviewTxt" buttons button-variant="outline-primary" size="sm" @change="setMsgTxt()"></b-form-radio-group>
            
            <b-form-group label="From:">
                <b-form-select id="fromNumberSelect" v-model="txtMsg.fromNumber"
                    :options="txtMsg.fromNumberOpts"></b-form-select>
            </b-form-group>
            <b-form-group label="To:">
                <b-form-input id="toNumberTxt" v-model="txtMsg.toNumber" trim
                    :state="txtMsg.toNumberErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNumberErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Name:">
                <b-form-input id="nameTxt" v-model="txtMsg.toName" trim :state="txtMsg.toNameErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNameErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Message text:">
                <b-form-textarea id="msgTxt" v-model="txtMsg.msgTxt" trim rows="4" max-rows="4"
                    :state="txtMsg.msgTxtErrState" :disabled="(txtMsg.reviewTxt==='review') ? true: false"></b-form-textarea>
                <b-form-invalid-feedback id="msgTxt-invalid-feedback">{{ txtMsg.msgTxtErrTxt }}</b-form-invalid-feedback>
                <b-form-text id="msgTxt-help" v-if="txtMsg.reviewTxt==='generic'">Character Count: {{ msgCount }}/160</b-form-text>
            </b-form-group>
            Message Preview:
            <p class="txtMsg send">{{ txtMsgPreview }}</p>

        </b-modal>

    </b-card>
</template>

<script>

import { format } from 'date-fns'
import { fromUnixTime, getUnixTime } from 'date-fns'
import editCustomerInfo from './editCustomerInfo.vue'
import AllCustomerJobs from './allCustomerJobs.vue'

export default {
    name: "customerInfo",
    components: {
        editCustomerInfo,
        AllCustomerJobs
    },
    props: {
        contract: Object,
        contracts: Array
    },
    data() {
        return {
            format: format,
            fromUnixTime: fromUnixTime,
            txtMsg: {
                txtTitle: "Send Message",
                fromNumber: "732-240-3780",
                fromNumberOpts: [
                    { value: "732-240-3780", text: "732-240-3780 (Twin Main)" },
                    { value: "732-240-3788", text: "732-240-3788 (Korey)" },
                    { value: "732-240-3792", text: "732-240-3792 (Kolton)" },
                ],
                toNumber: this.contract.customer.phones.mobile,
                toNumberErrState: null,
                toNumberErrTxt: "",
                toName: this.contract.customer.fname,
                toNameErrState: null,
                toNameErrTxt: "",
                msgTxt: "",
                msgTxtErrState: null,
                msgTxtErrTxt: "Message Text can be 160 characters max.",
                msgTxtDisabled: false,
                reviewTxt: 'review',
                reviewTxtOptions: [
                    { text: "Review/Thank You Text", value: "review"},
                    { text: "Generic Text Message", value: "generic"}
                ]
            }
            
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        userInfo() {
            return this.$state.userInfo
        },
        fiveContracts() {
            let fiveContracts = []
            if (this.contracts.length > 5) {
                for (let i = 0; i < 5; i++) {
                    fiveContracts.push(this.contracts[i])
                }
                return fiveContracts
            } else {
                fiveContracts = this.contracts
                return fiveContracts
            }

        },
        msgCount() {
            const msgLen = this.txtMsgPreview.length
            return msgLen+1
        },
        msgJobType() {
            if (this.contract.jobType == 'Siding') {
                    return "new siding"
                } else if (this.contract.jobType == 'Rip ReRoof' || this.contract.jobType == 'New Construction' || this.contract.jobType == 'Cover Roof') {
                    return "new roofing"
                } else if (this.contract.jobType == 'Windows') {
                    return "new windows"
                } else if (this.contract.jobType == 'Gutters') {
                    return "new gutters"
                } else if (this.contract.jobType == 'Roof Repair') {
                    return "recent roof repair"
                } else if (this.contract.jobType == 'Siding Repair') {
                    return "recent siding repair"
                } else {
                    return "recent job"
                }
        },
        txtMsgPreview() {
            if(this.txtMsg.reviewTxt === 'review'){
                return `Hi ${this.txtMsg.toName},\nWe hope you're enjoying your ${this.msgJobType}! We'd love to hear about your experience. Would you mind leaving us a 5-star review on Google? https://bit.ly/TwinRate Thanks, it really helps! -Twin`
            } else {
                return `${this.txtMsg.toName}, ${this.txtMsg.msgTxt}`
            }
            
        }
        /*
        streetview () {
            const key = "AIzaSyDZbo8X_q8crHijPzXsjD6krj2luf-00Jw"
            return `https://maps.googleapis.com/maps/api/streetview?location=${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}&size=300x135&key=${key}`
        }
        */
    },
    methods: {
        openTxtMsgModal() {
            this.txtMsg.msgTxt = `We hope you're enjoying your ${this.msgJobType} ! We'd love to hear about your experience. Would you mind leaving us a 5-star review on Google? https://bit.ly/TwinRate Thanks, it really helps! -Twin`
            this.$bvModal.show('txtModal')            
        },
        setMsgTxt() {
            console.log('click')
            if(this.txtMsg.reviewTxt === 'review'){
                this.txtMsg.msgTxt = `We hope you're enjoying your ${this.msgJobType}! We'd love to hear about your experience. Would you mind leaving us a 5-star review on Google? https://bit.ly/TwinRate Thanks, it really helps! -Twin`
            } else {
                this.txtMsg.msgTxt = `Type your message here...`
            }
        },
        async sendTxtMsg() {
            console.log(this.txtMsg)
            //Check to see that there is no missing data...
            //toNumber cant be blank
            if (this.txtMsg.toNumber.trim() == "") {
                this.txtMsg.toNumberErrTxt = "The 'To:' field can not be blank!"
                this.txtMsg.toNumberErrState = false
                return
            } else {
                this.txtMsg.toNumberErrState = true
            }

            //toNumber can only contain numerals and '-'
            const regex = /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/

            //The regular expression you provided, ^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$

            // ^: This matches the beginning of the string. So, the entire text you're checking should start with the pattern that follows.
            // [0-9]{3}: This part matches three consecutive digits (from 0 to 9) exactly three times.
            // [-]: This matches a literal hyphen "-" character.
            // ?: This quantifier makes the preceding element (the hyphen in this case) optional. So, it can appear zero or one time.
            // The above pattern ([0-9]{3}[-]?) is repeated twice more to match three sets of digits potentially separated by hyphens.
            // $: This matches the end of the string. So, the entire text you're checking should end with the pattern that precedes this.
            // Here are some examples of valid strings that this regex would match:

            // 123-456-7890
            // 1234567890 (without hyphens)

            if (!regex.test(this.txtMsg.toNumber)) {
                this.txtMsg.toNumberErrTxt = "The 'To:' fieldmust be a properly formatted phone number and can only contain 0-9 and the '-' character. i.e. 732-240-3780"
                this.txtMsg.toNumberErrState = false
                return
            } else {
                this.txtMsg.toNumberErrState = true
            }

            //toName cant be blank
            if (this.txtMsg.toName.trim() == "") {
                this.txtMsg.toNameErrTxt = "The 'Name:' field can not be blank!"
                this.txtMsg.toNameErrState = false
                return
            } else {
                this.txtMsg.toNameErrState = true
            }

            const payload = {
                to_Number: this.txtMsg.toNumber.replace(/-/g, ""),
                from_Number: this.txtMsg.fromNumber.replace(/-/g, ""),
                text: this.txtMsg.msgTxt
            }
            console.log("sending text message...")
            console.log(payload)

            try {
                const res = await this.$axios.post('/dialpad/', payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(`Response recieved. txt status is: ${res.status.toString()}`)

                if (res.status === 200) {

                    //notify user of the success
                    this.$bvToast.toast('👍🏻 Text message was sent.', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    })

                    //log the text message
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Text messaage was sent.`,
                        addInfo: `The message was sent to ${this.txtMsg.toNumber} from ${this.txtMsg.fromNumber}. The message text was: ${this.txtMsg.msgTxt}`,
                        job: this.contract._id,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)

                } else {
                    console.error(res)
                    this.$bvToast.toast(`Woops. Error Status text is: ${res.errorText}  👎🏻`, {
                        title: 'Oh no!',
                        variant: 'danger',
                        solid: true
                    })
                }

                this.$bvModal.hide('txtModal')

            } catch (error) {
                console.log(error)
                this.$bvToast.toast('Something went wrong. Check the console for errors. 👎🏻', {
                    title: 'Oh no!',
                    variant: 'danger',
                    solid: true
                })

                this.$bvModal.hide('txtModal')

            }

        },
        gotoCopyContract() {
            this.$router.push({ name: 'copyContract', params: { id: this.contract.customer._id } })
        },
        goToJob(job) {
            console.log(`Job selected...routing to: ${job}`)
            this.$router.push({ name: 'jobProfile', params: { id: job } })
        },
        email() {
            this.$router.push({ name: 'email', params: { customer: this.contract.customer._id, job: this.contract._id, email: this.contract.customer.emails.primary, cc: this.contract.customer.emails.secondary, fname: this.contract.customer.fname } })
        },
        firstLetterCap(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase()
        },
        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        gotoMap() {
            this.$router.push({ name: 'Map', params: { location: `${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}` } })
        },
        getIcon(jobType) {
            if (jobType.trim() === 'ripReroof') {
                return { icon: 'caret-up', displayName: 'Rip Re-Roof' }
            } else if (jobType.trim().toLowerCase() === 'decking') {
                return { icon: 'grip-lines', DisplayName: 'Decking' }
            } else if (jobType.trim().toLowerCase() === 'roofRepair') {
                return { icon: 'caret-up', displayName: 'Roof Repair' }
            } else if (jobType.trim().toLowerCase() === 'other') {
                return { icon: 'network-wired', displayName: 'Other' }
            } else if (jobType.trim().toLowerCase() === 'railing') {
                return { icon: 'ruler-horizontal', displayName: 'Railing' }
            } else if (jobType.trim().toLowerCase() === 'gutters') {
                return { icon: 'tint', displayName: 'Gutters' }
            } else if (jobType.trim().toLowerCase() === 'gutterRepair') {
                return { icon: 'tint', displayName: 'Gutter Repair' }
            } else if (jobType.trim() === 'gutterCleaning') {
                return { icon: 'leaf', displayName: 'Gutter Cleaning' }
            } else if (jobType.trim().toLowerCase() === 'windows') {
                return { icon: 'border-all', displayName: 'Windows' }
            } else if (jobType.trim().toLowerCase() === 'windowRepair') {
                return { icon: 'border-all', displayName: 'Window Repair' }
            } else if (jobType.trim().toLowerCase() === 'coverRoof') {
                return { icon: 'caret-up', displayName: 'Cover Re-Roof' }
            } else if (jobType.trim().toLowerCase() === 'siding') {
                return { icon: 'align-left', displayName: 'Siding' }
            } else if (jobType.trim().toLowerCase() === 'sidingRepair') {
                return { icon: 'align-left', displayName: 'Siding Repair' }
            } else if (jobType.trim().toLowerCase() === 'trimjob') {
                return { icon: 'align-left', displayName: 'Trim Job' }
            } else if (jobType.trim().toLowerCase() === 'newConstructRoof') {
                return { icon: 'caret-up', displayName: 'New Construction Roof' }
            } else {
                return { icon: 'network-wired', displayName: 'Other' }
            }
        }
    }
}

</script>


<style lang="scss" scoped>
.custInfo {
    padding-top: 5px;
    padding-bottom: 5px;
}

.otherJobs {
    padding-top: 6px;
    padding-bottom: 6px;
}

.hover-hand:hover {
    cursor: pointer;
}
.txtMsg {
    max-width: 375px;
    word-wrap: break-word;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
    }
}

.send {
    color: white;
    background: #5BC236;
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: #5BC236;
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
    }
}

</style>