<template>
    <div>
        <div class="d-flex justify-content-between mb-3">
            <h2 class="monoTitle title">Available Brochures</h2>
            <b-button variant="outline-success" @click="showUpload"><font-awesome-icon :icon="['fas', 'upload']" /> Upload Brochure</b-button>
        </div>
        <div class="w-100 d-flex flex-wrap flex-column flex-lg-row">
            <b-card v-for="brochure in brochures" :key="brochure.name" class="file-card">
                <b-media>
                    <template #aside>
                        <b-img :src="url+'/brochures/'+brochure.thumbnail" width="50"></b-img>
                    </template>
                    <h5 class="mt-0">{{ brochure.name }}</h5>
                    <p> 
                        <b-button variant="link" :to="{name:'fileviewer', params: {fileName: brochure.name, brochure: true}}" class="monoTitle">View</b-button>
                        
                        <b-button variant="link" @click="showRename(brochure.name)" class="monoTitle">Re-Name</b-button>
                        <b-button variant="link" @click="showDelete(brochure.name)" class="monoTitle">Delete</b-button>
                    </p>
                </b-media>
            </b-card>
        </div>
        <b-modal id="renameModal" v-model="showRenameModal" title="File Rename" @ok.prevent="reNameFile">
            <b-form-group label="File Name" :state="renameState"  invalid-feedback="The 'File Name' can not be blank.">
                <b-form-input v-model="newFileName" placeholder="Enter a new filename" :state="renameState"></b-form-input>
            </b-form-group>
        </b-modal>
        
        <b-modal id="deleteModal" header-bg-variant="danger" header-text-variant="light" okTitle="delete" v-model="showDeleteModal" title="Delete File?">
            Are you sure you want to delete this file?
            <template #modal-footer>
                <b-button @click="showDeleteModal = false">Cancel</b-button>
                <b-button variant="danger" @click.prevent="deleteFile"><font-awesome-icon icon="fa-solid fa-trash" /> Delete</b-button>
            </template>
        </b-modal> 

        <b-modal id="uploadModal" size="lg" header-bg-variant="info" header-text-variant="light" v-model="showUploadModal" title="Upload Brochure">
            <!--UPLOAD-->
            <form enctype="multipart/form-data" novalidate>
               
                <div class="dropbox">
                    <input type="file" id="files" multiple :disabled="isSaving" @change="filesChange($event.target.files); fileCount = $event.target.files.length" class="input-file">
                    <p v-if="isInitial">
                        <font-awesome-icon size="2x" icon="fa-solid fa-cloud-arrow-up" class="mb-2"></font-awesome-icon><br>
                        Drag your brochure here to upload<br> or click here for the file browser.
                    </p>
                    <p v-if="isSaving">
                        <font-awesome-icon size="2x" icon="fa-solid fa-spinner" spin-pulse class="mb-2"></font-awesome-icon><br>
                        Uploading {{ fileCount }} files...
                    </p>
                    <p v-if="isSuccess" class="text-light text-center">
                        <font-awesome-icon size="2x" icon="fa-regular fa-thumbs-up" class="mb-2"></font-awesome-icon><br>
                        All {{ fileCount }} files uploaded successfully!
                    </p>
                    <div v-if="returnedFileNames">
                        <div class="thumbs-box">
                            <div v-for="file in returnedFileNames" :key="file.filename">
                                <b-img class="thumb" :src="url+'/brochures/tn_'+file.filename.slice(0, file.filename.length-3)+'jpg'" :alt="file.filename"></b-img>
                            </div>
                        </div>
                    </div>
                    <p v-if="isFailed" class="text-danger">\
                        <font-awesome-icon size="2x" icon="fa-solid fa-circle-exclamation" class="mb-2 text-danger"/><br>
                        FAILED: {{uploadError}}
                    </p>
                </div>
            </form>
            <template #modal-footer>
                <b-button @click="showUploadModal = false" variant="success" v-if="currentStatus === 'STATUS_SUCCESS'">Done</b-button>
                <b-button @click="showUploadModal = false" variant="info" v-else>Cancel</b-button>
            </template>
        </b-modal> 
    </div>
    
</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
    name: 'brochuresAdmin',
    data() {
        return {
            brochures: [],
            format: format,
            fromUnixTime: fromUnixTime,
            url: this.$baseUrl,
            showRenameModal: false,
            newFileName: "",
            renameState: null,
            origFilename: "",
            origFileExt: "",
            showDeleteModal: false,
            showUploadModal: false,
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            returnedFileNames: null,
        }
    },
    computed: {
       userInfo() {
          return this.$state.userInfo
       },
       isMobile () {
          return this.$state.isMobile
       },
       isInitial() {
            return this.currentStatus === "STATUS_INITIAL"
        },
        isSaving() {
            return this.currentStatus === "STATUS_SAVING"
        },
        isSuccess() {
            return this.currentStatus === "STATUS_SUCCESS"
        },
        isFailed() {
            return this.currentStatus === "STATUS_FAILED"
        }
    },
    methods: {
        async getBrochures() {
            console.log("calling get brochures...")
            try {
                const res = await this.$axios.get(`/dirlist/brochures/list`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                this.brochures = res.data
                
            } catch (err) {
                console.log(err)
            
            }
        },
        showDelete(filename) {
            this.fileToDelete = filename
            this.showDeleteModal = true
        },
        showUpload() {
            this.showUploadModal = true
        },
        async deleteFile() {
            let payload = {
                contractId: "0",
                fileName: this.fileToDelete
            }

            try {
                const res = await this.$axios.put(`/dirlist/delete`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                })
                console.log(res)
                
                //log it 
                const newLog = {
                    logMsg: `A Brochure was deleted.`,
                    addInfo: `Deleted filename: ${this.fileToDelete}`,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog) 

                //Notify user
                this.$root.$bvToast.toast('🚫 Brochure was deleted successfully.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })

                //remove the item for the local array
                let index = this.brochures.findIndex(el => el.name === this.fileToDelete)
                console.log(`Index ${index} is the number of the item to remove. Name: ${this.fileToDelete}`)
                this.brochures.splice(index, 1)

                this.fileToDelete = ""
                this.showDeleteModal = false

            } catch (err) {
                console.error("Rename ERROR", err)
            }

        },
        showRename(filename) {
            this.origFilename = filename

            this.showRenameModal = true
        },
        async reNameFile() {
            //first check that filename isn't blank
            if(this.newFileName.trim() === ""){
                this.renameState = false
                return
            } else {
                this.renameState = true
            }
            
            this.showRenameModal = false
            
    
            if(this.origFilename.substring(this.origFilename.length - 4) === 'jpeg'){
                this.origFileExt = this.origFilename.slice(-5)
            } else {
                this.origFileExt = this.origFilename.slice(-4)
            }

            let payload = {
                contractId: "0",
                newFileName: `${this.newFileName}${this.origFileExt}`,
                oldFileName: this.origFilename
            }
            
            console.table(payload)

            try {
                const res = await this.$axios.put(`/dirlist/rename`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                })
                console.log(res)

                //log it 
                const newLog = {
                    logMsg: `A File was renamed successfully`,
                    addInfo: `Old Filename: ${this.origFilename}, New Filename: ${this.newFileName}${this.origFileExt}`,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog) 
                //Notify user
                this.$bvToast.toast('👍🏻 File rename was successful.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })

            } catch (err) {
                console.error("Rename ERROR", err)
            }
            this.origFilename = ""
            
        },
        uploadReset() {
            // reset form to initial state
            this.currentStatus = "STATUS_INITIAL"
            this.uploadedFiles = []
            this.uploadError = null
        },
        async uploadSave(formData) {
            // upload data to the server
            this.currentStatus = "STATUS_SAVING"
            console.log(formData)
            
            try {
                const res = await this.$axios.post("/upload/brochure", formData, {
                    headers: { Authorization: `Bearer ${this.$state.token}`}
                })

                console.log(res)
                this.currentStatus = "STATUS_SUCCESS"
                this.returnedFileNames = res.data.files

                res.data.files.forEach(file => {
                     this.brochures.push(
                        {
                            extension: file.filename.slice(-4),
                            name: file.filename,
                            path: file.path,
                            thumbnail: `tn_${file.filename.slice(0, file.filename.length-3)}jpg`,
                            type: "file"
                        }
                     )
                })

                this.brochures.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)

                //logit
                this.$actions.log({
                    logMsg: `${res.data.files.length} file(s) uploaded.`,
                    job: this.$route.params.id,
                    logUser: this.userInfo.name,
                    addInfo: JSON.stringify(res.data.files ,null,'\t')
                })

            } catch (err) {
                console.error(err)
                this.currentStatus = "STATUS_FAILED"
                this.uploadError = err
            }

        },
        filesChange(fileList) {
    
            if (!fileList.length) return

            const formData = new FormData()
            // append the files to FormData
            const files = document.getElementById("files")

            for(let i=0; i < files.files.length; i++){
                formData.append("files", files.files[i])
            }

            // upload Data
            this.uploadSave(formData);
        }
    },
    created() {
        this.getBrochures()
    },
    mounted() {
        this.uploadReset()
    }    
}
</script>

<style lang="scss" scoped>
.file-card {
    box-sizing: border-box;
    margin: 0 0em .75em 0em;
}

.title {
    display: inline;
}

@media screen and (min-width: 992px) {
    .file-card {
        width: calc(50% - .5em);
        margin: 0 .25em .75em .25em;
    }

    .dropbox {
        background: #63b9f2;
        color: #FFF;
        padding: 10px 10px;
        min-height: 200px;
        /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue;
        /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }

    .input-file {
        opacity: 0;
        /* invisible but it's there! */
        width: 100%;
        height: 200px;
        position: absolute;
        cursor: pointer;
    }

    .thumbs-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .thumb {
        margin: 1em 1em 1em 1em;
        border: 2px solid white;
    }
}
</style>