
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 pb-5 mb-5' : 'mt-3' ]">
    <div class="mb-2" v-if="loading">
        <b>Building Report:</b> <b-progress :value="progress.value" :max="progress.max" variant="primary" show-progress height="2rem"></b-progress>
    </div>
    <div class="h4">
        <b-form inline>
            Sales for Year:
            <b-form-select class="ml-2 mr-2" v-model="reportYear" :options="yearOptions" @change="getMonthSales"></b-form-select> 
            Month: <b-form-select class="ml-2" v-model="reportMonth" :options="monthOptions" @change="getMonthSales"></b-form-select>
        </b-form>
    </div>
    <b-card v-if="!loading" header="Sales by Month" class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
    <b-list-group flush>
        <b-list-group-item>
            <b-row no-gutters class="spreadsheet">
                <b-col class="text-truncate d-inline-block" >
                    Start Date
                </b-col>
                <b-col md="2" class="text-truncate d-inline-block" >  
                    Job Name
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Total Sale
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Tax
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Roofing
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Siding
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Windows
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    gutters
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Permits
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    Credit Card
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-for="job in monthSales" :key="job._id">
            <b-row no-gutters>
                <b-col class="text-truncate d-inline-block" >  
                    {{format(fromUnixTime(job.dates.startDate), 'MM/dd/yyyy')}} 
                </b-col>
                <b-col md="2" class="text-truncate d-inline-block" >
                    <a :href="`/jobprofile/${job._id}`"><b>{{job.customer.lname}}, {{job.customer.fname}}</b></a>
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.total).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.tax).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.roofing).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.siding).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.windows).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.gutters).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.permits).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(job.moneyTotals.creditCard).format()}}
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item variant="secondary">
            <b-row no-gutters>
                <b-col class="text-truncate d-inline-block" >  
                   &nbsp;
                </b-col>
                <b-col md="2" class="text-truncate d-inline-block text-right" >
                    Total w/tax<br/>
                    without tax
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.total).format()}}<br/>
                    ${{numbro(grandTotals.total).subtract(grandTotals.tax).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.tax).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.roofing).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.siding).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.windows).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.gutters).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.permits).format()}}
                </b-col>
                <b-col class="text-truncate d-inline-block text-right" >  
                    ${{numbro(grandTotals.creditCard).format()}}
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
    </b-card>

</b-container>
</template>

<script>
import numbro from "numbro"
import { format } from 'date-fns'
import { fromUnixTime, subMonths } from 'date-fns'

export default {
  name: "salesByMonth",
  components: {
    
  },
  data() {
    return {
      monthSales: [],
      reportMonth: 0,
      reportYear: 0,
      numbro: numbro,
      format: format,
      fromUnixTime: fromUnixTime,
      progress: {
        value: 0,
        max: 0
      },
      loading: true,
      grandTotals: {
        siding: 0,
        roofing: 0,
        windows: 0,
        gutters: 0,
        permits: 0,
        tax: 0,
        creditCard: 0,
        total: 0,
      },
      monthOptions: [
        {value: 0, text: "Jan"},
        {value: 1, text: "Feb"},
        {value: 2, text: "Mar"},
        {value: 3, text: "Apr"},
        {value: 4, text: "May"},
        {value: 5, text: "Jun"},
        {value: 6, text: "Jul"},
        {value: 7, text: "Aug"},
        {value: 8, text: "Sep"},
        {value: 9, text: "Oct"},
        {value: 10, text: "Nov"},
        {value: 11, text: "Dec"},
      ],
      yearOptions: [
      {value: 2002, text: "2002"},
      {value: 2003, text: "2003"},
      {value: 2004, text: "2004"},
      {value: 2005, text: "2005"},
      {value: 2006, text: "2006"},
      {value: 2007, text: "2007"},
      {value: 2008, text: "2008"},
      {value: 2009, text: "2009"},
      {value: 2010, text: "2010"},
      {value: 2011, text: "2011"},
      {value: 2012, text: "2012"},
      {value: 2013, text: "2013"},
      {value: 2014, text: "2014"},
      {value: 2015, text: "2015"},
      {value: 2016, text: "2016"},
      {value: 2017, text: "2017"},
      {value: 2018, text: "2018"},
      {value: 2019, text: "2019"},
      {value: 2020, text: "2020"},
      {value: 2021, text: "2021"},
      {value: 2022, text: "2022"},
      {value: 2023, text: "2023"},
      {value: 2024, text: "2024"},
      {value: 2025, text: "2025"},
      {value: 2026, text: "2026"},
      {value: 2027, text: "2027"}
      ]
    }
  },
  computed: {
    isMobile() {
        return this.$state.isMobile
    }
  },
  methods: {
    async getMonthSales() {
        this.grandTotals = {
            siding: 0,
            roofing: 0,
            windows: 0,
            gutters: 0,
            permits: 0,
            creditCard: 0,
            tax: 0,
            total: 0
        }
        this.progress.max = 0
        this.progress.value = 0
        this.loading = true

        let payload = {
            month: this.reportMonth,
            year: this.reportYear
        }
        
        console.log(`getting monthly sales records for month: ${payload.month} and year: ${payload.year}`)

        try {
            console.time("getMoneyLoop")
            const jobs = await this.$axios.post(`/job/getSalesByMonth`, payload, {
                headers: { Authorization: `Bearer ${this.$state.token}` }
            })

            console.log(jobs.data)
            this.progress.max = jobs.data.length

            let jobsWithMoneyData = []
            //console.time("getMoneyLoop")
            for(const job of jobs.data) {
                
                let moneyData = await this.getMoney(job._id)
                let combinedData = {
                    money: moneyData,
                    moneyTotals: {
                        siding: 0,
                        roofing: 0,
                        windows: 0,
                        gutters: 0,
                        permits: 0,
                        tax: 0,
                        creditCard: 0,
                        payments: 0,
                        total: 0,
                        balance: 0
                    },
                    ...job
                }
                //console.log(combinedData)
                moneyData.forEach(money => {
                    if(money.debit.toLowerCase() === "debit") {
                        combinedData.moneyTotals.total = combinedData.moneyTotals.total + money.amount
                        combinedData.moneyTotals.siding = combinedData.moneyTotals.siding + money.sales.siding
                        combinedData.moneyTotals.roofing = combinedData.moneyTotals.roofing + money.sales.roofing
                        combinedData.moneyTotals.windows = combinedData.moneyTotals.windows + money.sales.windows
                        combinedData.moneyTotals.gutters = combinedData.moneyTotals.gutters + money.sales.gutters
                        combinedData.moneyTotals.permits = combinedData.moneyTotals.permits + money.sales.permits
                        if(money.sales.creditCard){
                            combinedData.moneyTotals.creditCard = combinedData.moneyTotals.creditCard + money.sales.creditCard
                        }
                        combinedData.moneyTotals.tax = combinedData.moneyTotals.tax + money.sales.tax
                    }
                    if(money.debit.toLowerCase() === "credit") {
                        combinedData.moneyTotals.payments = combinedData.moneyTotals.payments + money.amount
                    }
                })
                combinedData.moneyTotals.balance = combinedData.moneyTotals.total - combinedData.moneyTotals.payments

                this.grandTotals.roofing = this.grandTotals.roofing + combinedData.moneyTotals.roofing
                this.grandTotals.siding = this.grandTotals.siding + combinedData.moneyTotals.siding
                this.grandTotals.windows = this.grandTotals.windows + combinedData.moneyTotals.windows
                this.grandTotals.gutters = this.grandTotals.gutters + combinedData.moneyTotals.gutters
                this.grandTotals.permits = this.grandTotals.permits + combinedData.moneyTotals.permits
                this.grandTotals.creditCard = this.grandTotals.creditCard + combinedData.moneyTotals.creditCard
                this.grandTotals.tax = this.grandTotals.tax + combinedData.moneyTotals.tax
                this.grandTotals.total = this.grandTotals.total + combinedData.moneyTotals.total
                
                jobsWithMoneyData.push(combinedData)
                this.progress.value = this.progress.value + 1
                if(this.progress.value == this.progress.max) {
                    this.loading = false
                }

            }
            console.timeEnd("getMoneyLoop")
            
            this.monthSales = jobsWithMoneyData
            
            //this.loading = false
        } catch(err){
            console.log(err)
        }
    },
    async getMoney(id) {
        
        try {
            let res = await this.$axios.get(`/money/job/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })
            
            return res.data

        } catch (err) {
            console.error(err)
        }
    }
  },
  created() {
    numbro.setDefaults({
        thousandSeparated: true,
        mantissa: 2
    })

    //set reportMonth & Year default to last month
    let today = new Date()
    let lastMonth = subMonths(today, 1)

    this.reportYear = lastMonth.getFullYear()
    this.reportMonth = lastMonth.getMonth()
    this.getMonthSales()
  }
}

</script>


<style lang="scss" scoped>
.smallTxt {
    font-size: .7em;
}
.btn-sm {
    padding: 0rem 0.25rem;
}
.list-group-item {
    padding: .2rem .5rem;
    font-size: .8em;
}
.customerNameCol {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.customerNameCol :first-child {
    flex-shrink: 4; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.customerNameCol > div > .btn {
    border: 1px solid #017BFE;
    text-decoration: none;
}

.customerNameCol > div > .btn:hover {
    background-color: #017BFE;
    color: #fff
}
</style>
