<template>
    <div>
    <b-button id="show-btn" variant="outline-primary" size="sm" pill @click="showAllJobs">View All</b-button>

    <b-modal id="allJobsModal" size="xl" scrollable ok-disabled>
        <template #modal-title>
            All Jobs for {{lname}}, {{fname}}
        </template>
        <template #default>
            <b-list-group>
                <b-list-group-item v-for="job in contracts" :key="job._id" @click="goToJob(job._id)">
                     <b-row no-gutters>
                         <b-col cols="11">
                            <b>{{format(fromUnixTime(job.dates.contractDate), "MM/dd/yyyy")}} {{job.jobType}} </b>
                            <span v-if="job.sold"><b-badge variant="success">Sold</b-badge> {{job.status}}</span>
                            <span v-else><b-badge variant="secondary">Un-sold</b-badge></span>
                            Jobsite: {{job.siteAddress.city}}
                            <br />
                            {{job.scopeText | stripHTML}}
                         </b-col>
                         <b-col cols="1">
                             <div class="float-right"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon></div>
                         </b-col>

                     </b-row>
                </b-list-group-item>
            </b-list-group>
        
        </template>
        <template #modal-footer="{cancel}">
            <b-button variant="outline-danger" @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>
    
    </div>
</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
  name: "allCustomerJobs",
  props: {
      contracts: Array,
      fname: String,
      lname: String
  },
  data() {
    return {
        format: format,
        fromUnixTime: fromUnixTime
    }
  },
  methods: {
    showAllJobs() {
        this.$bvModal.show('allJobsModal')
    },
    goToJob(job) {
        this.$bvModal.hide('allJobsModal')
        //this.$router.push({name: 'jobProfile', params: { id : id } })
        this.$emit('jobSelected', job)
    }
  }
}

</script>


<style lang="scss" scoped>

</style>