<template>
    <div>
    <b-badge id="newNoteBtn" variant="primary" @click="showNewNoteModal()"><font-awesome-icon icon="plus" scale=".7" class="newTixBtn" /> Note</b-badge>

    <b-modal id="newNoteModal" title="Add New Job Note" size="lg" @ok="addNote()" header-bg-variant="primary" header-text-variant="light">
        <b-container fluid>
           
            <b-form-group label="Note Text:" >
                <b-form-textarea id="noteText" v-model="noteText" trim autocomplete="off" ></b-form-textarea>
            </b-form-group>     
            
        </b-container>
    </b-modal>
    
    </div>
</template>

<script>
import { getUnixTime } from 'date-fns'

export default {
  name: "newNoteFrm",
  props: {
      jobId: String,
  },
  data() {
    return {
        noteText: ""
    }
  },
  computed: {
    userInfo () {
        return this.$state.userInfo
    }
  },
  methods: {
    showNewNoteModal() {
        this.$bvModal.show('newNoteModal')
    },
    async addNote() {

        let payload = {
            _id: this.jobId,
            noteText: this.noteText,
            noteDate: getUnixTime(new Date())
        }

        try {
            let res = await this.$axios.post(`/job/note`, payload, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.group("Response from /job/addNote API call:")
                console.table(res.data)
            console.groupEnd()

            //log it
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `New note added for job: ${payload._id}`,
                addInfo: `Note Data: ${JSON.stringify(payload)}`,
                job: payload._id,
                logUser: this.userInfo.name
            }

            this.$actions.log(newLog)
            
            this.$emit("new-note-added", payload)


        } catch (err) {
            console.error(err)
        }

        this.noteText = ""
        this.$bvModal.hide('newNoteModal')
    }
  }
}

</script>


<style lang="scss" scoped>
    .badge:hover {
        cursor: pointer;
    }
</style>