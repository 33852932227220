<template>
<div>
  
  <b-card no-body>
    <template #header>
      <div class="d-flex flex-row justify-content-between">
        <h5 class="mb-0 monoTitle">Logs</h5>
      </div>
    </template>
    <b-list-group>  
      <b-list-group-item v-for="log in currentPageLogs" :key="log._id" @click="showInfo(log._id)">
        <b-row>
          <b-col md="2"><small>{{format(fromUnixTime(log.logTime), 'MM/dd/yy')}}</small></b-col>
          <b-col md="2" class="text-truncate">{{log.logUser}}</b-col>
          <b-col md="6" class="text-truncate">
            <div v-if="log.email" class="d-inline">
              <b-badge pill variant="primary">Email</b-badge> 
              <font-awesome-icon v-if="log.email.delivered.length>0" icon="fa-solid fa-box-open" class="text-secondary ml-1"/>
              <font-awesome-icon v-if="log.email.open.length>0" icon="fa-regular fa-envelope-open" class="text-success ml-1" />
              <font-awesome-icon v-if="log.email.bounce.length>0" icon="fa-solid fa-ban" class="text-danger ml-1"/>
            </div>
            {{log.logMsg}}
          </b-col>


        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-pagination class="mb-0 d-inline-flex" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination> Total logs: {{totalRows}}
      </b-list-group-item>
    </b-list-group>  
  </b-card>
  <p>&nbsp;</p>
  
  <b-modal id="moreInfoModal" centered size="lg" title="Log Info" v-model="showModal" header-bg-variant="primary" header-text-variant="light">
    <p>
      <b>Log Time:</b> {{format(fromUnixTime(selectedLog.logTime), 'MM/dd/yy H:mm')}}<br>
      <b>User:</b> {{selectedLog.logUser}}<br>
      <b>Message:</b> {{selectedLog.logMsg}}<br>
      <span v-if="selectedLog.recip">
        <b>Recipient:</b> {{selectedLog.recip}}<br>
      </span>
      <span v-if="selectedLog.email.cc">
        <b>cc:</b> {{selectedLog.email.cc}}<br>
      </span>
      <span v-if="selectedLog.url">
        <b>URL:</b> {{selectedLog.url}}<br>
      </span>
      <span v-if="selectedLog.emailType">
        <b>Email Type:</b> {{selectedLog.emailType}}<br>
      </span>
    </p>
    <p>
      <b>Additional Information:</b><br>
      {{selectedLog.addInfo}}
    </p>
    <b-list-group v-if="selectedLog.email" horizontal="md" class="mb-2">
      <b-list-group-item>
        Sent<br>
        <small><div>{{format(fromUnixTime(selectedLog.email.sentTime), 'MM/dd/yy H:mm:ss')}}</div></small>
      </b-list-group-item>
      <b-list-group-item>
        Delivered<br>
        <small><div v-for="time in selectedLog.email.delivered" :key="time.timestamp">{{format(fromUnixTime(time.timestamp), 'MM/dd/yy H:mm:ss')}}</div></small>
      </b-list-group-item>
      <b-list-group-item>
        Opened<br>
        <small><div v-for="time in selectedLog.email.open" :key="time.timestamp">{{format(fromUnixTime(time.timestamp), 'MM/dd/yy H:mm:ss')}}</div></small>
      </b-list-group-item>
      <b-list-group-item>
        Bounce<br>
        <small><div v-for="time in selectedLog.email.bounce" :key="time.timestamp">{{format(fromUnixTime(time.timestamp), 'MM/dd/yy H:mm:ss')}}</div></small>
      </b-list-group-item>
    </b-list-group>

    <div v-if="selectedLog.email">
      <b-list-group v-if="selectedLog.email.click.length > 0">
        <b-list-group-item>
          Clicks<br>
        </b-list-group-item>
        <b-list-group-item v-for="time in selectedLog.email.click" :key="time.timestamp">
          <small>
            <div>
              {{format(fromUnixTime(time.timestamp), 'MM/dd/yy H:mm:ss')}}
              - {{time.url}} <a :href="time.url" target="_blank">View</a>
            </div>
          </small>
        </b-list-group-item>
      </b-list-group>
    </div>

    
  </b-modal>
</div>
</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
    name: 'logtabs',
    props: {
        jobId: String,
        
    },
    data() { 
        return {
            format: format,
            fromUnixTime: fromUnixTime,
            logs: [],
            currentPage: 1,
            perPage: 10,
            showModal: false,
            selectedLog: {
              cc: "",
              logTime: Math.floor(Date.now() / 1000),
              logUser: "",
              logMsg: "",
              addInfo:"",
              email: {
                sentTime: "",
                cc: null,
                delivered: [],
                open: [],
                click : [],
                bounce: [],
                events: []
              }
            }
        }
    },
    computed: {
      totalRows () {
        return this.logs.length
      },
      currentPageLogs () {
        return this.logs.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
      }
    },
    methods: {
      async getLogs() {
        try {
            let res = await this.$axios.get(`/log/job/${this.jobId}`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            this.logs = res.data.reverse()

        } catch (err) {
            console.error(err)
        }
      },
      async showInfo(id){
        let index = this.logs.findIndex( log => {
          return log._id === id
        })

        //console.log(index)
        let selectedLog = this.logs[index]
        console.log(this.logs[index])

        if(selectedLog.email){
          if(!selectedLog.email.cc){
            selectedLog.email.cc = ""
          }
        } else {
          selectedLog.email = {
            sentTime: "",
            cc: "",
            delivered: [],
            open: [],
            click : [],
            bounce: [],
            events: []
          }
        }
        /*
        if(selectedLog.hasOwnProperty("email")) {
          console.log("has Email")
          if(!selectedLog.email.hasOwnProperty("cc")){
            console.log("has Email but no cc")
            selectedLog.email.cc = ""
          }
        } else {
          console.log("has no email")
          selectedLog.email = {
            sentTime: "",
            cc: "",
            delivered: [],
            open: [],
            click : [],
            bounce: [],
            events: []
          }
        }
        */
        console.log(selectedLog)

        this.selectedLog = selectedLog

        this.showModal = true
      }
    },
    created() {
        this.getLogs()
    }    
}
</script>

<style lang="scss" scoped>
    pre {
      white-space: pre-wrap;  
    }
    .list-group-item {
      padding-top:  3px;
      padding-bottom: 3px;
      font-size: .85em;
    }
</style>