<template>
    <div>
    <b-button id="show-btn" variant="outline-primary" size="sm" @click="showEditContractDetailsModal"><font-awesome-icon icon="fa-regular fa-edit"></font-awesome-icon> Edit</b-button>

    <b-modal id="editContractDetailsModal" title="Edit Contract Details" size="xl" @ok="submitUpdate">
        <b-container fluid>
            <b-row>
                <b-col sm="3">
                    <b-form-group id="contractDate" description="Contract Date" >
                        <date-picker v-model="contract.dates.contractDate" :model-config="modelConfig" is-required>
                            <template v-slot="{ inputValue, togglePopover }">
                                <b-input-group>
                                <b-form-input :value="inputValue" trim autocomplete="off" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-primary" @click="togglePopover"><font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon></b-button>
                                </b-input-group-append>
                                </b-input-group>
                            </template>
                        </date-picker>
                        
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group id="depositDate" description="Deposit Date" >

                        <date-picker v-model="contract.dates.depositDate" :model-config="modelConfig">
                            <template v-slot="{ inputValue, togglePopover }">
                                <b-input-group>
                                <b-form-input :value="inputValue" trim autocomplete="off" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-primary" @click="togglePopover"><font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon></b-button>
                                    <b-button variant="outline-danger" @click="removeDate('depositDate')"><font-awesome-icon icon="fa-regular fa-calendar-times"></font-awesome-icon></b-button>
                                </b-input-group-append>
                                </b-input-group>
                            </template>
                        </date-picker>

                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group id="startDate" description="Start Date" >

                        <date-picker v-model="contract.dates.startDate" :model-config="modelConfig">
                            <template v-slot="{ inputValue, togglePopover }">
                                <b-input-group>
                                <b-form-input :value="inputValue" trim autocomplete="off" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-primary" @click="togglePopover"><font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon></b-button>
                                    <b-button variant="outline-danger" @click="removeDate('startDate')"><font-awesome-icon icon="fa-regular fa-calendar-times"></font-awesome-icon></b-button>
                                </b-input-group-append>
                                </b-input-group>
                            </template>
                        </date-picker>

                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group id="finishedDate" description="Finished Date" >

                        <date-picker v-model="contract.dates.finishedDate" :model-config="modelConfig" >
                            <template v-slot="{ inputValue, togglePopover }">
                                <b-input-group>
                                <b-form-input :value="inputValue" trim autocomplete="off" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-primary" @click="togglePopover"><font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon></b-button>
                                    <b-button variant="outline-danger" @click="removeDate('finishedDate')"><font-awesome-icon icon="fa-regular fa-calendar-times"></font-awesome-icon></b-button>
                                </b-input-group-append>
                                </b-input-group>
                            </template>
                        </date-picker>
        
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <b-form-group id="jobtype" description="Job Type" >
                        <b-form-select ref="jobType" v-model="contract.jobType" :options="jobTypeOptions" trim></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group id="salesman" description="Salesman" >
                        <b-form-select ref="salesman" v-model="contract.salesman.name" :options="salesmanOptions" trim></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group id="mechanic" description="Mechanic" >
                        <b-form-input v-model="contract.mechanic" trim autocomplete="off"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm>
                    <b-row>
                        <b-col sm="5">
                            <b-form-group id="street" description="Street" >
                                <b-form-input id="bstreet" v-model="contract.siteAddress.street" trim autocomplete="off" ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group id="city" description="City" >
                                <b-form-input v-model="contract.siteAddress.city" @blur="lookupZip()" trim ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" sm="2" >
                            <b-form-group id="state" description="State" >
                                <b-form-input v-model="contract.siteAddress.state" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="7" sm="2" >
                            <b-form-group id="zip" description="Zip Code" >
                                <b-form-input ref="zip" v-model="contract.siteAddress.zip" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="4" >
                            <b-form-group id="jobLabel" description="Job Label/Short Desc" >
                                <b-form-input ref="jobLabel" v-model="contract.jobLabel" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4" >
                            <b-form-group id="homeModel" description="Home Model" >
                                <b-form-input ref="homeModel" v-model="contract.homeModel" trim autocomplete="off"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
            
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h5>Scope Text:</h5>
                    <editor-scope :editing="true" :contractData="contract"></editor-scope>
            
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
    
    </div>
</template>

<script>
import "animate.css"
import { getUnixTime } from 'date-fns'
import { parse } from 'date-fns'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import "animate.css"
import editorScope from '../contractEditor-components/editorScope.vue'

export default {
  name: "editContractDetails",
  props: {
      contract: Object,
  },
  components: {
      DatePicker,
      editorScope
  },
  computed: {
      userInfo() {
          return this.$state.userInfo
      } 
  },
  data() {
    return {
        siteAddressState: null,
        salesmanOptions: [],
        salesmanIds: [],
        jobTypeOptions: [],
        modelConfig: {
            type: 'string',
            mask: 'MM/DD/YYYY'
        },
        location: null
    }
  },
  created() {
      this.getCompanyUsers()
      this.getJobTypes()
  },
  methods: {
    async getCompanyUsers() {

        try {
            let res = await this.$axios.get(`/user/`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            //console.log("Users:")
            //console.log(res.data)
            let salesmanOptions = []
            let salesmanIds = []
            
            res.data.forEach(user => {
                if(user.status.toLowerCase() === "active" ) {
                    salesmanOptions.push(user.name)
                    salesmanIds.push({ name: user.name, _id: user._id})
                }       
            })
            
            this.salesmanOptions = salesmanOptions
            this.salesmanIds = salesmanIds

        } catch (err) {
            console.error(err)
        } 
    },
    async getJobTypes() {
        let jobTypeOptions = [{text: "Choose...", value: null}]
        this.userInfo.company.jobTypes.forEach(type => {
            jobTypeOptions.push({ text: type, value: type })
        })
        this.jobTypeOptions = jobTypeOptions
    },
    async submitUpdate() {
        //build payload
        let payload = {

            siteAddress: {
                street: this.contract.siteAddress.street,
                city: this.contract.siteAddress.city,
                state: this.contract.siteAddress.state,
                zip: this.contract.siteAddress.zip
            },
            dates: {
                contractDate: getUnixTime(parse(this.contract.dates.contractDate, 'MM/dd/yyyy', new Date())),
            },
            jobType: this.contract.jobType,
            salesman: '',
            scopeText: this.contract.scopeText
        }

        //check if there is a mechanic and add to payload if they is
        if(this.contract.mechanic) {
            payload.mechanic = this.contract.mechanic
        }
        //check if there is a label and add to payload if they is
        if(this.contract.jobLabel) {
            payload.jobLabel = this.contract.jobLabel
        }
        //check if there is a home model and add to payload if they is
        if(this.contract.homeModel) {
            payload.homeModel = this.contract.homeModel
        }

        //check if any dates were added and add to payload if they were
        if(this.contract.dates.depositDate != 'n/a') {
            payload.dates.depositDate = getUnixTime(parse(this.contract.dates.depositDate, 'MM/dd/yyyy', new Date()))
        }
        if(this.contract.dates.finishedDate != 'n/a') {
            payload.dates.finishedDate = getUnixTime(parse(this.contract.dates.finishedDate, 'MM/dd/yyyy', new Date()))
        }
        if(this.contract.dates.startDate != 'n/a') {
            payload.dates.startDate = getUnixTime(parse(this.contract.dates.startDate, 'MM/dd/yyyy', new Date()))
        }

        //convert the salesman to just it's _id and add to payload
        let searchName = this.contract.salesman.name
        let matchedSalesman = this.salesmanIds.filter(obj => {
            return obj.name === searchName
        })
        payload.salesman = matchedSalesman[0]._id

        //check for a location
        if(this.location) {
            payload.location = this.location
        }

        console.table(payload)

        try {
            let res = await this.$axios.put(`/job/${this.contract._id}`, payload, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.log(res)

            //log it
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `Job ${this.contract._id} Info Updated.`,
                addInfo: res.config.data,
                job: this.contract._id,
                logUser: this.userInfo.name
            }
            this.$actions.log(newLog)

            this.$bvModal.hide('editContractDetailsModal')

        } catch (err) {
            console.error(err)
        }
        
    },
    showEditContractDetailsModal() {
        this.$bvModal.show('editContractDetailsModal')
    },
    removeDate(date) {
        this.contract.dates[date] = 'n/a'
    },
    async lookupZip() {
        //geoc
          let apiKey = "101d562b2155dee5b664021b1042d15e5b4bb01"
          let city = this.contract.siteAddress.city
          let street = this.contract.siteAddress.street
          let state = this.contract.siteAddress.state
          
          try {
                
                let res = await this.$axios.get(`https://api.geocod.io/v1.6/geocode?q=${encodeURI(`${street}, ${city}, ${state}`)}&country=us&api_key=${apiKey}`, {
                    headers: {}
                })

                //console.log(res.data)

                let zipCode = res.data.results[0].address_components.zip
                let location = {
                    lat: res.data.results[0].location.lat,
                    lon: res.data.results[0].location.lng
                } 

                //console.log(`The Zip code is: ${zipCode}`)
                //console.log(location)
            
                this.contract.siteAddress.zip = zipCode
                this.location = location
                this.$refs.zip.$el.classList.add('animate__animated', 'animate__tada')
            
                
            } catch (error) {
                console.error(error)
            }
          
    }
  }
}

</script>


<style lang="scss" scoped>

</style>