
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3' : 'mt-3']">
    <b-row>
      <b-col>
        <h2>Admin Settings</h2>
        <h3>User Settings</h3>

        <h3>Add new user</h3>
        <div class="mb-3">
          <b-form inline>
            <label class="sr-only" for="inline-form-input-name">Name</label>
            <b-form-input id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Jane Doe"
              v-model="newUser.name"></b-form-input>

            <label class="sr-only" for="inline-form-input-username">Email</label>
            <b-form-input id="inline-form-input-username" class="mb-2 mr-sm-2 mb-sm-0" placeholder="jdoe@email.com"
              v-model="newUser.email"></b-form-input>

            <label class="sr-only" for="inline-form-input-username">Temp Password</label>
            <b-form-input id="inline-form-input-username" class="mb-2 mr-sm-2 mb-sm-0" placeholder="temp password"
              v-model="newUser.tempPassword"></b-form-input>

            <b-button variant="primary" @click="addNewUser()">Add User</b-button>
          </b-form>
        </div>

        <h3>Existing Users</h3>
        <b-table striped hover :items="users" :fields="fields" selectable select-mode="single"
          @row-selected="userdetail"></b-table>



        <h3>Selected user</h3>
        Name: {{ selectedUser.name }}<br />
        Admin: {{ selectedUser.admin }}<br />
        Email: {{ selectedUser.email }}<br />
        <b-button variant="info" @click="resetPassword">Reset Passord</b-button> <b-button variant="danger" class="ml-2"
          @click="deleteUser">Delete User</b-button>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>


export default {
  name: "adminSettings",
  components: {

  },
  data() {
    return {
      users: [],
      fields: ["name", "status", "admin", "email"],
      selectedUser: {},
      newUser: {
        name: null,
        email: null,
        tempPassword: null
      }
    }
  },
  computed: {
    isMobile() {
      return this.$state.isMobile
    }
  },
  methods: {
    async getUsers() {
      try {
        const res = await this.$axios.get(`/user`, {
          headers: { Authorization: `Bearer ${this.$state.token}` }
        }
        )
        this.users = res.data

      } catch (err) {
        console.log(err)
      }
    },
    userdetail(user) {
      this.selectedUser = user[0]
    },
    async deleteUser() {
      const userId = this.selectedUser._id

      const delUser = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this user? (This cannot be undone!)`, {
        title: "Delete User",
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })


      console.log(`delete user value is: ${delUser}`)

      try {
        const res = await this.$axios.delete(`/user/${userId}`, {
          headers: { Authorization: `Bearer ${this.$state.token}` }
        })

        console.log(res.data)

      } catch (err) {
        console.log(err)
      }

    },

    async resetPassword() {

      const payload = {
        resetPassword: true
      }

      try {
        const res = await this.$axios.put(`/user/${this.selectedUser._id}`, payload, {
          headers: { Authorization: `Bearer ${this.$state.token}` }
        }
        )

        console.log(res.data)

      } catch (err) {
        console.log(err)
      }

    },
    async addNewUser() {
      let payload = {
        "name": this.newUser.name,
        "status": "active",
        "admin": false,
        "email": this.newUser.email,
        "password": this.newUser.tempPassword,
        "resetPassword": true
      }
      try {
        const res = await this.$axios.post(`/user`, payload, {
          headers: { Authorization: `Bearer ${this.$state.token}` }
        })

        console.log(res)

      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
    this.getUsers()
  }
}
</script>


<style lang="scss" scoped></style>
