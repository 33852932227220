<template>
  <div>
  <b-button id="show-btn" variant="outline-light" @click="showPaymentsModal"><font-awesome-icon icon="fa-regular fa-credit-card" /> Get Payment Link</b-button>

  <b-modal id="paymentsModal" title="Create a Credit Card Payment Link" size="lg" @ok="getPaymentLink" 
      ok-title="Create Link" 
      ok-variant="info" 
      header-bg-variant="info"
      header-text-variant="">
        
      <b-form-group label="Amount To Charge:">
        <b-form-input id="chargeAmount" v-model="chargeAmount" trim></b-form-input>
      </b-form-group>
      <b-form-group label="Is this the deposit or final payment?">
        <b-form-radio-group id="depositRadio" v-model="deposit" :options="[{text: 'Deposit', value: true}, {text: 'Final', value: false}, {text: 'Other', value: 'other'}]"
          @change="setProductName" name="depositButtons" buttons></b-form-radio-group>
      </b-form-group>
      <b-form-group label="Charge Title:">
        <b-form-input id="productName" ref="prodNameTxt" v-model="productName" trim :disabled="prodNameDisabled"></b-form-input>
      </b-form-group>
      <b-form-group label="Description:">
        <b-form-input id="productDesc" ref="prodDescTxt" v-model="productDesc" trim ></b-form-input>
      </b-form-group>
      <b-form-group label="Include 3% Fee?">
        <b-form-radio-group id="feeRadio" v-model="chargeCCfee" :options="[{text: 'Yes', value: true}, {text: 'No', value: false} ]"
          name="depositButtons" buttons></b-form-radio-group>
      </b-form-group>
      <b-form-group label="Credit Card Fee:">
        <b-form-input id="ccFee" v-model="ccFee" Disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Payment Link:" v-show="showPaymentLink">
        <b-form-input id="paymentLnk" ref="paymentLnk" v-model="paymentLink" trim ></b-form-input>
      </b-form-group>
        
  </b-modal>
  
  </div>
</template>

<script>
import numbro from "numbro"
import { getUnixTime } from "date-fns"

export default {
  name: "ccPaymentsModal",
  props: {
    contractNumber: Number,
    price: Object,
    customer: Object,
    /*
    customer: {
          customerId: '6365135fd235c42ad146fdcb',
          name: 'Korey Stanley',
          email: 'korey@twininc.com',
          stripeId: 'cus_NP8ykmF6Z5XZdF'
      }
    */
   job: String

  },
  data() {
    return {
      paymentLink: "",
      showPaymentLink: false,
      console: "Return values here...",
      chargeAmount: 0,
      chargeCCfee: true,
      deposit: true,
      numbro,
      productName: "",
      prodNameDisabled: true,
      productDesc: ""
    }
  },
  computed: {
      isMobile() {
        return this.$state.isMobile
      },
      defaultProductName() {
        if(this.deposit){
          return `Deposit on contract# ${this.contractNumber}`
        } else {
          return `Final payment on contract# ${this.contractNumber}`
        }
      },
      productPrice() {
        return this.chargeAmount*100
      },
      ccFee() {
        if(this.chargeCCfee){
          let threePercent = Number(this.chargeAmount*0.03).toFixed(2)
          return threePercent
        } else {
          return 0
        } 
      },
      paymentLinkTotal() {
        let total = 0
        if(this.chargeCCfee){
          total = numbro(this.chargeAmount).add(this.ccFee)
        } else {
          total = numbro(this.chargeAmount)
        }
        return total
      },
      /*
      productDesc() {
        if(this.chargeCCfee){
          return `Original amount of $${Number(this.chargeAmount).toFixed(2)} with 3% card fee of $${this.ccFee}`
        } else {
          return ""
        }
      }
      */
  },
  created() {
    this.productName = this.defaultProductName
    
  },
  methods: {
    showPaymentsModal(){
      this.chargeAmount = this.price.value()
      this.productDesc = `Original amount of $${Number(this.chargeAmount).toFixed(2)} with 3% card fee of $${this.ccFee}`
      this.$bvModal.show('paymentsModal')
    },
    setProductName(val) {
      if(val === 'other'){
        this.prodNameDisabled = false
        setTimeout(() => {
          this.$refs.prodNameTxt.focus()
          this.$refs.prodNameTxt.select()
        }, 100)
        
      } else if(val === true) {
        this.productName = `Deposit on contract# ${this.contractNumber}`
      } else if(val === false) {
        this.productName = `Final payment on contract# ${this.contractNumber}`
      }
    },
    async getPaymentLink() {

      let payload = {
        productName: this.productName,
        productPrice: Math.ceil(this.productPrice),
        productDesc: this.productDesc,
        chargeCCfee: this.chargeCCfee,
        ccFee: Math.ceil(this.ccFee*100),
        customer: {
          customerId: this.customer._id,
        },
        job: this.job,
        deposit: this.deposit,
      }

      /*
      if(this.customer.stripeId){
        payload.customer.stripeId = this.customer.stripeId
      }
      */

      console.log(`Sending Payment Link Details...`)
      console.log(payload)

      try {
        const res = await this.$axios.post(`/payment/paymentLink`, payload, {
            headers: { Authorization: `Bearer ${this.$state.token}` }
        })
        console.log("Awaiting paymentLink post responce")
        console.log(res.data)
        
        if(this.chargeCCfee){ //if we are adding a credit card fee add it to the twin invoice
          let moneyItem = {
            job: this.job,
            description: `3% Credit Card Fee on ${ this.deposit ? 'deposit' : 'balance' }.`,
            amount: this.ccFee,
            debit: 'debit',
            sales: {roofing: 0, siding: 0, windows: 0, gutters: 0, tax: 0, permits: 0, creditCard: this.ccFee},
            transTime: getUnixTime(new Date())
          }

          const moneyRes = await this.$axios.post(`/money`, moneyItem, {
            headers: { Authorization: `Bearer ${this.$state.token}` }
          })
          console.log("Posting 3% fee to money route")
          console.log(moneyRes)

          this.$emit("feeAdded", moneyItem)
        }
        
        //add link to ccPaymentLinks
        this.$emit("paymentLinkAdded", {amount: this.paymentLinkTotal*100, createdDate: getUnixTime(new Date()), url: res.data.url})

        //notify user
        this.$root.$bvToast.toast('Successfully created a payment link.', {
          title: 'Success',
          variant: 'success',
          solid: true
        })

      } catch (err) {
          console.error(err)
          this.$root.$bvToast.toast('Something went wrong. :-(', {
          title: 'Woops',
          variant: 'warning',
          solid: true
        })
      }

      
    }

  }
}
</script>


<style lang="scss" scoped>

</style>
