<template>
    <div>
    <b-badge id="newTixBtn" variant="primary" @click="showNewTixModal()"><font-awesome-icon icon="plus" scale=".7" class="newTixBtn" /> Ticket</b-badge>

    <b-modal id="newTixModal" title="Add New Service Tix" size="xl" @ok="addTix()">
        <b-container fluid>
            
            <b-card header="Ticket Info" class="mb-3 mt-3" border-variant="primary" header-bg-variant="primary" header-text-variant="white">
                <b-row>
                    <b-col>
                        <b-form-group description="Service Description" >
                            <b-form-textarea id="serviceDesc" v-model="tix.serviceDesc" trim autocomplete="off" ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="6">
                        <b-form-group description="Service Assigned To">
                            <b-form-input id="serviceMan" v-model="tix.serviceMan" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <b-form-group description="Service Catagory" class="radioBtn">
                            <b-form-radio-group
                                button-variant="outline-primary"
                                id="serviceCat"
                                v-model="tix.serviceCat"
                                :options="[{text: 'Warranty', value: 'Warranty'}, {text: 'Punchlist', value: 'Punchlist'}]"
                                buttons
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

        </b-container>
    </b-modal>
    
    </div>
</template>

<script>
import { getUnixTime } from 'date-fns'

export default {
  name: "newServiceTixFrm",
  props: {
      jobId: String,
      customerId: String
  },
  components: {
     
  },
  computed: {
      userInfo() {
          return this.$state.userInfo
      } 
  },
  data() {
    return {
        tix: {
            job: this.jobId,
            customer: this.customerId,
            dates: {
                addedDate: null,
                finishedDate: null
            },
            serviceDesc: "",
            serviceMan: "",
            serviceCat: "Warranty",
            status: "pending"
        }
    }
  },
  created() {

  },
  methods: {
    showNewTixModal() {
        this.$bvModal.show('newTixModal')
    },
    async addTix() {

        let payload = this.tix
        payload.dates.addedDate = getUnixTime(new Date())

        try {
            let res = await this.$axios.post(`/service`, payload, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.log(res)

            //log it
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `New Service Ticket created for job: ${payload.job}`,
                addInfo: res.data,
                job: payload.job,
                logUser: this.userInfo.name
            }

            this.$actions.log(newLog)
            
            this.$emit("new-tix-added", payload)


        } catch (err) {
            console.error(err)
        }




        this.$bvModal.hide('newTixModal')
    }
  }
}

</script>


<style lang="scss" scoped>
    .badge:hover {
        cursor: pointer;
    }
    #serviceDesc {
        min-height: 175px;
    }
</style>