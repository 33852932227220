<template>
    <div>
        <b-card border-variant="success" header-border-variant="success" header-bg-variant="success" header-tag="header"
            header-text-variant="light" class="mt-3">
            <template #header>
                <div class="d-flex flex-row justify-content-between pt-0 pb-0 mt-0 mb-0">
                    <h5 class="mb-0 mt-2 monoTitle">Money</h5>
                    <cc-payments :contract-number="contract.contractId" :price="balance" :customer="contract.customer"
                        :job="jobId" @paymentLinkAdded="ccLinkAdded" @feeAdded="ccFeeAdded"></cc-payments>
                </div>
            </template>

            <div v-if="contract.ccPaymentLinks.length > 0" class="monoTitle"><b>Credit Card Payment Links:</b></div>

            <b-list-group v-if="contract.ccPaymentLinks.length > 0" class="mb-3 mt-1">

                <b-list-group-item class="ccRow ccHeaderRow monoTitle" variant="success">
                    <b-row>
                        <b-col cols="6" md="2">Date</b-col>
                        <b-col cols="6" md="2">Amount</b-col>
                        <b-col md="5">Link</b-col>
                        <b-col md="3"></b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item v-for="item in contract.ccPaymentLinks" :key="item.createdDate" class="ccRow">
                    <b-row>
                        <b-col cols="6" md="2">{{ format(fromUnixTime(item.createdDate), 'MM/dd/yyyy') }}</b-col>
                        <b-col cols="6" md="2">${{ item.amount / 100 }}</b-col>
                        <b-col md="5">
                            {{ item.url }}
                        </b-col>
                        <b-col md="3" class="text-right">
                            <b-button variant="link" @click="copyLink(item.url)" class="pt-0 pb-0"><font-awesome-icon
                                    :icon="['far', 'copy']" scale="1"></font-awesome-icon>
                                <small>Copy</small></b-button>
                            <b-button variant="success" @click="openSendMsgDialog(item.url)" class="pt-0 pb-0"><font-awesome-icon
                                    :icon="['fas', 'comment']" scale="1"></font-awesome-icon> <small>Send
                                    Text</small></b-button>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>

            <b-button-group size="sm" class="mb-3">
                <b-button v-if="contract.sold" variant="outline-success" @click="unSellJob()"><font-awesome-icon
                        icon="fa-regular fa-file-excel" scale=".8"></font-awesome-icon> Unsell Job</b-button>
                <b-button v-else variant="success" @click="$bvModal.show('sellJobModal')"><font-awesome-icon
                        icon="file-invoice-dollar" scale=".8"></font-awesome-icon> Sell Job</b-button>
                <b-button v-if="contract.sold" variant="outline-success"
                    @click="$bvModal.show('addPaymentModal')"><font-awesome-icon icon="dollar-sign"
                        scale=".8"></font-awesome-icon>
                    Add Payment</b-button>
                <b-button v-if="contract.sold" variant="outline-success"
                    @click="$bvModal.show('addExtraModal')"><font-awesome-icon icon="plus"
                        scale=".8"></font-awesome-icon> Add Extras</b-button>
                <b-button variant="outline-success" @click="showInvoice"><font-awesome-icon icon="share"
                        scale=".8"></font-awesome-icon> View Invoice</b-button>
            </b-button-group>

            <!-- Money Line Items -->
            <b-list-group>

                <b-list-group-item v-for="lineItem in money" :key="lineItem._id" class="d-flex">
                    <span class="flex-grow-1">
                        <!-- Contract Price -->
                        <div v-if="lineItem.description == 'Contract Price'">
                            <div v-if="lineItem.custDesc">
                                <b>{{ lineItem.custDesc }}</b>
                                <font-awesome-icon icon="fa-regular fa-edit" scale=".8"
                                    class="text-primary hover-hand ml-1"
                                    @click="editMoneyItem(lineItem._id)"></font-awesome-icon>
                            </div>
                            <div v-else>
                                <b>{{ lineItem.description }}</b>
                                <font-awesome-icon icon="fa-regular fa-edit" scale=".8"
                                    class="text-primary hover-hand ml-1"
                                    @click="editMoneyItem(lineItem._id)"></font-awesome-icon>
                            </div>
                        </div>
                        <!-- End Contract Price -->

                        <!-- Other Items-->
                        <div v-else>
                            <div v-if="lineItem.debit.toLowerCase() == 'credit'" class="d-inline">
                                <div v-if="lineItem.description != 'Deposit'" class="d-inline">
                                    Payment: {{ lineItem.description }}
                                </div>
                                <div v-else class="d-inline">
                                    {{ lineItem.description }}
                                </div>
                            </div>
                            <div v-else class="d-inline">
                                {{ lineItem.description }}
                            </div>
                            <font-awesome-icon icon="fa-regular fa-trash-alt" scale=".8" class="text-danger hover-hand"
                                @click="delMoneyItem(lineItem._id)"></font-awesome-icon>
                            <font-awesome-icon icon="fa-regular fa-edit" scale=".8" class="text-primary hover-hand ml-1"
                                @click="editMoneyItem(lineItem._id)"></font-awesome-icon>
                        </div>
                        <!-- end Other Items-->

                        <div v-if="lineItem.note">
                            <small>{{ lineItem.note }}</small>
                        </div>
                        <div v-if="lineItem.privateNote" class="lightTxt">
                            {{ lineItem.privateNote }}
                        </div>
                    </span>
                    <span :class="lineItem.transType" class="text-right">
                        <div v-if="lineItem.debit.toLowerCase() == 'credit'">
                            <span class="text-info">${{ numbro(lineItem.amount).format() }}</span>
                        </div>
                        <div v-else>
                            ${{ numbro(lineItem.amount).format() }}
                            <div class="lightTxt">
                                <div class="d-inline" v-if="lineItem.sales.roofing > 0">
                                    Roof:{{ numbro(lineItem.sales.roofing).format() }}</div>
                                <div class="d-inline" v-if="lineItem.sales.siding > 0">
                                    Sd:{{ numbro(lineItem.sales.siding).format() }}
                                </div>
                                <div class="d-inline" v-if="lineItem.sales.gutters > 0">
                                    Gut:{{ numbro(lineItem.sales.gutters).format() }}</div>
                                <div class="d-inline" v-if="lineItem.sales.windows > 0">
                                    Win:{{ numbro(lineItem.sales.windows).format() }}</div>
                                <div class="d-inline" v-if="lineItem.sales.creditCard > 0">
                                    Credit:{{ numbro(lineItem.sales.creditCard).format() }}</div>
                                <div class="d-inline" v-if="lineItem.sales.tax > 0">
                                    Tax:{{ numbro(lineItem.sales.tax).format() }}</div>
                            </div>
                        </div>

                    </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex" style="border-top: 1px double black;">
                    <span class="flex-grow-1 text-right">
                        <h6>Sub Total</h6>
                    </span>
                    <h6 class="text-right money-totals">${{ numbro(subTotal).format() }}</h6>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <h6>Tax</h6>
                    </span>
                    <h6 class="text-right money-totals">${{ numbro(tax).format() }}</h6>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <h3>Total</h3>
                    </span>
                    <span class="text-right money-totals">
                        <h3>${{ numbro(total).format() }}</h3>
                    </span>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <span>Total of payments recieved</span>
                    </span>
                    <span class="text-right money-totals">${{ numbro(totalPayments).format() }}</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex" style="border-top: 1px double black;">

                    <span class="flex-grow-1 text-right">
                        <h3>Balance</h3>
                    </span>
                    <span class="text-right money-totals">
                        <h3>${{ numbro(balance).format() }}</h3>
                    </span>
                </b-list-group-item>
                <b-list-group-item class="d-flex" style="">
                    <b-form-checkbox switch class="ml-2" size="sm" v-model="contract.paidInFull" :value="true"
                        :unchecked-value="false" @input="setPaidInFull">
                        <span class="">Paid in Full</span>
                    </b-form-checkbox>
                </b-list-group-item>
                <b-list-group-item class="d-flex" v-if="!contract.sold">
                    <div class="btn btn-sm btn-success" @click="showPreSoldNumbersModal('deposit')">
                        Deposit set to: ${{ numbro(contract.deposit).format() }}
                        <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                    </div>
                    <div class="ml-2 btn btn-sm btn-success" @click="showPreSoldNumbersModal('progressPayment')">
                        Progress payment set to: ${{ numbro(contract.progressPayment).format() }}
                        <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                    </div>
                </b-list-group-item>

            </b-list-group>
            <!-- End Money Line Items -->
        </b-card>

        <!-- Pre Sold Numbers Modal -->
        <b-modal id="preSoldNumbersModal" title="Set Payment Details" header-bg-variant="success"
            header-text-variant="white" ok-title="Update" @ok="editPreSoldNumbers">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <div class="badge badge-danger" v-if="preSoldErrorTxt">{{ preSoldErrorTxt }}</div>
                        <b-form-group label="Deposit Amount">
                            <b-input-group prepend="$">
                                <b-form-input type="number" v-model="preSoldDeposit" placeholder="0.00"></b-form-input>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group label="Progress Payment Amount">
                            <b-input-group prepend="$">
                                <b-form-input type="number" v-model="preSoldProgressPayment" placeholder="0.00"
                                    :disabled="!preSoldProgressSwitch"></b-form-input>
                                <b-input-group-append is-text>
                                    <b-form-checkbox switch class="mr-n2" v-model="preSoldProgressSwitch" :value="true"
                                        :unchecked-value="false">
                                        <span class="sr-only">Switch to enable progress payment</span>
                                    </b-form-checkbox>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                    </b-col>
                </b-row>
            </b-container>
        </b-modal> <!-- End Pre Sold Numbers Modal -->

        <!-- Sell Job Modal -->
        <b-modal id="sellJobModal" title="Set contract status to 'SOLD'" header-bg-variant="primary"
            header-text-variant="white" ok-title="Sell Job" @ok="sellJob">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <div class="badge badge-danger" v-if="sellJobErrorTxt">{{ sellJobErrorTxt }}</div>
                        <b-form-group label="Deposit Amount" label-for="depositAmtTxt">
                            <b-input-group prepend="$">
                                <b-form-input id="depositAmtTxt" type="number" v-model="deposit" placeholder="0.00"
                                    :state="depositState"></b-form-input>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group label="Note" label-for="depositNoteTxt">
                            <b-form-input id="depositNoteTxt" v-model="depositNote"
                                placeholder="e.g. ch# 2187 on 5-25-77" :state="depositNoteState"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal> <!-- End sellJobModal -->

        <!-- Add Payment Modal -->
        <b-modal id="addPaymentModal" title="Add a payment to this job" header-bg-variant="primary"
            header-text-variant="white" ok-title="Add Payment" @ok="addPayment">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <div class="text-muted font-weight-light">Current Invoice Balance: ${{ numbro(balance).format() }}
                        </div>
                        <div class="badge badge-danger" v-if="paymentErrorTxt">{{ paymentErrorTxt }}</div>
                        <b-form-group label="Payment Amount" label-for="paymentAmtTxt">
                            <b-input-group prepend="$">
                                <b-form-input id="paymentAmtTxt" type="number" v-model="paymentAmt" placeholder="0.00"
                                    :state="paymentAmtState"></b-form-input>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group label="Description" label-for="paymentDescTxt">
                            <b-form-input id="paymentDescTxt" v-model="paymentDesc"
                                placeholder="e.g. Rec ch# 2187 on 5-25-77" :state="paymentDescState"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Notes (optional)" label-for="paymentNoteTxt">
                            <b-form-input id="paymentNoteTxt" v-model="paymentNote"></b-form-input>
                        </b-form-group>

                        <div class="badge badge-info" v-if="paidInFull">This payment will pay this Invoice in full.
                        </div>

                    </b-col>
                </b-row>
            </b-container>
        </b-modal> <!-- End addPaymentModal -->

        <!-- Edit Money Modal -->
        <b-modal id="editMoneyItemModal" title="Edit" header-bg-variant="primary" header-text-variant="white"
            ok-title="Update" @ok="submitEditMoneyItem">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <div class="badge badge-danger" v-if="editMoneyErrorTxt">{{ editMoneyErrorTxt }}</div>
                        <b-form-group label="Description" label-for="descriptionTxt">
                            <b-form-input id="descriptionTxt" v-model="moneyEditItem.description"
                                :state="editMoneyDescState"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Notes (Optional)" label-for="noteTxt">
                            <b-form-input id="noteTxt" v-model="moneyEditItem.note"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Private Note (Optional, Will not print on customer invoice)"
                            label-for="noteTxt">
                            <b-form-input id="noteTxt" v-model="moneyEditItem.privateNote"></b-form-input>
                        </b-form-group>

                        <div v-if="moneyEditItem.debit.toLowerCase() == 'credit'">
                            <b-form-group label="Amount" label-for="editAmtTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="editAmtTxt" type="number" v-model.number="moneyEditItem.amount"
                                        :state="editMoneyAmtState"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </div>

                        <div v-else>
                            <b-form-group label="Sales" description="Roofing" label-for="roofingTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="roofingTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.roofing"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group description="Siding" label-for="sidingTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="sidingTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.siding"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group description="Windows" label-for="windowsTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="windowsTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.windows"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group description="Gutters" label-for="guttersTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="guttersTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.gutters"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group description="Credit Card Fees" label-for="creditCardTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="creditCardTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.creditCard"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group description="Permits" label-for="permitsTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="permitsTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.permits"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group :description="`Sales Tax Rate ${this.company.salesTaxRate}%`"
                                label-for="taxTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="taxTxt" type="number" placeholder="0"
                                        v-model.number="moneyEditItem.sales.tax"
                                        @change="addUpSales('moneyEditItem')"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="calcTax('moneyEditItem')">Calc Sales
                                            Tax</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Amount" label-for="editAmtTxt">
                                <b-input-group prepend="$">
                                    <b-form-input id="editAmtTxt" type="number" v-model.number="moneyEditItem.amount"
                                        disabled :state="editMoneyAmtState"></b-form-input>
                                </b-input-group>
                            </b-form-group>

                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal> <!-- End editMoneyModal -->

        <!-- Add Extra Modal -->
        <b-modal id="addExtraModal" title="Add Additional Charge" header-bg-variant="success"
            header-text-variant="white" ok-title="Add Extra" @ok="submitExtra">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <div class="badge badge-danger" v-if="addExtraErrorTxt">{{ addExtraErrorTxt }}</div>
                        <b-form-group label="Description" label-for="extraDescriptionTxt">
                            <b-form-input id="extraDescriptionTxt" v-model="addExtra.description"
                                :state="addExtraDescState"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Notes (optional)" label-for="extraNoteTxt">
                            <b-form-input id="extraNoteTxt" v-model="addExtra.note"></b-form-input>
                        </b-form-group>

                        <div>

                            <b-input-group prepend="Roofing $" class="mb-2">
                                <b-form-input class="rightJustified" id="exroofingTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.roofing"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>


                            <b-input-group prepend="Siding $" class="mb-2">
                                <b-form-input class="rightJustified" id="exsidingTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.siding"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>


                            <b-input-group prepend="Window $" class="mb-2">
                                <b-form-input class="rightJustified" id="exwindowsTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.windows"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>


                            <b-input-group prepend="Gutters $" class="mb-2">
                                <b-form-input class="rightJustified" id="exguttersTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.gutters"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>

                            <b-input-group prepend="CreditCard Fees $" class="mb-2">
                                <b-form-input class="rightJustified" id="exCreditCardTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.creditCard"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>

                            <b-input-group prepend="Permits $" class="mb-2">
                                <b-form-input class="rightJustified" id="expermitsTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.permits"
                                    @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>


                            <b-input-group prepend="Sales Tax $" class="mb-2">
                                <b-input-group-prepend>
                                    <b-button variant="outline-primary" @click="calcTax('addExtra')">Calc Sales
                                        Tax</b-button>
                                </b-input-group-prepend>
                                <b-form-input class="rightJustified" id="extaxTxt" type="number" placeholder="0"
                                    v-model.number="addExtra.sales.tax" @change="addUpSales('addExtra')"></b-form-input>
                            </b-input-group>


                            <b-input-group prepend="Total Sale $">
                                <b-form-input class="rightJustified" id="exeditAmtTxt" type="number"
                                    v-model.number="addExtra.amount" disabled :state="addExtraAmtState"></b-form-input>
                            </b-input-group>


                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal> <!-- End addExtraModal -->

        <!-- Start Text Message Modal-->
        <b-modal id="sendTxtModal" title="Confirm Payment Message Text" size="md" @ok.prevent="sendTxtMsg" ok-title="Send Payment Link"
            ok-variant="primary" header-bg-variant="primary" header-text-variant="white">

            <b-form-group label="From:">
                <b-form-select id="fromNumberSelect" v-model="txtMsg.fromNumber" :options="txtMsg.fromNumberOpts"></b-form-select>
            </b-form-group>
            <b-form-group label="To:">
                <b-form-input id="toNumberTxt" v-model="txtMsg.toNumber" trim :state="txtMsg.toNumberErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNumberErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Name:">
                <b-form-input id="nameTxt" v-model="txtMsg.toName" trim :state="txtMsg.toNameErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNameErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Message text:">
                <b-form-textarea id="msgTxt" v-model="txtMsg.msgTxt" trim rows="2" max-rows="2" :state="txtMsg.msgTxtErrState"></b-form-textarea>
                <!--<b-form-input id="msgTxt" v-model="txtMsg.msgText" trim :state="txtMsg.msgTextErrState"></b-form-input>-->
                <b-form-invalid-feedback id="msgTxt-invalid-feedback">{{ txtMsg.msgTxtErrTxt}}</b-form-invalid-feedback>
                <b-form-text id="msgTxt-help">Character Count: {{ msgCount }}/160</b-form-text>
            </b-form-group>
            Message Preview:
            <p class="txtMsg send">{{ txtMsgPreview }}</p>

        </b-modal>

    </div>
</template>

<script>
import numbro from "numbro"
import { getUnixTime, fromUnixTime, format } from "date-fns"
import { parse } from 'date-fns'
import ccPayments from '../ccPayments.vue'

export default {
    name: 'jp-money',
    props: {
        jobId: String,
        contract: Object
    },
    components: {
        ccPayments
    },
    data() {
        return {
            txtMsg: {
                fromNumber: "732-240-3780",
                fromNumberOpts: [
                    { value: "732-240-3780", text: "732-240-3780 (Twin Main)" },
                    { value: "732-240-3788", text: "732-240-3788 (Korey)" },
                    { value: "732-240-3792", text: "732-240-3792 (Kolton)" },
                ],
                toNumber: null,
                toNumberErrState: null,
                toNumberErrTxt: "",
                toName: "",
                toNameErrState: null,
                toNameErrTxt: "",
                msgTxt: "Please click on the following link to make your payment.",
                msgTxtErrState: null,
                msgTxtErrTxt: "Message Text can be 160 characters max.",
                msgTxtDisabled: false,
                paymentLink: ""
            },
            fromUnixTime,
            format,
            money: [],
            //subTotal: 0,
            //tax: 0,
            //totalPayments: 0,
            numbro: numbro,
            deposit: null,
            depositNote: null,
            sellJobErrorTxt: null,
            depositState: null,
            depositNoteState: null,
            paymentDesc: null,
            paymentDescState: null,
            paymentAmt: null,
            paymentAmtState: null,
            paymentNote: null,
            paymentErrorTxt: null,
            moneyEditItem: {
                description: "",
                debit: "",
                amount: null,
                note: "",
                privateNote: "",
                sales: {
                    roofing: null,
                    siding: null,
                    windows: null,
                    gutters: null,
                    tax: null,
                    creditCard: null,
                    permits: null
                }
            },
            editMoneyErrorTxt: null,
            editMoneyAmtState: null,
            editMoneyDescState: null,
            addExtraErrorTxt: null,
            addExtraDescState: null,
            addExtraAmtState: null,
            preSoldErrorTxt: null,
            preSoldDeposit: null,
            preSoldProgressPayment: null,
            preSoldProgressSwitch: false,
            addExtra: {
                description: "",
                debit: "debit",
                amount: 0,
                note: "",
                sales: {
                    roofing: null,
                    siding: null,
                    windows: null,
                    gutters: null,
                    tax: null,
                    creditCard: null,
                    permits: null
                }
            },

        }
    },
    computed: {
        subTotal() {
            let sub = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'debit') {
                    if (trans.sales.tax) {
                        sub = numbro(sub).add(numbro(trans.amount).subtract(trans.sales.tax))
                    } else {
                        sub = numbro(sub).add(trans.amount)
                    }
                }
            })
            return sub
        },
        tax() {
            let tax = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'debit') {
                    if (trans.sales.tax) {
                        tax = numbro(tax).add(trans.sales.tax)
                    }
                }
            })
            return tax
        },
        total() {
            let newTotal = numbro(this.subTotal).add(this.tax)
            this.$actions.updateTotal(newTotal)
            return newTotal
        },
        totalPayments() {
            let payments = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'credit') {
                    payments = numbro(payments).add(trans.amount)
                }
            })

            return payments
        },
        balance() {
            return numbro(this.total).subtract(this.totalPayments)
        },
        userInfo() {
            return this.$state.userInfo
        },
        paidInFull() {
            if (this.balance - this.paymentAmt == 0) {
                return true
            } else {
                return false
            }
        },
        company() {
            return this.$state.userInfo.company
        },
        txtMsgPreview() {
            return `${this.txtMsg.toName}, ${this.txtMsg.msgTxt}\n${this.txtMsg.paymentLink} Thanks --Twin`
        },
        msgCount() {
            const msgLen = this.txtMsgPreview.length
            return msgLen+1
        }
    },
    methods: {
        async getMoney() {
            try {
                let res = await this.$axios.get(`/money/job/${this.jobId}`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                this.money = res.data

            } catch (err) {
                console.error(err)
            }
        }, // --End of getMoney method
        showPreSoldNumbersModal() {
            this.preSoldDeposit = this.contract.deposit

            if (this.contract.progressPayment) {
                this.preSoldProgressSwitch = true
                this.preSoldProgressPayment = this.contract.progressPayment
            } else {
                this.presoldProgressPayment = 0
            }

            this.$bvModal.show('preSoldNumbersModal')
        },
        openSendMsgDialog(url) {
            
            this.txtMsg.paymentLink = url
            this.txtMsg.toName = this.contract.customer.fname
            this.txtMsg.toNumber = this.contract.customer.phones.mobile

            this.$bvModal.show('sendTxtModal')            
        },
        async sendTxtMsg(){
            
            //do some checks
            const regex = /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/
            if (!regex.test(this.txtMsg.toNumber)) {
                this.txtMsg.toNumberErrTxt = "The 'To:' fieldmust be a properly formatted phone number and can only contain 0-9 and the '-' character. i.e. 732-240-3780"
                this.txtMsg.toNumberErrState = false
                return
            } else {
                this.txtMsg.toNumberErrState = true
            }

            //toName cant be blank
            if (this.txtMsg.toName.trim() == "") {
                this.txtMsg.toNameErrTxt = "The 'Name:' field can not be blank!"
                this.txtMsg.toNameErrState = false
                return
            } else {
                this.txtMsg.toNameErrState = true
            }

            //msgTxt cant be blank
            if (this.txtMsg.msgTxt.trim() == "") {
                this.txtMsg.msgTxtErrTxt = "The 'Message Text:' field can not be blank!"
                this.txtMsg.msgTxtErrState = false
                return
            } else {
                this.txtMsg.toNameErrState = true
            }

            //full message text cnt be more then 160 Char
            const text = `${this.txtMsg.toName},\n${this.txtMsg.msgTxt}\n${this.txtMsg.paymentLink} \nThanks --Twin`

            if (text.length > 160) {
                this.txtMsg.msgTxtErrTxt = "The 'Message Text:' can not exceed 160 characters."
                this.txtMsg.msgTxtErrState = false
                return
            } else {
                this.txtMsg.toNameErrState = true
            }

            const toNumber = this.txtMsg.toNumber.replace(/-/g, "")
            const fromNumber = this.txtMsg.fromNumber.replace(/-/g, "")

            const payload = {
                from_Number : fromNumber,
                to_Number : toNumber,
                text : text
            }
            console.log(payload)
            console.log("Sending text message...")

            try {
                const res = await this.$axios.post('/dialpad/', payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(`Response recieved. Txt status is: ${res.status.toString()}`)

                if (res.status === 200) {

                    //notify user of the success
                    this.$bvToast.toast('👍🏻 Text message was sent.', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    })

                    //log the text message
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Payment link text messaage was sent.`,
                        addInfo: `The message was sent to ${this.txtMsg.toNumber} from ${this.txtMsg.fromNumber}. The message text was: ${payload.text}`,
                        job: this.jobId,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)


                } else {
                    console.error(res)
                    this.$bvToast.toast(`Woops. Error Status text is: ${res.errorText}  👎🏻`, {
                        title: 'Oh no!',
                        variant: 'danger',
                        solid: true
                    })
                }

                this.$bvModal.hide('sendTxtModal')

            } catch (error) {
                console.log(error)
                this.$bvToast.toast('Something went wrong. Check the console for errors. 👎🏻', {
                    title: 'Oh no!',
                    variant: 'danger',
                    solid: true
                })

                this.$bvModal.hide('sendTxtModal')

            }

        },
        async editPreSoldNumbers() {

            let payload = {
                id: this.contract._id,
                deposit: Number(this.preSoldDeposit),
                progressPayment: Number(this.preSoldProgressPayment)
            }

            console.log("updating deposit and progresspayment:")
            console.table(payload)

            try {
                let res = await this.$axios.put(`/job/${this.jobId}`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(res.data)

                //update the displayed values
                this.contract.deposit = Number(payload.deposit)
                this.contract.progressPayment = Number(payload.progressPayment)

                this.$bvToast.toast('👍🏻 Deposit and Progress Payment numbers were updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })

            } catch (err) {
                console.log(err)
                this.$bvToast.toast('Something went wrong. Numbers were not updated. 👎🏻', {
                    title: 'Woops',
                    variant: 'warning',
                    solid: true
                })
            }

            this.$bvModal.hide('preSoldNumbersModal')

        },
        async addPayment(bvModalEvt) {

            //if validation fails we dont want the modal to disappear... Means we have to manually hide the modal later...
            bvModalEvt.preventDefault()

            //make sure fields aren't blank
            if (!this.paymentAmt) {
                this.paymentErrorTxt = "The 'Payment' can not be left blank!"
                this.paymentAmtState = false
                return
            }

            if (!this.paymentDesc) {
                this.paymentErrorTxt = "The 'Description' field can not be left blank!"
                this.paymentDescState = false
                return
            }

            //was job paidinFull?? If so update contract to reflect
            const paidInFullValue = this.paidInFull ? true : false
            console.log(`paidInFullValue is: ${paidInFullValue}`)
            console.log(`Calling 'setPaidInFull'`)
            this.setPaidInFull(paidInFullValue)

            let payment = {
                job: this.jobId,
                description: this.paymentDesc,
                amount: numbro.unformat(this.paymentAmt),
                debit: "Credit",
                note: this.paymentNote,
                sales: {
                    tax: null,
                    roofing: null,
                    siding: null,
                    windows: null,
                    gutters: null,
                    creditCard: null,
                    permits: null
                },
                transTime: getUnixTime(new Date()),
            }



            try {
                let res = await this.$axios.post(`/money`, payment, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                let resId = res.data
                payment._id = resId

                //update the current jopprofile view
                //this.totalPayments = numbro(this.totalPayments).add(payment.amount)
                this.money.push(payment)


                console.log(`payment success. New moneyid: ${resId}`)

                //log it
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `New Payment was added successfully.`,
                    addInfo: `New payment of ${payment.amount} was add to Job. Addtional Info: Desc: ${payment.description}, Payment Note: ${payment.note}, paidInFull: ${payment.paidInFull}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog)

                //Show Notification
                this.$bvToast.toast('Payment Added Succesfully.', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })



                //hide the modal
                this.$nextTick(() => {
                    this.$bvModal.hide('addPaymentModal')
                })

                //reset modal fields
                this.paymentAmt = null
                this.paymentDesc = null
                this.paymentNote = null
                this.paymentDescState = null
                this.paymentAmtState = null
                this.paymentErrorTxt = null

            } catch (err) {
                console.log(err)
                //Show Notification
                this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                    title: `Error!`,
                    variant: "danger",
                    solid: true
                })
            }

            //Is the job paidInfull? If so add notification for user to sendout thank you/review message
            if (this.paidInFull) {
                console.log("Paidinfull is true so create notification to send thank you/review text message.")
                const reviewMsg = {
                    date: Math.floor(Date.now() / 1000),
                    text: `Final payment on ${this.contract.customer.lname.concat(', ', this.contract.customer.fname).substring(0, 14)} job. Send review text msg?`,
                    user: this.contract.salesman._id,
                    job: this.jobId,
                    noteType: "review",
                    read: false,
                    archived: false
                }

                try {
                    let resMsg = await this.$axios.post(`/notify`, reviewMsg, {
                        headers: {
                            Authorization: `Bearer ${this.$state.token}`
                        }
                    })
                    console.log(resMsg)

                } catch (err) {
                    console.log(err)
                }
            }
        }, // --End of addPayment method
        async togglePaidInFull() {

            console.log(`Toggle paidInFull called. val is: ${this.paidInFull}`)

            let updateData = {
                paidInFull: this.paidInFull
            }

            try {
                let res = await this.$axios.put(`/job/${this.jobId}`, updateData, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Job "paidInFull" status toggled successfully to ${this.paidInFull}`)
                console.log(res.data)
                this.contract.paidInFull = this.paidInFull

            } catch (err) {
                console.log(err)
            }

        }, // --End of togglePaidInFull method
        async setPaidInFull(event) { //brute force manual method

            let updateData = {
                paidInFull: event
            }

            console.log(`Manually set paidInFull called. paidInFull is to be set to: ${event}`)
            //console.log(e)


            try {
                await this.$axios.put(`/job/${this.jobId}`, updateData, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Job "paidInFull" status manually set to ${event}`)
                //console.log(res.data)
                this.contract.paidInFull = event

            } catch (err) {
                console.log(err)
            }

        }, // --End of setPaidInFull method
        async unSellJob() {
            let depositIndex = this.money.findIndex(money => {
                if (money.description.toLowerCase() == "deposit") {
                    return true
                }
            })

            let origDeposit = this.money[depositIndex]

            this.$bvModal.msgBoxConfirm('Are you sure you want to un-sell this Job? The deposit will be deleted and the deposit date removed.', {
                headerBgVariant: 'danger',
                headerTextVariant: 'white',
                title: 'Please Confirm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async value => {
                    if (value) {
                        console.log(`Un-sell job selected`)

                        this.money.splice(depositIndex, 1)
                        this.$emit("unsold")

                        let updateData = {
                            status: null,
                            sold: null,
                            dates: {
                                contractDate: getUnixTime(parse(this.contract.dates.contractDate, 'MM/dd/yyyy', new Date()))
                            }
                        }

                        try {
                            let res = await this.$axios.put(`/job/${this.jobId}`, updateData, {
                                headers: {
                                    Authorization: `Bearer ${this.$state.token}`
                                }
                            })

                            console.log(`Job route returned with code:`)
                            console.log(res)

                            let res2 = await this.$axios.delete(`/money/${origDeposit._id}`, {
                                headers: {
                                    Authorization: `Bearer ${this.$state.token}`
                                }
                            })

                            console.log(`Money route returned with code:`)
                            console.log(res2)

                            let toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ')
                            //log it
                            const newLog = {
                                logTime: getUnixTime(new Date()),
                                logMsg: `Job was un-sold.`,
                                addInfo: toString(origDeposit),
                                job: this.jobId,
                                logUser: this.userInfo.name
                            }

                            this.$actions.log(newLog)

                            this.$bvToast.toast('Job un-sold successfully', {
                                title: `Un-Sold`,
                                variant: "success",
                                solid: true
                            })

                        } catch (err) {
                            console.error(err)
                            //Show Notification
                            this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                                title: `Error!`,
                                variant: "danger",
                                solid: true
                            })
                        }

                    } else {
                        console.log("Abort!")
                    }
                })

        }, // --End of unSellJob method
        async sellJob(bvModalEvt) {
            //if validation fails we dont want the modal to disappear... Means we have to manually hide the modal later...
            bvModalEvt.preventDefault()

            //make sure fields aren't blank
            if (!this.deposit) {
                this.sellJobErrorTxt = "The 'Deposit' can not be left blank!"
                this.depositState = false
                return
            }

            if (!this.depositNote) {
                this.sellJobErrorTxt = "The 'Note' field can not be left blank!"
                this.depositNoteState = false
                return
            }

            console.log(`Sell job selected`)
            this.$emit("sold")


            let updateData = {
                status: 'pending',
                sold: true,
                dates: {
                    contractDate: getUnixTime(parse(this.contract.dates.contractDate, 'MM/dd/yyyy', new Date())),
                    depositDate: getUnixTime(new Date())
                }
            }

            let moneyData = {
                job: this.jobId,
                description: "Deposit",
                amount: numbro.unformat(this.deposit),
                debit: "Credit",
                note: this.depositNote,
                sales: {
                    tax: null,
                    roofing: null,
                    siding: null,
                    windows: null,
                    gutters: null,
                    creditCard: null,
                    permits: null
                }
            }

            this.money.push(moneyData)

            this.$nextTick(() => {
                this.$bvModal.hide('sellJobModal')
            })

            try {
                let res = await this.$axios.put(`/job/${this.jobId}`, updateData, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Job route returned with code:`)
                console.log(res)

                let res2 = await this.$axios.post(`/money`, moneyData, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Money route returned with id:`)
                console.log(res2)

                //log it
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `Job was set to "Sold"`,
                    addInfo: `Deposit amount set to: ${this.deposit}, Despoit Note: ${this.depositNote}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog)

                this.$bvToast.toast('Job set to "Sold" successfully', {
                    title: `Un-Sold`,
                    variant: "primary",
                    solid: true
                })

            } catch (err) {
                console.error(err)
                //Show Notification
                this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                    title: `Error!`,
                    variant: "danger",
                    solid: true
                })
            }

        }, // --End of sellJob method
        async delMoneyItem(moneyId) {

            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
                headerBgVariant: 'danger',
                headerTextVariant: 'white',
                title: 'Are you sure?',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async value => {
                    if (value) {

                        console.log(`Delete item: ${moneyId}`)
                        //get data on what we are deleting so we can log it later
                        let deleteIndex = this.money.findIndex(money => {
                            if (money._id == moneyId) {
                                return true
                            }
                        })

                        let itemToDelete = this.money[deleteIndex]

                        //send req to delete
                        try {
                            let res = await this.$axios.delete(`/money/${moneyId}`, {
                                headers: {
                                    Authorization: `Bearer ${this.$state.token}`
                                }
                            })

                            console.log(`Delete operation returned code ${res.data}`)

                            //remove item from local money array
                            this.money.splice(deleteIndex, 1)

                            //log it
                            let toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ')

                            const newLog = {
                                logTime: getUnixTime(new Date()),
                                logMsg: `Money line Item deleted successfully`,
                                addInfo: toString(itemToDelete),
                                job: this.jobId,
                                logUser: this.userInfo.name
                            }

                            this.$actions.log(newLog)

                            console.log(`paidInFull?? this.total is: ${this.total._value}...TotalPayments is: ${this.totalPayments._value}`)
                            if (numbro(this.total).subtract(this.totalPayments) > 0) {
                                if (this.paidInFull) {
                                    console.log("Balance is not 0...toggle paidInFull")
                                    this.togglePaidInFull()
                                } else {
                                    console.log("Balance is not 0 but paidInFull is already false... do nothing.")
                                }

                            }


                            //Show Notification
                            this.$bvToast.toast('Money line items was deleted successfully.', {
                                title: `Delete Successful`,
                                variant: "info",
                                solid: true
                            })

                        } catch (err) {
                            console.log(err)
                            //Show Notification
                            this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                                title: `Error!`,
                                variant: "danger",
                                solid: true
                            })
                        }

                    } else {
                        console.log("Nope, Dont delete this item/Cancel.")
                    }
                })


        }, // --End delMoneyItem method
        async editMoneyItem(moneyId) {
            //console.log(moneyId)

            let moneyItem = this.money.findIndex(item => {
                return item._id == moneyId
            })
            //console.log(moneyItem)
            if (this.money[moneyItem].description == 'Contract Price') {
                this.moneyEditItem.custDesc = this.money[moneyItem].description
                this.moneyEditItem.description = "Contract Price"
            } else {
                this.moneyEditItem.description = this.money[moneyItem].description
            }
            this.moneyEditItem.note = this.money[moneyItem].note
            this.moneyEditItem.privateNote = this.money[moneyItem].privateNote
            this.moneyEditItem.amount = this.money[moneyItem].amount
            this.moneyEditItem.debit = this.money[moneyItem].debit
            this.moneyEditItem.sales.roofing = this.money[moneyItem].sales.roofing
            this.moneyEditItem.sales.siding = this.money[moneyItem].sales.siding
            this.moneyEditItem.sales.windows = this.money[moneyItem].sales.windows
            this.moneyEditItem.sales.gutters = this.money[moneyItem].sales.gutters
            this.moneyEditItem.sales.creditCard = this.money[moneyItem].sales.creditCard
            this.moneyEditItem.sales.permits = this.money[moneyItem].sales.permits
            this.moneyEditItem.sales.tax = this.money[moneyItem].sales.tax
            this.moneyEditItem._id = moneyId

            this.$bvModal.show('editMoneyItemModal')

        },
        addUpSales(item) {
            let roofing = this[item].sales.roofing ? this[item].sales.roofing : 0
            let siding = this[item].sales.siding ? this[item].sales.siding : 0
            let windows = this[item].sales.windows ? this[item].sales.windows : 0
            let gutters = this[item].sales.gutters ? this[item].sales.gutters : 0
            let permits = this[item].sales.permits ? this[item].sales.permits : 0
            let creditCard = this[item].sales.creditCard ? this[item].sales.creditCard : 0
            let tax = this[item].sales.tax ? this[item].sales.tax : 0

            let total = roofing + siding + windows + gutters + permits + creditCard + tax

            this[item].amount = total.toString()

        },
        calcTax(item) {
            let roofing = this[item].sales.roofing ? this[item].sales.roofing : 0
            let siding = this[item].sales.siding ? this[item].sales.siding : 0
            let windows = this[item].sales.windows ? this[item].sales.windows : 0
            let gutters = this[item].sales.gutters ? this[item].sales.gutters : 0
            let permits = this[item].sales.permits ? this[item].sales.permits : 0
            let creditCard = this[item].sales.creditCard ? this[item].sales.creditCard : 0

            let totalWithoutTax = roofing + siding + windows + gutters + permits + creditCard

            let salesTaxRate = numbro(this.company.salesTaxRate)

            let calculatedTax = totalWithoutTax * (salesTaxRate / 100)

            console.log(`tax calcualted as ${calculatedTax}`)

            this[item].sales.tax = Number(numbro(calculatedTax).format({ mantissa: 2 }))

            this.addUpSales(item)
        },
        async submitEditMoneyItem(bvModalEvt) {
            //if validation fails we dont want the modal to disappear... Means we have to manually hide the modal later...
            bvModalEvt.preventDefault()
            this.editMoneyErrorTxt = null
            this.editMoneyDescState = null


            //make sure fields aren't blank
            if (!this.moneyEditItem.description) {
                this.editMoneyErrorTxt = "The 'Description' can not be left blank!"
                this.editMoneyDescState = false
                return
            }

            console.log(this.moneyEditItem)

            let payload = this.moneyEditItem

            try {
                let res = await this.$axios.put(`/money/${payload._id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Money line item update returned with code:`)
                console.log(res)

                //update the current page
                let moneyIndex = this.money.findIndex(item => {
                    return item._id == payload._id
                })

                //This below would not be reactive - use Vue.$set instead
                //this.money[moneyIndex] = payload
                this.$set(this.money, moneyIndex, payload)

                //recalc totals

                //is job paid in full or no longer??
                this.togglePaidInFull()

                //log it
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `Money Line item was updated successfully`,
                    addInfo: `Money item updated to: amount: ${payload.amount}, description: ${payload.description}, note: ${payload.note} privateNote: ${payload.privateNote}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog)

                this.$bvToast.toast('Money line item updated successfully', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })

                //reset moneyEditItem
                this.moneyEditItem = {
                    description: "",
                    debit: "",
                    amount: null,
                    note: "",
                    privateNote: "",
                    sales: {
                        roofing: null,
                        siding: null,
                        windows: null,
                        gutters: null,
                        creditCardd: null,
                        tax: null,
                        permits: null
                    }
                }

            } catch (err) {
                console.error(err)
                //Show Notification
                this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                    title: `Error!`,
                    variant: "danger",
                    solid: true
                })
            }

            //hide the modal
            this.$nextTick(() => {
                this.$bvModal.hide('editMoneyItemModal')
            })

        }, // -- End submitEditMoneyitem 
        async submitExtra(bvModalEvt) {

            //if validation fails we dont want the modal to disappear... Means we have to manually hide the modal later...
            bvModalEvt.preventDefault()
            this.addExtraErrorTxt = null
            this.addExtraDescState = null
            this.addExtraAmtState = null

            //make sure fields aren't blank
            if (!this.addExtra.description) {
                this.addExtraErrorTxt = "The 'Description' can not be left blank!"
                this.addExtraDescState = false
                return
            }

            if (!this.addExtra.amount) {
                this.addExtraErrorTxt = "At least one of the 'sales' fields must have a dollar amount other then 0"
                this.addExtraAmtState = false
                return
            }

            console.log(this.addExtra)

            let payload = this.addExtra
            payload.debit = "debit"
            payload.job = this.jobId

            try {
                let res = await this.$axios.post(`/money`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                console.log(`Money Item added. New id: ${res.data}`)

                //update the current page
                payload._id = res.data
                this.money.push(payload)

                //log it
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `New Money Extra added.`,
                    addInfo: `Money extra added. amount: ${payload.amount}, description: ${payload.description}, note: ${payload.note}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog)

                this.$bvToast.toast('Money extra added successfully', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })

                //reset moneyEditItem
                this.addExtra = {
                    description: "",
                    debit: "debit",
                    amount: null,
                    sales: {
                        roofing: null,
                        siding: null,
                        windows: null,
                        gutters: null,
                        creditCard: null,
                        tax: null,
                        permits: null
                    }
                }

            } catch (err) {
                console.error(err)
                //Show Notification
                this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                    title: `Error!`,
                    variant: "danger",
                    solid: true
                })
            }

            //hide the modal
            this.$nextTick(() => {
                this.$bvModal.hide('addExtraModal')
            })

        }, // --End submitExtra
        showInvoice() {
            this.$router.push({ name: 'viewInvoice', params: { id: this.jobId } })
        },
        ccLinkAdded(payment) {
            console.log("'paymentLinkAdded' emitted'...")
            this.contract.ccPaymentLinks.push(payment)
        },
        ccFeeAdded(fee) {
            console.log("'feeAdded' emitted...")
            this.money.push(fee)
        },
        copyLink(url) {
            navigator.clipboard.writeText(url)
            console.log(`The url: ${url} was successfully copied to the clipboard`)
        }

    },
    created() {
        numbro.setDefaults({
            thousandSeparated: true,
            mantissa: 2
        })

        this.getMoney()
    },
    mounted() {
        //if balance is zero ask if job should be set to paidInFull?
        setTimeout(() => {
            if (this.balance.value() == 0 && this.paidInFull === false) {
                this.$bvModal.msgBoxConfirm('The job balance is zero. Should job be set as "Paid in Full"?', {
                    title: "Paid in Full",
                    okTitle: "Yes",
                    okVariant: "success",
                    cancelTitle: "No",
                    centered: true
                }).then(value => {
                    if (value === true) {
                        this.togglePaidInFull()
                    }
                }).catch(err => {
                    console.log(`Error while checking if job is 0 balance: ${err}`)
                })
            }
        }, 400)
    }
}
</script>

<style lang="scss" scoped>
.custom-green {
    color: white;
    background-color: #95bf8f; //dark sea green
}

.custom-blue {
    background-color: #00abf5; //blue jeans
}

.lightTxt {
    color: gray;
    font-size: .8em;

    div {
        margin-left: .25em;
    }
}

.debit {
    font-weight: bold;
}

.credit {
    color: #dc3545;
}

.credit:before {
    content: "-";
}

.money-totals {
    width: 18%;
}

@media only screen and (max-width: 600px) {
    .money-totals {
        width: 60%;
    }
}

.hover-hand:hover {
    cursor: pointer;
}

.rightJustified {
    text-align: right;
}

.card-header {
    padding: .5rem 1.25rem
}

.ccRow {
    padding: .5rem 1rem;
    font-size: .9em
}

.ccHeaderRow {
    font-weight: bold;
    font-size: .8em;
}

.txtMsg {
    max-width: 375px;
    word-wrap: break-word;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
    }
}

.send {
    color: white;
    background: #5BC236;
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: #5BC236;
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
    }
}
</style>