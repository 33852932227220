<template>
<b-card header-text-variant="primary" border-variant="primary" class="mt-3" header-bg-variant="white">
    <template #header>
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0 monoTitle">Contract Details 
                <small>
                    <b-badge :variant="soldBadge" v-html="getStatus(contract.status)"></b-badge>
                    <b-badge v-if="isRepeatJob" class="ml-1" variant="warning">Repeat Job Schedule</b-badge>
                    <b-badge v-if="isRepeatJob && !repeatJob.active" class="ml-1" variant="danger">Repeat Inactive</b-badge>
                </small>
            </h5>
            <b-button-toolbar v-if="!isMobile">
                <edit-contract-details class="d-inline" :contract="contract"/>
                <b-button class="d-inline ml-1" variant="outline-primary" size="sm" @click="viewContract"><font-awesome-icon icon="fa-regular fa-file-alt"></font-awesome-icon> View Contract</b-button>
                <b-button-group class="ml-1">
                    <b-button variant="outline-primary" size="sm" @click="gotoCopyContract"><font-awesome-icon icon="fa-regular fa-copy"></font-awesome-icon> Copy</b-button>
                    <b-dropdown variant="outline-primary" size="sm" right>
                        <template #button-content>
                            <font-awesome-icon icon="fa-ellipsis-h"></font-awesome-icon>
                        </template>
                        <b-dropdown-item v-if="!isRepeatJob" @click="showRepeatJobModal">Create Repeat Job</b-dropdown-item>
                        <b-dropdown-item v-if="isRepeatJob" @click="showRepeatJobModal">Edit Repeat Job</b-dropdown-item>
                        <b-dropdown-item @click="deleteJob">Delete Job</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
            </b-button-toolbar>
        </div>
    </template>
    <b-button-toolbar class="mb-2"  v-if="isMobile">
        <edit-contract-details class="d-inline" :contract="contract"/>
        <b-button class="d-inline ml-1" variant="outline-primary" size="sm" @click="viewContract"><font-awesome-icon icon="fa-regular fa-file-alt"></font-awesome-icon> View Contract</b-button>
        <b-button-group class="ml-1">
            <b-button variant="outline-primary" size="sm" @click="gotoCopyContract"><font-awesome-icon icon="fa-regular fa-copy"></font-awesome-icon> Copy</b-button>
            <b-dropdown variant="outline-primary" size="sm" right>
                <template #button-content>
                    <font-awesome-icon icon="fa-ellipsis-h"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="showRepeatJobModal">Create Repeat Job</b-dropdown-item>
                <b-dropdown-item @click="deleteJob">Delete Job</b-dropdown-item>
            </b-dropdown>
        </b-button-group>
    </b-button-toolbar>
    <b-card-text>
       
        <b-row>
            <b-col sm="6">
                <h5>Contract # {{contract.contractId}} | {{contract.jobType}}</h5>
                <h5 class="text-info" v-if="contract.jobLabel">{{contract.jobLabel}}</h5>
                <address>
                    <b>Jobsite Address</b> <br />
                    {{contract.siteAddress.street}}<br />
                    {{contract.siteAddress.city}}, {{contract.siteAddress.state}} {{contract.siteAddress.zip}}
                </address>
                
                <div class="mb-2">
                    Salesman: {{contract.salesman.name}} <br/>
                    Mechanic: {{contract.mechanic ? contract.mechanic : "n/a"}}
                </div>  
                
            </b-col>
            <b-col sm="6">
                <font-awesome-icon icon="map-marked-alt"></font-awesome-icon> View Map: <br />
                <b-link @click.prevent="gotoMap()"><b-img :src="streetViewUrl" fluid class="border border-primary rounded"></b-img></b-link><br />
                <small>Streetview Date: {{streetViewDate}}</small>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-card class="contractInfoCard" body-class="date-card-body" header-class="date-card-header monoTitle" align="center" header="Contract Date" border-variant="dark">
                    <b-card-text>{{contract.dates.contractDate}}</b-card-text>
                </b-card>
            </b-col>
            <b-col sm="3">
                <b-card class="contractInfoCard" body-class="date-card-body" header-class="date-card-header monoTitle" align="center" header="Deposit Date" border-variant="dark">
                    <b-card-text>{{ contract.sold ? contract.dates.depositDate : "n/a" }}</b-card-text>
                </b-card>
            </b-col>
            <b-col sm="3">
                <b-card class="contractInfoCard" body-class="date-card-body" header-class="date-card-header monoTitle" align="center" header="Start Date" border-variant="dark">
                    <b-card-text>{{contract.sold ? contract.dates.startDate : "n/a"}}</b-card-text>
                </b-card>
            </b-col>
            <b-col sm="3">
                <b-card class="contractInfoCard" body-class="date-card-body" header-class="date-card-header monoTitle" align="center" header="Finish Date" border-variant="dark">
                    <b-card-text>{{contract.sold ? contract.dates.finishedDate : "n/a"}}</b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </b-card-text>
    <div class="mb-3"><b>Proposal text:</b><br/>{{ cleanScopeText }}</div>
    
    <div class="mb-1">
        <new-service-tix :jobId="id" :customerId="contract.customer._id" class="d-inline" @new-tix-added="addNewTix"/>
        <b>&nbsp;Service tickets:</b> 
    </div>
    <b-list-group>
        <b-list-group-item v-for="tix in sortedServiceTix" :key="tix._id" class="serviceItem" :to="`/serviceTix/${tix._id}?fromJob=${id}`">
            <div class="d-flex flex-row justify-content-between">
                <b-badge :variant="tix.variant" class="mt-1">{{tix.status}}</b-badge>
                <div class="flex-grow-1 ml-2 no-wrap-text overflow-hidden">
                    <b v-if="!tix.finished">{{tix.dates.addedDate}}</b>
                    <b v-else>{{tix.dates.finishedDate}}</b>
                    {{tix.serviceDesc}}
                </div>
                <div class="">
                    <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                </div>
            </div>
        </b-list-group-item>
    </b-list-group>
    <div class="mb-1">
        <new-note :jobId="id" class="d-inline" @new-note-added="addNewNote"/>
        <b>&nbsp;Job Notes:</b> 
    </div>
    <b-list-group>
        <b-list-group-item v-for="note in contract.notes" :key="note.noteDate" class="noteItem">
            <div class="d-flex flex-row justify-content-start">
                <div class="">
                    {{format(fromUnixTime(note.noteDate), 'MM/dd/yyyy')}}
                </div>
                <div class="ml-2">
                    {{note.noteText}} <font-awesome-icon class="text-danger" icon="fa-regular fa-trash-can" @click="delNote(note)"/>
                </div>
            </div>
        </b-list-group-item>
    </b-list-group>

    <b-modal 
        id="repeatJobsModal" 
        title="Create Repeat Jobs" 
        @ok="submitRepeatJob"
    >
        <b-alert variant="warning" :show="!repeatJob.active && isRepeatJob">This schedule is currently de-activated.</b-alert>
        <b-form-group label="Repeat Schedule">
            <b-form-radio-group
                id="repeatSchedule"
                v-model="repeatJob.schedule"
                :options="repeatJob.scheduleOptions"
                button-variant="outline-primary"
                name="repeatScheduleRadio"
                buttons
                @change="setRepeatJobSchedule"
            ></b-form-radio-group>
        </b-form-group>

        <div class="h5">Select days when this job should repeat:</div>
        
        <b-form-group id="day1"  label="Repeat Day 1:" label-cols-sm="3" v-show="repeatJob.annual">
            <b-input-group>
                <b-form-input v-model="repeatJob.day1"></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        v-model="repeatJob.day1"
                        button-only
                        right
                        locale="en-US"
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>

        <b-form-group id="day2"  label="Repeat Day 2:" label-cols-sm="3" v-show="repeatJob.biAnnual">
            <b-input-group>
                <b-form-input v-model="repeatJob.day2"></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        v-model="repeatJob.day2"
                        button-only
                        right
                        locale="en-US"
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>

        <b-form-group id="day3"  label="Repeat Day 3:" label-cols-sm="3" v-show="repeatJob.triAnnual">
            <b-input-group>
                <b-form-input v-model="repeatJob.day3"></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        v-model="repeatJob.day3"
                        button-only
                        right
                        locale="en-US"
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>

        <b-form-group id="day4"  label="Repeat Day 4:" label-cols-sm="3" v-show="repeatJob.quarterly">
            <b-input-group>
                <b-form-input v-model="repeatJob.day4"></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        v-model="repeatJob.day4"
                        button-only
                        right
                        locale="en-US"
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
        <template #modal-footer="{ok, cancel}" >
            <b-button v-if="isRepeatJob" variant="danger" @click="deleteRepeatSchedule">
                <font-awesome-icon :icon="['far', 'trash-can']" />
            </b-button>
            <b-button  v-if="repeatJob.active && isRepeatJob" variant="outline-secondary" @click="deactivateRepeatJob" class="mr-auto">
                De-Activate
            </b-button>
            <b-button  v-if="!repeatJob.active && isRepeatJob" variant="outline-success" @click="activateRepeatJob" class="mr-auto">
                Activate
            </b-button>
            <b-button  variant="secondary" @click="cancel()">
                Cancel
            </b-button>
            <b-button  variant="primary" @click="ok()">
                {{isRepeatJob? 'Update Schedule' : 'Set Schedule'}}
            </b-button>  
        </template>
    </b-modal>

</b-card>
</template>

<script>
import editContractDetails from './editContractDetails.vue'
import { format, getDayOfYear, fromUnixTime, getUnixTime, parse } from 'date-fns'
import NewServiceTix from '../serviceBoard-components/newServiceTix.vue'
import newNote from './newNote.vue'

export default {
    name: "contractDetails",
    components: {
        editContractDetails,
        NewServiceTix,
        newNote
    },
   
    data() {
        return {
            format: format,
            fromUnixTime: fromUnixTime,
            contracts: [],
            serviceTix: [],
            soldBadge: "danger",
            streetViewUrl: "",
            streetViewDate: "",
            isRepeatJob: false,
            repeatJob: {
                id: null,
                day1: null,
                day2: null,
                day3: null,
                day4: null,
                annual: true,
                biAnnual: false,
                triAnnual: false,
                quarterly: false,
                schedule: "annual",
                scheduleOptions: [
                    { text: "Annual", value: "annual" },
                    { text: "Bi-Annual", value: "biAnnual" },
                    { text: "Tri-Annual", value: "triAnnual" },
                    { text: "Quarterly", value: "quarterly" },
                ]
            }
        }
    },
    props: {
        contract: Object
    },
    computed: {
        isMobile() {
            return this.$state.isMobile
        },
        id() {
            return this.$route.params.id
        },
        userInfo () {
            return this.$state.userInfo
        },
        sortedServiceTix() {
            const sortedService = this.serviceTix.slice().sort((a, b) => {
                if (a.dateAdded < b.dateAdded) {
                    return -1
                }
                if (a.dateAdded > b.dateAdded) {
                    return 1
                }
                return 0
            })
            return sortedService.reverse()
        },
        repeatSchedule() {
            let scheduleDays = []
            if(this.repeatJob.day1){
                scheduleDays.push(getDayOfYear(parse(this.repeatJob.day1, 'yyyy-MM-dd', new Date())))  
            }
            if(this.repeatJob.day2){
                scheduleDays.push(getDayOfYear(parse(this.repeatJob.day2, 'yyyy-MM-dd', new Date())))
            }
            if(this.repeatJob.day3){
                scheduleDays.push(getDayOfYear(parse(this.repeatJob.day3, 'yyyy-MM-dd', new Date())))
            }
            if(this.repeatJob.day4){
                scheduleDays.push(getDayOfYear(parse(this.repeatJob.day4, 'yyyy-MM-dd', new Date())))
            }
            return scheduleDays
        },
        cleanScopeText() {
            if(this.contract.scopeText){
                let tempText = this.contract.scopeText.replaceAll(/(<([^>]+)>)/gi, "").replaceAll('&bull;', "").replaceAll('&quot;', "").replaceAll('&nbsp;', "")
                return tempText.substring(0, 240) + "..."
            } else {
                return ""
            }
        }
    },
    created() {
        this.getStreetView()
        this.getServiceTix()
        this.getRepeatJobSchedule()
    },
    methods: {
        showRepeatJobModal() {
            this.$bvModal.show('repeatJobsModal')
        },
        async deactivateRepeatJob(){
            let payload = { active: false }
            this.repeatJob.active = false
            this.$bvModal.hide('repeatJobsModal')

            try {
                let res = await this.$axios.put(`/repeatJob/${this.repeatJob.id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })
                console.log(res)
                this.$bvToast.toast('Repeat Schedule was De-Activated.', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })
            } catch (err) {
                console.log(err)
            }
        },
        async activateRepeatJob(){
            let payload = { active: true }
            this.repeatJob.active = true
            this.$bvModal.hide('repeatJobsModal')
            
            try {
                let res = await this.$axios.put(`/repeatJob/${this.repeatJob.id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })
                console.log(res)
                this.$bvToast.toast('Repeat Schedule was Activated.', {
                    title: `Success`,
                    variant: "success",
                    solid: true
                })
            } catch (err) {
                console.log(err)
            }
        },
        async submitRepeatJob() {
            let payload = {
                job: this.id,
                active: true,
                daysOfYear: this.repeatSchedule
            }

            console.table(payload)

            if(this.isRepeatJob) { //already existing schedule - Update 
                try {
                    let res = await this.$axios.put(`/repeatJob/${this.repeatJob.id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.$state.token}`
                        }
                    })

                    console.log(res)

                    this.$bvToast.toast('Repeat Schedule was successfully updated.', {
                        title: `Success`,
                        variant: "success",
                        solid: true
                    })

                    //logit
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Existing Repeat schedule was updated.`,
                        addInfo: `Repeat Schedule is: ${this.repeatJob.schedule}. New days are: ${this.repeatSchedule}`,
                        job: this.id,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)

                } catch (err) {
                    
                    console.error(err)
                }

            } else { //create a new schedule
                this.isRepeatJob = true
                try {
                    let res = await this.$axios.post(`/repeatJob/`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.$state.token}`
                        }
                    })

                    console.log(res)
                    this.$bvToast.toast('Repeat Schedule was added successfully.', {
                        title: `Success`,
                        variant: "success",
                        solid: true
                    })

                    //logit
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `New Repeat schedule was added.`,
                        addInfo: `Repeat Schedule is: ${this.repeatJob.schedule}. Days are: ${this.repeatSchedule}`,
                        job: this.id,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)

                } catch (err) {
                    console.error(err)
                }

            }
            
        },
        setRepeatJobSchedule(){
            console.log(`setRepeatJobSchedule called. Schedule is ${this.repeatJob.schedule}`)
            const schedule = this.repeatJob.schedule
            
            if(schedule === 'annual') {
                console.log("Set annual")
                this.repeatJob.annual = true
                this.repeatJob.biAnnual = false
                this.repeatJob.triAnnual = false
                this.repeatJob.quarterly = false
                this.repeatJob.day2 = null
                this.repeatJob.day3 = null
                this.repeatJob.day4 = null
            } else if(schedule === 'biAnnual') {
                console.log("Set biAnnual")
                this.repeatJob.annual = true
                this.repeatJob.biAnnual = true
                this.repeatJob.triAnnual = false
                this.repeatJob.quarterly = false
                this.repeatJob.day3 = null
                this.repeatJob.day4 = null
            } else if(schedule === 'triAnnual') {
                console.log("Set triAnnual")
                this.repeatJob.annual = true
                this.repeatJob.biAnnual = true
                this.repeatJob.triAnnual = true
                this.repeatJob.quarterly = false
                this.repeatJob.day4 = null
            } else if(schedule === 'quarterly') {
                console.log("Set quarterly")
                this.repeatJob.annual = true
                this.repeatJob.biAnnual = true
                this.repeatJob.triAnnual = true
                this.repeatJob.quarterly = true
            }
        },
        addNewTix(tix) {

            //console.log("New Ticket added... tix data is:")
            //console.log(tix)

            tix.dates.addedDate = format(fromUnixTime(tix.dates.addedDate), 'MM/dd/yyyy')
            tix.variant = 'warning'
            
            this.serviceTix.push(tix)

            this.$bvToast.toast('New Service Ticket Added', {
                title: `Success`,
                variant: "success",
                solid: true
            })
        },
        addNewNote(note) {
            this.contract.notes.push(note)

            this.$bvToast.toast('New Note Added', {
                title: `Success`,
                variant: "success",
                solid: true
            })
        },
        delNote(note) {

            let payload = {
                noteId : note._id,
                jobId : this.id
            }

            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this note?', {
                title: 'Please Confirm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(async value => {
                    console.log(`Delete note ${payload.noteId} selected. Vale was: ${value}`)

                    try {
                        let res = await this.$axios.put(`/job/delNote/${payload.noteId}`, payload, {
                            headers: {
                                Authorization: `Bearer ${this.$state.token}`
                            }
                        })

                        console.log(res)

                        if(res.status == 200) {
                             //logit
                            const newLog = {
                                logTime: getUnixTime(new Date()),
                                logMsg: `Note Deleted from for job.`,
                                addInfo: `Deleted Note text: ${note.noteText}`,
                                job: this.id,
                                logUser: this.userInfo.name
                            }

                            this.$actions.log(newLog)

                            //remove from local array
                            let noteToDeleteIndex = this.contract.notes.findIndex( el => el._id === payload.noteId )
                            console.log(`Index to delete: ${noteToDeleteIndex}`)
                            this.contract.notes.splice( noteToDeleteIndex, 1 )

                            //notify user
                            this.$bvToast.toast('Note was deleted successfully.', {
                                title: `Success`,
                                variant: "info",
                                solid: true
                            })

                        }
                        

                    } catch (err) {
                        console.error(err)
                    }

                })
                .catch(err => {
                    console.log(err)
                })

        },
        async getStreetView(){
            let apiKey = "AIzaSyDZbo8X_q8crHijPzXsjD6krj2luf-00Jw"
            let metaUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}&key=${apiKey}`
            let streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?location=${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}&size=300x135&key=${apiKey}`
            
            try {
                let res = await this.$axios.get(metaUrl)
                if(res.data.status == "OK") {
                    this.streetViewDate = res.data.date
                    this.streetViewUrl = streetViewUrl
                } else {
                    this.streetViewDate = "No Data"
                    this.streetViewUrl = "https://via.placeholder.com/300x135?text=No+StreetView+Available"
                }
            } catch (err) {
                console.log("Error getting streetview metadata")
            }
        },
        async getServiceTix() {

            try {
                let serviceTix = await this.$axios.get(`/service/job/${this.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                serviceTix.data.forEach(tix => {
                    if (tix.status.toLowerCase() == 'finished') {
                        tix.variant = 'success'
                        tix.finished = true
                    } else {
                        tix.variant = 'warning'
                        tix.finished = false
                    }

                    //format dates
                    tix.dates.addedDate = format(fromUnixTime(tix.dates.addedDate), 'MM/dd/yyyy')
                    if (tix.dates.finishedDate) {
                        tix.dates.finishedDate = format(fromUnixTime(tix.dates.finishedDate), 'MM/dd/yyyy')
                    }


                })


                this.serviceTix = serviceTix.data

            } catch (err) {
                console.error(err)
            }

        },
        async getRepeatJobSchedule() {

            try {
                let repeat = await this.$axios.get(`/repeatJob/job/${this.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                if(repeat.status === 200) {
                    console.log(repeat)
                    this.isRepeatJob = true
                    this.repeatJob.id = repeat.data[0]._id
                    this.repeatJob.active = repeat.data[0].active
                    this.repeatJob.lastRun = repeat.data[0].lastRun
                    //set repeat schedule
                    if(repeat.data[0].daysOfYear.length === 4){
                        this.repeatJob.schedule = 'quarterly'
                        this.setRepeatJobSchedule()
                        this.repeatJob.day1 = format(this.dateFromDay(repeat.data[0].daysOfYear[0]), 'yyyy-MM-dd')
                        this.repeatJob.day2 = format(this.dateFromDay(repeat.data[0].daysOfYear[1]), 'yyyy-MM-dd')
                        this.repeatJob.day3 = format(this.dateFromDay(repeat.data[0].daysOfYear[2]), 'yyyy-MM-dd')
                        this.repeatJob.day4 = format(this.dateFromDay(repeat.data[0].daysOfYear[3]), 'yyyy-MM-dd')
                    }
                    if(repeat.data[0].daysOfYear.length === 3){
                        this.repeatJob.schedule = 'triAnnual'
                        this.setRepeatJobSchedule()
                        this.repeatJob.day1 = format(this.dateFromDay(repeat.data[0].daysOfYear[0]), 'yyyy-MM-dd')
                        this.repeatJob.day2 = format(this.dateFromDay(repeat.data[0].daysOfYear[1]), 'yyyy-MM-dd')
                        this.repeatJob.day3 = format(this.dateFromDay(repeat.data[0].daysOfYear[2]), 'yyyy-MM-dd')
                    }
                    if(repeat.data[0].daysOfYear.length === 2){
                        this.repeatJob.schedule = 'biAnnual'
                        this.setRepeatJobSchedule()
                        this.repeatJob.day1 = format(this.dateFromDay(repeat.data[0].daysOfYear[0]), 'yyyy-MM-dd')
                        this.repeatJob.day2 = format(this.dateFromDay(repeat.data[0].daysOfYear[1]), 'yyyy-MM-dd')
                    }
                    if(repeat.data[0].daysOfYear.length === 1){
                        this.repeatJob.schedule = 'annual'
                        this.setRepeatJobSchedule()
                        this.repeatJob.day1 = format(this.dateFromDay(repeat.data[0].daysOfYear[0]), 'yyyy-MM-dd')
                    }
                
                } else if (repeat.status === 201) {
                    //there is no repeat schedule
                    console.log(`getRepeatJobSchedule returned with no schedule for this job.`)
                } 

            } catch (err) {
                console.log("There was an error with the repeat jobs call.")
                console.error(err)
            }

        },
        async deleteRepeatSchedule() {
            this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this repeat schedule completely. You can de-activate this schedule temporarily as well.`, {
                title: 'Please Confirm',
                okVariant: 'danger',
                okTitle: 'Delete',
                centered: true
            })
            .then(async value => {

                if(value){
                    this.$bvModal.hide('repeatJobsModal')

                    try {
                        const res = await this.$axios.delete(`/repeatJob/${this.repeatJob.id}`, {
                            headers: { Authorization: `Bearer ${this.$state.token}` }
                        })
                        
                        if(res.status === 200) {
                            console.log('RepeatJob deleted successfully')
                            
                            //notiy user
                            this.$bvToast.toast(`Repeat Job was successfully deleted.`, {
                                title: 'Delete Successful',
                                variant: 'success',
                                solid: true
                            })
                            //log it
                            let log = {
                                logTime: getUnixTime(new Date()),
                                logMsg: `Repeat job schedule was deleted for job ${this.id}`,
                                job: this.id,
                                logUser: this.userInfo._id,
                                addInfo: `The deleted schedule was: `
                            }
                            this.$actions.log(log)

                        } else {
                            console.err(`Something went wrong :-(`)
                            this.$bvToast.toast(`Woops, something went wrong.`, {
                                title: 'Delete Un-successful',
                                variant: 'warning',
                                solid: true
                            })
                        }

                    } catch (err) {
                        console.error(err)
                    }

                } else {
                    console.log(`user canceled delete operation.`)
                }
            })
        },
        dateFromDay(day){
            let year = new Date().getFullYear()
            let date = new Date(year, 0); // initialize a date in `year-01-01`
            return new Date(date.setDate(day)); // add the number of days
        },
        gotoCopyContract () {
            this.$router.push({name: 'copyContract', params: { id : this.contract.customer._id } })
        },
        viewContract() {
            console.log("View Contract Selected")
            this.$router.push({ name: 'viewContract', params: { id: this.id } })
        },
        deleteJob () {
            this.$bvModal.msgBoxConfirm('This cannot be undone! Last warning...', {
                headerBgVariant: 'danger',
                headerTextVariant: 'white',
                title: 'Are you sure you want to delete job?',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Delete Job',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if(value) {

                    console.log(`Delete job was selected. job id: ${this.id}`)
                    //send req to delete
                    try {
                        let res = await this.$axios.delete(`/job/${this.id}`, {
                            headers: {
                                Authorization: `Bearer ${this.$state.token}`
                            }
                        })

                        console.log(`Delete operation returned code ${res.data}`)
                        
                                   
                        //log it
                        const newLog = {
                            logTime: getUnixTime(new Date()),
                            logMsg: `Job Deleted. Job ID: ${this.id}`,
                            addInfo: `Customer Name on Job: ${this.contract.customer.lname}, ${this.contract.customer.fname}. ContractId: ${this.contract.contractId}`,
                            logUser: this.userInfo.name,
                            job: this.id
                        }

                        this.$actions.log(newLog)
                        
                        //Show Notification
                        this.$root.$bvToast.toast('Job deleted successfully.', {
                            title: `Delete Successful`,
                            variant: "info",
                            toaster: 'b-toaster-top-full'
                        })

                        this.$router.push({ name: 'dashboard' })

                    } catch (err) {
                        console.log(err)
                        //Show Notification
                        this.$bvToast.toast('Woops something went wrong. Check the console for error message.', {
                            title: `Error!`,
                            variant: "danger",
                            solid: true
                        })
                    }

                } else {
                    console.log("Nope, Dont delete this item/Cancel.")
                }
            })
        },
        firstLetterCap(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase()
        },
        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        gotoMap() {
            this.$router.push({ name: 'Map', params: { location: `${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}` } })
        },
        getStatus(status) {
            if (this.contract.sold) {
                this.soldBadge = "success"
                return `<b-badge variant="primary">Sold / ${status}</b-badge>`
            } else {
                return `<b-badge variant="warning">Un-Sold</b-badge>`
            }
        }
    }
}

</script>


<style lang="scss" scoped>
    .card-body {
        padding-top: .5rem;
    }
    .card-header {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
     .date-card-body {
        padding: 5px;
    }
    .date-card-header {
        padding: 5px;
    }
    .contractInfoCard {
        margin-bottom: 5px;
    }
    .serviceItem {
        padding-top: 5px;
        padding-bottom: 6px;
    }
    .noteItem {
        padding: .25rem .5rem;
        font-size: .8em;
    }
    .no-wrap-text {
        white-space: nowrap;
        text-overflow: ellipsis
    }
    .custom-blue {
        background-color: #00abf5; //blue jeans
    }

</style>