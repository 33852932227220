<template>
<div class="container">
    <b-row class="mt-4">
        <b-col>
            <h3>Upload files</h3>
            <b-button variant="primary" @click="goBack">
                <font-awesome-icon icon="fa-chevron-left"></font-awesome-icon> Back
            </b-button> 
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col>
           <!--UPLOAD-->
            <form enctype="multipart/form-data" novalidate>
               
                <div class="dropbox">
                    <input type="file" multiple id="files" :disabled="isSaving" @change="filesChange($event.target.files); fileCount = $event.target.files.length" class="input-file">
                    <p v-if="isInitial">
                        <font-awesome-icon size="2x" icon="fa-solid fa-cloud-arrow-up" class="mb-2"></font-awesome-icon><br>
                        Drag your file(s) here to begin<br> or click/tap to browse.
                    </p>
                    <p v-if="isSaving">
                        <font-awesome-icon size="2x" icon="fa-solid fa-spinner" spin-pulse class="mb-2"></font-awesome-icon><br>
                        Uploading {{ fileCount }} files...
                    </p>
                    <p v-if="isSuccess" class="text-light text-center">
                        <font-awesome-icon size="2x" icon="fa-regular fa-thumbs-up" class="mb-2"></font-awesome-icon><br>
                        All {{ fileCount }} files uploaded successfully!
                    </p>
                    <div v-if="returnedFileNames">
                        <div class="thumbs-box">
                            <div v-for="file in returnedFileNames" :key="file.filename">
                                <b-img class="thumb" v-if="file.mimetype == 'application/pdf'" :src="url+'/files/'+contractId+'/'+'tnpdf_'+file.filename.slice(0,file.filename.length - 4)+'.jpg'" :alt="file.filename"></b-img>
                                <b-img class="thumb" v-else :src="url+'/files/'+contractId+'/'+'tn_'+file.filename" :alt="file.filename"></b-img>
                            </div>
                        </div>
                    </div>
                    <p v-if="isFailed" class="text-danger">\
                        <font-awesome-icon size="2x" icon="fa-solid fa-circle-exclamation" class="mb-2 text-danger"/><br>
                        FAILED: {{uploadError}}
                    </p>
                </div>
            </form>

        </b-col>
    </b-row>   
</div>
</template>

<script>

//const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3 

export default {
    name: 'upload',
    components: {
        
    },
    data() {
        return {
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            returnedFileNames: null,
            url: this.$baseUrl
        }
    },
    computed: {
        isInitial() {
            return this.currentStatus === "STATUS_INITIAL"
        },
        isSaving() {
            return this.currentStatus === "STATUS_SAVING"
        },
        isSuccess() {
            return this.currentStatus === "STATUS_SUCCESS"
        },
        isFailed() {
            return this.currentStatus === "STATUS_FAILED"
        },
        userInfo () {
          return this.$state.userInfo
        },
        id () {
          return this.$route.params.id
        },
        contractId () {
            return this.$route.params.contractId
        }
    },
    methods: {
       reset() {
            // reset form to initial state
            this.currentStatus = "STATUS_INITIAL"
            this.uploadedFiles = []
            this.uploadError = null
        },
        async save(formData) {
            // upload data to the server
            this.currentStatus = "STATUS_SAVING"
            console.log(formData)
            
            try {
                const res = await this.$axios.post("/upload", formData, {
                    headers: { Authorization: `Bearer ${this.$state.token}`}
                })

                console.log(res)
                this.currentStatus = "STATUS_SUCCESS"
                this.returnedFileNames = res.data.files

                

                //logit
                this.$actions.log({
                    logMsg: `${res.data.files.length} file(s) uploaded.`,
                    job: this.$route.params.id,
                    logUser: this.userInfo.name,
                    addInfo: JSON.stringify(res.data.files ,null,'\t')
                })

            } catch (err) {
                console.error(err)
                this.currentStatus = "STATUS_FAILED"
                this.uploadError = err
            }

        },
        filesChange(fileList) {
    
            if (!fileList.length) return

            const formData = new FormData()
            // append the files to FormData
            const files = document.getElementById("files")

            formData.append("id", this.id)
            formData.append("contractId", this.contractId)

            for(let i=0; i < files.files.length; i++){
                formData.append("files", files.files[i])
            }

            // upload Data
            this.save(formData);
        },
        goBack() {
            this.$router.go(-1)
        }
    },
    mounted() {
        this.reset()
    }
}
</script>

<style lang="scss" scoped>
.dropbox {
    background: #63b9f2;
    color: #FFF;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}
  
.thumbs-box {
    display: flex; 
    flex-wrap: wrap;  
    justify-content: center;
}
.thumb {
    margin: 1em 1em 1em 1em;
    border: 2px solid white;
}

</style>