<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-5 mb-5 pb-4' : 'mt-3' ]">
      <h2>Unread Notifications ({{ notifications.unread.length }})</h2>
      <b-alert show :variant="note.noteType === 'payment'? 'success': 'warning'" v-for="note in notifications.unread" :key="note._id" class="d-flex mb-2 pt-0 pb-0 pr-0" >
          <div class="flex-fill hide-long-stuff">
              <font-awesome-icon v-if="note.noteType == 'email'" icon="fa-solid fa-envelope-open-text" class="mr-1"/>
              <font-awesome-icon v-else icon="fa-regular fa-credit-card" class=""/>
              {{ note.text }} 
              <b-button variant="link" :class="[isMobile? 'text-left': '']" @click="goToJob(note.job._id)">
                  {{note.job.customer.lname}}, {{note.job.customer.fname}}
                  {{ note.job.jobType }}: {{note.job.siteAddress.street }}<font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="ml-1"/>
              </b-button>
             <small><span class="text-muted">{{ format(fromUnixTime(note.date), 'MM/dd h:mm aaa') }}</span></small>
          </div>
          
          <b-button variant="outline-primary" size="sm" @click="dismiss(note)" class="pt-0 pb-0 pl-1 pr-1 mr-0 ml-2 text-primary"> 
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="mr-1"/>Archive
          </b-button>
      </b-alert>
      <div class="mt-4"><h2>Archived Notifications ({{ notifications.read.length }})</h2></div>
      <b-alert show variant="info" v-for="note in notifications.read" :key="note._id" class="d-flex mb-2 pt-0 pb-0 pr-0" >
          <div class="flex-fill hide-long-stuff">
              <font-awesome-icon v-if="note.noteType == 'email'" icon="fa-solid fa-envelope-open-text" class="mr-1"/>
              <font-awesome-icon v-else icon="fa-regular fa-credit-card" class=""/>
              {{ note.text }} 
              <b-button variant="link" :class="[isMobile? 'text-left': '']" @click="goToJob(note.job._id)">
                  {{note.job.customer.lname}}, {{note.job.customer.fname}}
                  {{ note.job.jobType }}: {{note.job.siteAddress.street }}<font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="ml-1"/>
              </b-button>
             <small><span class="text-muted">{{ format(fromUnixTime(note.date), 'MM/dd h:mm aaa') }}</span></small>
          </div>
          
          <b-button variant="outline-primary" size="sm" @click="markUnread(note)" class="pt-0 pb-0 pl-1 pr-1 mr-0 ml-2 text-primary"> 
              <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-1"/>Mark Unread
          </b-button>
      </b-alert>
      
      <b-button variant="primary">Previous</b-button>
      <b-button class="ml-2" variant="primary">Next (Older)</b-button>
  </b-container>
</template>

<script>

import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
  name: "notificationBoard",
  data() {
    return {
      notifications: {
        unread: [],
        read: []
      },
      format: format,
      fromUnixTime: fromUnixTime,
    }
  },
  computed: {
        userInfo () {
          return this.$state.userInfo
        },
        isMobile () {
          return this.$state.isMobile
        }
  },
  methods: {
    async checkNotifications() {
            
            if(!this.$state.userInfo._id){
                console.log(`Notify Not ready yet! Waiting for initsession to complete.`)
                setTimeout(() => { this.checkNotifications() }, 100)
            } else {

                const user = this.$state.userInfo._id
                //let skip = 0
                
                /*
                const res = await this.$axios.get(`/notify/user/${user}/${skip}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                */

                const res = await this.$axios.get(`/notify/user/${user}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                
                const unRead = res.data.filter((note) => note['read'] === false)
                const read = res.data.filter((note) => note['read'] === true)
                this.notifications.unread = unRead
                this.$state.notifications = unRead.length
                this.notifications.read = read
                //console.log(this.notifications)

            }
            
        },
        goToJob(_id) {
            this.$router.push(`/jobProfile/${_id}`)
        },
        async dismiss(note) {
            let payload = note
            payload.read = true
            try {
                const res = await this.$axios.put(`/notify/${payload._id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(res)
                this.$state.notifications = this.$state.notifications - 1
                const newNotifcations = this.notifications.unread.filter((note) => note['_id'] !== payload._id)
                this.notifications.unread = newNotifcations
                this.notifications.read.unshift(payload)

            } catch (err) {
                console.log(err)
            }
        },
        async markUnread(note) {
            let payload = note
            payload.read = false
            try {
                const res = await this.$axios.put(`/notify/${payload._id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(res)
                this.$state.notifications = this.$state.notifications + 1
                const newNotifcations = this.notifications.read.filter((note) => note['_id'] !== payload._id)
                this.notifications.read = newNotifcations
                this.notifications.unread.push(payload)

            } catch (err) {
                console.log(err)
            }
        }
  },
  mounted() { 
        this.checkNotifications()
    }    
}
</script>


<style lang="scss" scoped>

</style>
