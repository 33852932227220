<template>
<b-card header-tag="header" header-bg-variant="" header-text-variant="dark" border-variant="secondary" class="mt-3" no-body>
    <template #header>
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0 monoTitle">Job Setups</h5>
            <b-button size="sm" variant="outline-dark" class="mr-1" v-b-tooltip.hover title="New Setup" @click="addNewJobSetup">
                <font-awesome-icon icon="plus"></font-awesome-icon>
            </b-button>
        </div>
    </template>
    <b-list-group flush>
        <b-list-group-item v-show="jobsetups.length > 0" v-for="setup in jobsetups" :key="setup._id" :class="{'lightbluebg' : setup.primary}" >
            <b-row class="row">
                <b-col cols="12" md="2" align-self="center">
                  {{setup.date}}
                  <font-awesome-icon v-if="setup.primary" class="mr-1 text-warning" icon="fa-solid fa-splotch" />
                </b-col>
                <b-col cols="12" md="5" style="line-height:18px;"> 
                    <span v-if="setup.manufacturer" class="monoTitle"><strong>{{setup.manufacturer}} </strong></span>
                    <span v-if="setup.material" class="monoTitle">{{setup.material}} </span>
                    <span v-if="setup.color" class="monoTitle">{{setup.color}}</span>
                    <span v-if="setup.manufacturer||setup.material||setup.color"><br></span>
                    <span v-if="setup.label"><small><b>Label:</b>{{setup.label}} | </small></span> 
                    <small>{{setup.shortInstructions}}</small> 
                </b-col>
                <b-col cols="12" md="5" class="text-right">
                    
                    <div class="mini-card mr-1 align-bottom" v-if="!!setup.money.sellPrice">
                      <div class="header monoTitle">
                        <p><b>Sell Price</b></p>
                      </div>
                      <div class="body">
                        <p>${{numbro(setup.money.sellPrice).format()}}</p>
                      </div>
                    </div>
                    
                    <div class="mini-card align-bottom" v-if="!!setup.money.sellPrice">
                      <div class="header monoTitle">
                        <p><b>Profit %</b></p>
                      </div>
                      <div class="body">
                        <p>{{numbro(setup.money.profitPercent).format()}}</p>
                      </div>
                    </div>
                   
                    <b-button size="sm" variant="outline-dark" class="ml-1" v-b-tooltip.hover title="Copy" @click="copyJobSetup(setup._id)">
                        <font-awesome-icon icon="copy"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" variant="success" v-b-tooltip.hover title="Print Job Setup" class="ml-1" @click="printSetup(setup._id)">
                        <font-awesome-icon icon="fa-solid fa-print"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" variant="primary" v-b-tooltip.hover title="View  Job Setup" class="ml-1" @click="gotoJobSetup(setup._id)">
                        View <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-show="jobsetups.length < 1" class="">
            <b-button variant="outline-primary" class="" @click="addNewJobSetup">
                <font-awesome-icon icon="plus"></font-awesome-icon> Add First Setup
            </b-button>
        </b-list-group-item> 
    </b-list-group>
</b-card>
</template>

<script>
import {format} from 'date-fns'
import {fromUnixTime} from 'date-fns'
import numbro from 'numbro'

export default {
    name: "jobsetups",
    components: {
    
    },
    data() {
        return {
            numbro: numbro,
            format: format,
            fromUnixTime: fromUnixTime,
            jobsetups: [],
        }
    },
    props: {
        contract: Object
    },
    computed: {   
        id() {
            return this.$route.params.id
        },
        currentJobTotal() {
          return this.$state.currentJobTotal
        },
        userInfo () {
          return this.$state.userInfo
        }
    },
    created() {
        this.getJobsetups()
    },
    methods: {
        addNewSetup() {

            //TODO: Route to new setup form
        },
        gotoJobSetup(setupId){
            this.$router.push({ name: 'jobSetup', params: {id: setupId, copySetup: false, newSetup: false, jobAmount: this.currentJobTotal._value, fromJob: this.id} })
        },
        printSetup(setupId){
            let logInfo = {
              logMsg : `JobSetup was printed by ${this.userInfo.name}`,
              job: this.id,
              logUser: this.userInfo.name
            }
            this.$actions.log(logInfo)
            this.$router.push({ name: 'printJobSetup', params: {id: setupId, jobId: this.id} })
        },
        addNewJobSetup(){
            this.$router.push({ name: 'jobSetup', params: {id: "new", copySetup: false, newSetup: true, jobAmount: this.currentJobTotal._value, fromJob: this.id} })
        },
        copyJobSetup(id){
            this.$router.push({ name: 'jobSetup', params: {id: id, copySetup: true, newSetup: false, jobAmount: this.currentJobTotal._value, fromJob: this.id} })
        },
        async getJobsetups() {

            try {
                let setups = await this.$axios.get(`/jobsetup/job/${this.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                setups.data.forEach(setup => {
                    //format date
                    setup.date= format(fromUnixTime(setup.date), 'MM/dd/yyyy')
                    //cleanup HTML and shortenInstructions
                    setup.shortInstructions = `${this.cleanInstructions(setup.instructions).slice(0,40)}...`
                    
                    //set a sellPrice if none exists
                    if(setup?.money?.sellPrice ){
                        console.log(`sellPrice exists!`)
                    } else {
                        console.log(`sellPrice doesn't exist`)
                        Object.assign(setup, { money: { sellPrice : 0, profitPercent: 0 } })
                    }

                })

                this.jobsetups = setups.data

            } catch (err) {
                console.error(err)
            }

        },
        cleanInstructions(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            let text = div.textContent || div.innerText || ''
            let shortText = text.substring(0, 240) + "..."
            return shortText
        },
        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    }
}

</script>


<style lang="css" scoped>
.lightbluebg {
    background-color: #D8EBFF !important;
}

.mini-card {
    width: 80px;
    height: 30px;
    display: inline-flex;
    flex-direction: column;
    border: 1px #007BFF solid;
    border-radius: 6px;
    font-size: .6em;
}
.mini-card > .header {
    height: 45%;
    background: #007BFF;
    color: white;
    text-align: center;
    line-height: 10px;
}
.header > p {
  padding: 0 0 0 0;
  margin: 1px 0 2px 0;
}
.mini-card > .body {
    text-align: center;
    line-height: 10px;
}
.mini-card > .body > p {
  padding: 0 0 0 0;
  margin: 3px 0 3px 0;
}
</style>