<template>
<b-card header-bg-variant="" header-text-variant="dark" border-variant="secondary" class="mt-3">
    <template #header class="d-flex">
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0 d-inline flex-grow-1 monoTitle">Files</h5>
            
            <div class="d-inline">
                <b-button size="sm" variant="outline-dark" @click="gotoUpload" class="mr-1"><font-awesome-icon icon="fa-solid fa-cloud-arrow-up" /> Upload</b-button>
                <b-button size="sm" variant="outline-dark"  @click="hideFiles" :class="[hide ? hideClass : '']" ><font-awesome-icon icon="fa-regular fa-eye-slash" /> Hide Files</b-button>
            </div>
        </div>
    </template>
    <b-card-text :class="[{hideFiles : hide},filesView]" v-if="thumbnails.length > 0">
            
        <div  v-for="thumb in thumbnails" :key="thumb.name" class="text-center brick" >
            <router-link :to="{name:'fileviewer', params: {contractId: contractId, fileName: thumb.origFileName, extension: thumb.extension, jobId: id}}">
                <b-img class="thumbnail" :src="url+'/files/'+contractId+'/'+thumb.name" :alt="thumb.name"></b-img>
            </router-link>
            <br/>{{thumb.origFileName}}
        </div>
             
    </b-card-text>
    <b-card-text :class="[{hideFiles : hide}, filesView]" v-else>
        <div class="text-center brick" >
            <b-img class="thumbnail" src="https://picsum.photos/150/100" alt="Add Some Files"></b-img>
            <br/>Add Some Files...
        </div>                  
    </b-card-text>
    <b-card-text>
        
        <b-button variant="outline-primary" @click="hideFiles" :class="[hide ? '' : hideClass]" >Show {{thumbnails.length}} file(s)</b-button>
        
    </b-card-text>

</b-card>
</template>

<script>

//import {format} from 'date-fns'
//import {fromUnixTime} from 'date-fns'

export default {
    name: "JobFiles",
    data() {
        return {
            //format: format,
            //fromUnixTime: fromUnixTime,
            thumbnails: [],
            hideClass: "d-none",
            url: this.$baseUrl,
            hide: false,
            filesView: "thumb"
        }
    },
    props: {
        contractId: Number
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        isMobile() {
            return this.$state.isMobile
        }
    },
    created() {
        this.getFiles() 
        if(this.isMobile){
            this.hide = true 
        }
    },
    methods: {
        async getFiles() {

            try {
                let res = await this.$axios.get(`/dirlist/thumbs/${this.contractId}/`, {
                    headers: {
                        Authorization: `Bearer ${this.$state.token}`
                    }
                })

                //console.log(res.data.children)

                if (res.data) {

                    let files = res.data
                
                    //console.log(files)
                    this.thumbnails = files

                } 
        
                

            } catch (err) {
                console.error(err)
            } finally {

                //console.log("Get Files Completed... calling stack Bricks")
                this.stackBricks()

            }

        },
        gotoUpload(){
            this.$router.push( { name: 'upload', params: { 'id' : this.id, 'contractId': this.contractId } } )
        },
        hideFiles() {
            if(this.hide) {
                this.hide = false
            } else {
                this.hide = true
            }
        },
        stackBricks(){
            //masonry
           
        },
        firstLetterCap(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase()
        },
        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    }
}

</script>


<style lang="scss" scoped>

.thumb {
    display: flex;
    flex-wrap: wrap;
}
.thumb .brick {
    background-color: #eee;
    text-align: center;
    display: inline;
    padding: .7em .6em 0 .6em;
    overflow: hidden;
    max-height: 165px;
    font-size: .8em;
}  
.thumb .brick .thumbnail {
    max-width: 100%;
    max-height: 110px;
    margin-bottom: .7em;
    border: 1px solid #111;
    border-radius: 4px;
}
 
.hideFiles {
    display: none;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
    .brick {
        margin: .5%;
        width: 15%;
    }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .brick {
        margin: .5%;
        width: 24%;
    }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
    .brick {
        margin: .5%;
        width: 49%;
    }
}

     
</style>